import React, { Component } from 'react';
class FinanceAffiliates extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>APC Finance Affiliates Page</h1>
            </div>
        );
    }
}
 
export default FinanceAffiliates;