import React, { Component } from 'react';
import WoyTagButton from './woytagbutton';
import './woytags.css';

const addUpdate = ["add","update"];
const enumAddUpdate = ["enum-add","enum-update"];

class WoyTags extends Component {
   
    handelRemoveTag = (tagvalue) => {
        let tempTags = this.props.tags.filter(tag => !(tag===tagvalue) );
        //console.log("newtemptags -- "+tempTags);
        this.props.setTags(tempTags);
    }
    
    
    
    handelAddTag = (e) => {
        //e.preventDefault();
        if (addUpdate.includes(this.props.mode)){
            if (e.key === "Tab"){
                e.preventDefault();
                try{
                    let tempTags = this.props.tags.slice();
                    if (!tempTags)
                        tempTags = [];
                    tempTags.push(e.target.value);    
                    this.props.setTags(tempTags);
                    e.target.value = "";
                }catch(e){
                    console.log(e);
                }
    
            }
        } else if (enumAddUpdate.includes(this.props.mode)){
            try{
                let tempTags = this.props.tags.slice();
                if (!tempTags)
                    tempTags = [];
                tempTags.push(e.target.value);    
                this.props.setTags(tempTags);
                //e.target.value = "";
            }catch(e){
                console.log(e);
            }
        };
        
    }

    displayTags = () => {
        const tagslist = this.props.tags.slice();
        if ( addUpdate.includes(this.props.mode)) {
            return (
            <div className='woytag-panel'>
                <div><span>{this.props.label}</span></div>
                <div className="woytag-button-panel">
                    {(tagslist.length>0) && tagslist.map((tag,index) => <WoyTagButton key={index} value={tag} mode={this.props.mode} removeTag={this.handelRemoveTag}/>)}
                    <input className='woytag-textbox' type='text' placeholder='Add tag' onKeyDown={this.handelAddTag}/>
                </div>   
            </div> 
            )
        } 
        else if (enumAddUpdate.includes(this.props.mode)) {
            return (
                <div className='woytag-panel'>
                    <div><span>{this.props.label}</span></div>
                    <div className="woytag-button-panel">
                        {(tagslist.length>0) && tagslist.map((tag,index) => <WoyTagButton key={index} value={tag} mode={this.props.mode} removeTag={this.handelRemoveTag}/>)}
                        <select className='woytag-selectbox' onChange={this.handelAddTag}>
                            <option value=" " key="0" selected>Select Category</option>
                            {
                               this.props.options.map((cat,index)=>{
                                   return(
                                    <option value={cat} key={index}>{cat}</option>
                                   )
                               }) 
                            }
                        </select>    
                    </div>   
                </div> 
                )
        }
        else {
            return (
                <div className="woytag-button-panel-tabular">
                    {(tagslist.length>0) && tagslist.map((tag,index) => <WoyTagButton key={index} value={tag} mode={this.props.mode} removeTag={this.handelRemoveTag}/>)}
                </div>
            )
        }
    }

    render() { 
        
        return (
            this.displayTags()
        );
    }
}
 
export default WoyTags;