import React, { Component } from 'react';

class SpecsTable extends Component {
    state = {  }
    
    renderGroupHeader = (cat, index) => {
        return (
            <div>
                <div key={cat+"- Header - "+index} className='inv-details-sd-grid'>
                    <div className='inv-detail-specSubHeaderBG1'>
                        {cat}
                    </div>
                    <div>
                        &nbsp;
                    </div>
                </div>
                <div key={cat+"- Sub Header - "+index} className='inv-details-sd-grid'>
                    <div className='inv-detail-specSubHeaderBG2'>
                        Specification (UOM)
                    </div>
                    <div className='inv-detail-specSubHeaderBG2'>
                        Value
                    </div>
                </div>
            </div>
        )    
    }

    render() { 
        if (!this.props.inv.product.specifications){
            return(
                <div className='inv-detail-stockheadercell inv-detail-specHeaderBG'>
                <div className='inv-detail-stocksubheaderfont'>-- No Specification(s) Data --</div>
            </div>
            );
        }

        try {
            const specs = this.props.inv.product.specifications;
            //console.log(specs);
            let currCat="";
            let groupHeader="";
            return (
                <div className="inv-detail-specTableContainer">
                    {specs.map((spec, index) => {
                            if (currCat!==spec.specCategory){
                                groupHeader=this.renderGroupHeader(spec.specCategory, index);
                                currCat=spec.specCategory;
                            } else {
                                groupHeader= "";
                            }

                            let uom = "";
                            if (spec.unitOfMeasure !== "---" )
                                uom = <span className='inv-detail-uomText'>({spec.unitOfMeasure})</span>
                            //ctr++;    
                            return(
                                <div>
                                    {groupHeader}
                                    <div className='inv-details-sd-grid' key={"row-header-" +index}>
                                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>{spec.specName} {uom}</div>
                                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>
                                            {spec.specValue}
                                        </div>
                                    </div>
                                </div>
                            )}
                        )}
                </div>
            
            );    
        } catch (error) {
            console.log(error);
        }
        
    }
}
 
export default SpecsTable;