import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faHouse } from '@fortawesome/free-solid-svg-icons';
import DBTable from '../../../components/woydbtable/woydbtable';
import Woymodal from '../../../components/woymodal/Woymodal';
import ProductsForm from '../../../components/forms/productsform';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate, sortbyColumn} from '../../../utils/paginate';
import WoyCRUDSearch from '../../../components/woycrudsearch/WoyCRUDSearch';

class ProductsMaintenance extends Component {
    state = { 
        products:[],
        filteredProducts:[],
        selectedProduct:{},
        showModal:false,
        dialogMode:"",
        formTitle:"",
        pageSize:25,
        currentPage:1,
        activeColumn:""
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/products'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:products} = await Axios(config);
            this.setState({products:products,filteredProducts:products});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
       // console.log(colName + " - " + searchValue);
        //const $regex = escapeStringRegexp(searchValue);
        const {products} = this.state;
        let newProducts = [];
        if (colName==="category"){
            newProducts = products.filter( x => 
                x[colName].some(t => t.includes(searchValue)) 
              );
        } else {
            newProducts = products.filter( x => 
                x[colName].includes(searchValue) 
              );
        }
        
        this.setState({filteredProducts:newProducts})   
    }

    openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false,dialogMode:"add"});
    }

    addProductlick = () => {
        this.setState({dialogMode:"add"});
        this.openModalDialog();   
        this.setState({formTitle:"Add New Product"});
    }

    editProductClick = (product) => {
        this.setState({dialogMode:"update"});
        this.openModalDialog();      
        this.setState({selectedProduct:product});
        this.setState({formTitle:"Update - "+product.name});
    }

    viewProductClick = (product) => {
        this.setState({dialogMode:"view"});
        this.openModalDialog();      
        this.setState({selectedProduct:product});
        this.setState({formTitle:"View - "+product.name});
    }

    removeProductClick = (product) => {
        this.setState({dialogMode:"remove"});
        this.openModalDialog();      
        this.setState({selectedProduct:product});
        this.setState({formTitle:"Are you sure you want to delete this?"});
    }

    removeFromProducts = (id) => {
        let newProducts = this.state.products.slice();
        let newProducts2 = this.state.filteredProducts.slice();
        newProducts = newProducts.filter((product) => product._id !== id);
        newProducts2 = newProducts2.filter((product) => product._id !== id);
        this.setState({products:newProducts,filteredProducts:newProducts2});

    }

    editFromProducts = (editedProduct) => {
        let editedProducts = this.state.products.slice();
        let editedProducts2 = this.state.filteredProducts.slice();
        editedProducts = editedProducts.map(product => product._id !== editedProduct._id ? product : editedProduct);
        editedProducts2 = editedProducts2.map(product => product._id !== editedProduct._id ? product : editedProduct);

        this.setState({products:editedProducts,filteredProducts:editedProducts2,selectedProduct:editedProduct})  
    }

    addToProducts = (newproduct) => {
        let newproducts = this.state.products.slice();
        let newproducts2 = this.state.filteredProducts.slice();
        newproducts.push(newproduct);
        newproducts2.push(newproduct);
        this.setState({products:newproducts,filteredProducts:newproducts2})  
    }

    handlePageChange = (page) =>{
        //console.log(page);
        this.setState({currentPage:page});
        //this.setState({displaySuppliers:display});
    }

    handleSort = (colName) => {
        const sortedProducts = sortbyColumn(this.state.filteredProducts,colName);
        this.setState({filteredProducts:sortedProducts,activeColumn:colName});
    }

    setSelected = (selected) => {
        this.setState({selectedProduct:selected});
    }

    setDialogMode = (mode) => {
        this.setState({dialogMode:mode});
    }
    
    render() { 
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"A",
                value:"active"
            },
            {
                label:"Make",
                value:"make"
            },
            {
                label:"Model",
                value:"model"
            },
            {
                label:"Year",
                value:"modelyear"
            },
            {
                label:"Description",
                value:"description"
            },
            {
                label:"Category Tag",
                value:"category"
            }
        ];

        const displayProduct = paginate(this.state.filteredProducts,this.state.currentPage,this.state.pageSize);

        const searchOptions = [
            {label:"Product Category", value:"category"},
            {label:"Name", value:"name"},
            {label:"Make", value:"make"},
            {label:"Model", value:"model"},
            {label:"Year", value:"modelyear"},
            {label:"Description", value:"description"}
        ];

        return (
            <div className="apc-maintenance-container">
            {(this.state.showModal) && (this.state.dialogMode==="add") && 
                <Woymodal>
                    <ProductsForm mode={this.state.dialogMode} products={this.state.products} title={this.state.formTitle} product={this.state.selectedProduct} insertProduct={this.addToProducts} updateProduct={this.editFromProducts} removeProduct={this.removeFromProducts} closeForm={this.closeModalDialog}/>
                </Woymodal>
            }
            <div className='apc-maintenance-title-container'>
                <div>
                    <h2>Manage Products</h2>
                </div>
                <div>
                    <WoyCRUDSearch options={searchOptions} defaultOption="category" searchClick={this.handleSearch}/>
                </div>
                <div className='apc-maintenance-addbutton-container'>
                    <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                    <span>&nbsp;</span>
                    <button type="button" onClick={this.addProductlick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
            
           {/*  <DBTable 
                data={displayProduct} 
                user={this.props.user} 
                display={display} 
                addMethod={this.addProductlick} 
                updateMethod={this.editProductClick} 
                deleteMethod={this.removeProductClick} 
                sortBy={this.state.activeColumn} 
                sortData={this.handleSort}
                tagsColumns={["category"]}
            /> */}
            <DBTable 
                data={displayProduct} 
                user={this.props.user} 
                display={display} 
                addMethod={this.addProductlick} 
                updateMethod={this.editProductClick} 
                deleteMethod={this.removeProductClick}
                viewMethod={this.viewProductClick} 
                sortBy={this.state.activeColumn} 
                sortData={this.handleSort} 
                tagsColumns={["tags"]} 
                linkColumns={["website"]}
                mode={this.state.dialogMode}
                setMode={this.setDialogMode}
                setSelected={this.setSelected}
                closeForm={this.closeModalDialog}
                columns={8}     
            >
                <ProductsForm mode={this.state.dialogMode} title={this.state.formTitle} product={this.state.selectedProduct} insertProduct={this.addToProducts} updateProduct={this.editFromProducts} removeProduct={this.removeFromProducts} closeForm={this.closeModalDialog}/>
            </DBTable>
            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayProduct.length} of {this.state.filteredProducts.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredProducts.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange}/>
            </div>
        </div>
        );
    }
}
 
export default ProductsMaintenance;