import React from 'react';

const InvoiceDetailsDescInputBox = ({type, name, placeholder, error, onchange, value, detailindex, descindex, align}) =>{
    
    return(
        <div>
            <input className={"tbEdit "+align} type={type} id={"id-"+name} name={name} placeholder={placeholder} onChange={(event) => {onchange(event,detailindex,descindex)}} defaultValue={value}/>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default InvoiceDetailsDescInputBox;