import React, { Component } from 'react';

class Woymodal extends Component { 
    render() { 
        return (
            <div>
                <div className="apc-modaldialog-window">
                    <div className="apc-modaldialog-formcontainer">
                        {this.props.children}
                    </div>
                </div>
                <div className='apc-modaldialog-container'>
                </div>    
            </div>
        );
    }
}
 
export default Woymodal;