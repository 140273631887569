import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan, faEye } from '@fortawesome/free-solid-svg-icons';
import './woydbtable.css';
import WoyTags from '../input/woytags/woytags';

const editViewDelete = ["update","view","remove"];

class DBTable extends Component {
    state = {
        showForm:false,
        selected: {},
        dialogMode:"edit"
    }

    componentDidMount() {
        this.setState({dialogMode:this.props.mode});
    }
    
    
    showDBForm = () => {
        this.setState({showForm:true});
        this.props.openForm();
    }

    closeDBForm = () => {
        this.setState({showForm:false});
        this.props.closeForm();
    }

    updateClick = (user) => {
        this.setState({selected:user,dialogMode:"update",showForm:true});
        this.props.setSelected(user);
        this.props.setMode("update");
        this.props.updateMethod(user);
        //this.props.updateMethod(user);
    }

    removeClick = (user) => {
        this.setState({selected:user,dialogMode:"remove",showForm:true});
        //this.props.deleteMethod(user);
        this.props.setSelected(user);
        this.props.setMode("remove");
        this.props.deleteMethod(user);
    }

    viewClick = (user) => {
        this.setState({selected:user,dialogMode:"view",showForm:true});
        //this.props.viewMethod(user);
        this.props.setSelected(user);
        this.props.setMode("view");
        this.props.viewMethod(user);
    }

    setDisplayCell = (item,colname) => {
        if (this.props.tagsColumns){
            if (this.props.tagsColumns.includes(colname))
             return <WoyTags editMode={false} tags={item[colname]}/>   
        }

        if (this.props.linkColumns) {
            if (this.props.linkColumns.includes(colname))
                return <a className="apc-link" href={item[colname]} target="new_" exact>{item[colname]}</a>
        }

        if (item[colname]===true)
            return <input type="checkbox" readOnly checked />
        else if (item[colname]===false)
            return <input type="checkbox" disabled/>
        else
            return item[colname];    
    }

    render() {
        const { data, display, sortBy, sortData} = this.props; 
        return (
            <div>
                <table className="apc-table-container">
                    <thead>
                        <tr>
                        {
                            display.map(col =>
                                <th className={sortBy === col.value ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} key={"id-"+col.label} onClick={()=>{sortData(col.value)}}>{col.label}</th>
                            )
                        }
                        <th className='apc-woytable-row-header'>Action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    data && data.map((user,index) => 
                        <React.Fragment>
                        <tr className='apc-woytable-row' key={"id-"+index}>
                            {
                                display.map(col =>
                                    <td className='apc-woytable-col' key={"id-"+user[col.value]}>{this.setDisplayCell(user,col.value)}</td>
                                )
                            }
                            <td className='apc-woytable-col apc-woytable-col-actions' key={"id-"+user._id+"-actions-"+index}>
                                <div className="apc-actionbuttongrid">
                                    <div>
                                    {
                                        (this.props.user.isAdmin) && <button value={user._id} type="button" onClick={()=> {this.removeClick(user)}} className="btn btn-danger apc-actionbutton"><FontAwesomeIcon icon={faTrashCan} /></button>
                                    }
                                    </div>
                                    <div>
                                         <button value={user._id} type="button" onClick={()=>{this.updateClick(user)}} className="btn btn-warning apc-actionbutton"><FontAwesomeIcon icon={faEdit} /></button>
                                    </div>
                                    <div>
                                         <button value={user._id} type="button" onClick={()=>{this.viewClick(user)}} className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faEye} /></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {(editViewDelete.includes(this.props.mode))&&(this.state.showForm) && (this.state.selected._id === user._id) && <tr><td colSpan={this.props.columns} className="apc-table-editRow"><div className='apc-table-editRow-Content'>{this.props.children}</div></td></tr>}
                        </React.Fragment>
                    )    
                }
                    </tbody>
                </table>
            </div>
        );
    }
}
 
export default DBTable;