import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './woydialog.css'
import WoyPopUp from '../popup/woyPopUp';
import WoyCRUDSearch from '../woycrudsearch/WoyCRUDSearch';
import DBTableBrowse from '../woydbtable/woydbtablebrowse';
import {sortbyColumn} from '../../utils/paginate';

class ProductDialog extends Component {
    state = {
        products: [],
        filteredProducts: [],
        selectedProduct: {},
        activeColumn: "name",
        showPopUp: false    
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/products'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:products} = await Axios(config);
            this.setState({products:products,filteredProducts:products});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
        // console.log(colName + " - " + searchValue);
         //const $regex = escapeStringRegexp(searchValue);
         const {products} = this.state;
         let newProducts = [];
         if (colName==="category"){
             newProducts = products.filter( x => 
                 x[colName].some(t => t.includes(searchValue)) 
               );
         } else {
             newProducts = products.filter( x => 
                 x[colName].includes(searchValue) 
               );
         }
         
         this.setState({filteredProducts:newProducts})   
     }

    openProductBrowser = () => {
        this.setState({showPopUp: true});
    }

    closeProductBrowser = () => {
        this.setState({showPopUp: false});
    }

    selectProductClick = (selectedProduct) => {
        this.setState({selectedProduct: selectedProduct});
        this.props.setProduct(selectedProduct);
        this.closeProductBrowser();
    }

    handleSort = (colName) => {
        const sortedProducts = sortbyColumn(this.state.filteredProducts,colName);
        this.setState({filteredProducts:sortedProducts,activeColumn:colName});
    }

    displayProductDetails = () => {
        if (this.props.product !== null){
            if (Object.keys(this.props.product).length !== 0)
                return (<span class="input-group-text apc-form-dialog-text">{this.props.product.name}</span>)
            else
                return (<span class="input-group-text apc-form-dialog-text">No product selected.</span>);    
        }
        else 
            return (<span class="input-group-text apc-form-dialog-text">No product selected.</span>);
    }

    render() {
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"A",
                value:"active"
            },
            {
                label:"Make",
                value:"make"
            },
            {
                label:"Model",
                value:"model"
            },
            {
                label:"Year",
                value:"modelyear"
            },
            {
                label:"Description",
                value:"description"
            },
            {
                label:"Category Tag",
                value:"category"
            }
        ];

        const displayProduct = this.state.filteredProducts;
        //console.log(displayProduct);

        const searchOptions = [
            {label:"Product Category", value:"category"},
            {label:"Name", value:"name"},
            {label:"Make", value:"make"},
            {label:"Model", value:"model"},
            {label:"Year", value:"modelyear"},
            {label:"Description", value:"description"}
        ];

        return (
         <div>
            {
                this.state.showPopUp && 
                    <WoyPopUp handleClose={this.closeProductBrowser}>
                        <div>
                            <WoyCRUDSearch options={searchOptions} defaultOption="name" searchClick={this.handleSearch}/>
                        </div>
                        <DBTableBrowse 
                        data={displayProduct} 
                        user={this.props.user} 
                        display={display} 
                        selectMethod={this.selectProductClick} 
                        sortBy={this.state.activeColumn} 
                        sortData={this.handleSort}
                        tagsColumns={["category"]}
                        />
                    </WoyPopUp>    
            } 
            <div class="input-group">
                <span class="input-group-text apc-form-dialog-label">Product</span>
                {this.displayProductDetails()}
                <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={this.openProductBrowser}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
            </div>
         </div>
        );
    }
}
 
export default ProductDialog;