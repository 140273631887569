import React from 'react';
import { Form } from 'react-bootstrap';

const InputBox = ({label, type, name, placeholder, error, onchange}) =>{
    return(
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control type={type} name={name} placeholder={placeholder} onChange={onchange}/>
            {error && <div className="alert alert-danger">{error}</div>}
        </Form.Group>
    );
       
}
export default InputBox;