import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './WoyUploader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import WoyImgCarousel from '../imgcarousel/imgcarousel';
import WoyFileTable from './WoyFileTable';

const addUpdate = ["add","update"];
const updateRemove = ["remove","update","view"];

class WoyMultiUploader extends Component {
    state = { 
        files:null,
        preview:null,
        parentfiles:[]
    }

    componentDidMount() {
        if (this.props.preview) {
            this.setState({preview:this.props.preview})
        }
    }
    
    
    convertFileListToArray = (fileList) =>{
        //console.log("File List: "+fileList);
        let retval = [];
        for (let i = 0; i < fileList.length; i++) {
            retval.push(fileList[i])
        }
        return retval;
    }

    setFileArrayforParent = (tempFiles) => {
        let fileArray = [];
        if (!(tempFiles == null)){
            tempFiles.map(file =>{
                return (
                    fileArray.push({
                        filepath: file.path,
                        description: file.filename,
                        filetype: file.mimetype
                    })
                )
            })
        };
        return fileArray;
    }

    handleFileChange = e => {
        const tempFiles = this.convertFileListToArray(e.target.files); 
        this.setState({
            files: tempFiles
        });
        
        
        
    }

    displayDetails = () =>{
        if (this.props.mode==="add"){
            if (this.props.filetype === "image")
                return <div className="woyuploader-carousel-container"><WoyImgCarousel images={this.state.preview} mode={this.props.mode}/></div>
            else if (this.props.filetype === "docs")
                return <WoyFileTable deleteMethod={this.props.deleteMethod} files={this.props.preview}/>;
        } else if (updateRemove.includes(this.props.mode)) {
            if (this.props.filetype === "image")
                return <div className="woyuploader-carousel-container"><WoyImgCarousel images={this.state.preview} mode={this.props.mode}/></div>
            else if (this.props.filetype === "docs")
                return <WoyFileTable deleteMethod={this.props.deleteMethod} files={this.props.preview}/>;
        }

        
    }

    handleSubmit = async () => {
        //e.preventDefault();
        
        //initialize axios parameters
        let filetypeupload = (this.props.filetype==="image"?"productPhotos":"productDocs");
        const url = process.env.REACT_APP_BACKEND_URL + '/api/uploads/' + filetypeupload;
        const formData = new FormData();
        const {files} = this.state;
        files.map(file => {
            return(formData.append(filetypeupload,file))
        });
        
        const config = {
            method:"post",
            url: url,
            data: formData,
            headers: {
                "Content-Type":"multipart/form-data",
                "x-auth-token": localStorage.getItem("token")
            }
        };

        try {
            const res = await axios(config);
            if (res) {
                //console.log(res.data.msg);
                console.log(res.data);
                this.setState({preview: res.data.files});
                const parentfile = this.setFileArrayforParent(res.data.files);
                this.setState({
                    parentfiles: parentfile,
                    preview: res.data.files
                });

                this.props.setParentFiles(parentfile);
        
        this.props.setParentFiles(parentfile);
            }
        } catch (error) {
          console.log(error);  
        }
    }

    render() { 
        const placeholder = (this.props.filetype==="image"?"Choose Photo(s)":"Choose Product(s)");
        return (
            <Fragment>
                {/* <form> */}
                <div className="input-group mb-3">
                    {addUpdate.includes(this.props.mode) && <Fragment><input type="file" className="form-control" name="productphotos" id="inputGroupFile02" placeholder={placeholder} onChange={this.handleFileChange} multiple/>
                    <div className="input-group-append btn btn-success" onClick={this.handleSubmit}>
                        {/* <span>{this.props.uploadButtonLabel}</span> */}
                        <span><FontAwesomeIcon icon={faUpload} /></span>
                    </div></Fragment>}
                </div>
                <div className="row justify-content-center">
                    {this.displayDetails()}
                </div>
                
                {/* </form> */}
            </Fragment>
        );
    }
}
 
export default WoyMultiUploader;