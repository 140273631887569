import React, { Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import "./imgcarousel.css";

const updateRemove = ["update","remove","view"];

class WoyImgCarousel extends Component {
    state = {  } 

    renderImages = () =>{
        if (this.props.images == null)
            return <div><h4>No photo or image</h4></div>

        const {images} = this.props;
        
        if (this.props.mode==="add"){
            return(images.map((image,key)=> 
                <div key={key}>
                    <img className="imgcarousel-img" src={process.env.REACT_APP_BACKEND_URL + "\\" +image.path} alt={image.filename}/>
                    <p className="legend">{image.filename}</p>
                </div>
            ))
        } 
        
        if (updateRemove.includes(this.props.mode)) {
            return(images.map((image,key)=>
                <div key={key}>
                    <img className="imgcarousel-img" src={process.env.REACT_APP_BACKEND_URL + "\\" +image.filepath} alt={image.filename}/>
                    <p className="legend">{image.description}</p>
                </div>
            ))
        }
    }



    render() { 
        return (
            <div>
                {/* {(this.props.images == null) && <h4>No photo or image</h4>} */}
                {(this.props.images !== null) && <Carousel showThumbs={false}>{this.renderImages()}</Carousel>}
            </div>
            
            
        );
    }
}
 
export default WoyImgCarousel;