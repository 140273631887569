import React, { Component } from 'react';

class InvoiceOptions extends Component {
    state = {  } 
    render() { 
        return (<div className='apc-margin-auto'>
            <h3>Create New Invoice</h3>
            <div><button className='btn btn-success apc-invoice-options-btn' onClick={this.props.setCashSale}>CASH SALE</button></div>
            <div><button className='btn btn-success apc-invoice-options-btn' onClick={this.props.setDepositCashSale}>CASH SALE - DEPOSIT</button></div>
            <div><button className='btn btn-success apc-invoice-options-btn' onClick={this.props.setPOSale}>PURCHASE ORDER SALE</button></div>
            <div><button className='btn btn-success apc-invoice-options-btn' onClick={this.props.setDepositPOSale}>PO SALE - DEPOSIT</button></div>
            <div><button className='btn btn-danger apc-invoice-options-btn' onClick={this.props.closeForm}>CLOSE</button></div>
        </div>);
    }
}
 
export default InvoiceOptions;