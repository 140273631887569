import React, { Component } from 'react';
import Joi from 'joi-browser';
import Axios from 'axios';
import InputBox from '../input/inputbox2';
import CheckBox from '../input/checkbox';
import SelectBox from '../input/selectbox';
import WoyTags from '../input/woytags/woytags';
import SpecsBox from '../input/specsBox/specsBox';
import WoyUploader from '../fileupload/WoyUploader';
import WoyMultiUploader from '../fileupload/WoyMultiUploader';
import WoyPopUp from '../popup/woyPopUp';
import DBTableBrowse from '../woydbtable/woydbtablebrowse';
import WoyCRUDSearch from '../woycrudsearch/WoyCRUDSearch';
import TextBox from '../input/textbox';

const updateRemoveView = ["update","remove","view"];
const updateInsert = ["update","add"];
const viewRemove = ["remove","view"];
const enumAttachment = ['image/jpeg','image/png','image/jpg','application/pdf'];
const enumProductCategory = ['DIESEL GENERATOR', 'PETROL GENERATOR', 'MACHINERY','COMPRESSOR','PORTABLE GENERATOR', 'PORTABLE WATER PUMP', 'PARTS', 'SERVICE'];
const enumProductCondition = ['BRAND NEW', 'EXCELLENT', 'GOOD', 'RUNNING', 'POOR', 'WRECKING'];
const enumFuelType = ['DIESEL','PETROL','NATURAL GAS', 'LPG', 'HYBRID'];
const enumElectricPhase = ['SINGLE PHASE', 'THREE PHASE'];
const enumSpecCategory = ['GENERATOR', 'COMPRESSOR', 'ENGINE', 'DIMENSIONS', 'WEIGHT', 'OTHER'];

const baseSpecTemplate = [
    {specCategory:"ENGINE",specName:"Make", unitOfMeasure:"---", specValue:""},
    {specCategory:"ENGINE",specName:"Model", unitOfMeasure:"---", specValue:""},
    {specCategory:"ENGINE",specName:"Type", unitOfMeasure:"---", specValue:""},
    {specCategory:"ENGINE",specName:"Serial No", unitOfMeasure:"---", specValue:""},
    {specCategory:"ENGINE",specName:"Rated Output", unitOfMeasure:"kW", specValue:""},
    {specCategory:"ENGINE",specName:"Speed", unitOfMeasure:"rpm", specValue:""},
    {specCategory:"ENGINE",specName:"No of Cylinders", unitOfMeasure:"---", specValue:""},
    {specCategory:"ENGINE",specName:"Oil Capacity", unitOfMeasure:"L", specValue:""},
    {specCategory:"ENGINE",specName:"Fuel Type", unitOfMeasure:"---", specValue:""},
    {specCategory:"ENGINE",specName:"Fuel Tank Capacity", unitOfMeasure:"L", specValue:""},
    {specCategory:"DIMENSIONS",specName:"Length", unitOfMeasure:"mm", specValue:""},
    {specCategory:"DIMENSIONS",specName:"Width", unitOfMeasure:"mm", specValue:""},
    {specCategory:"DIMENSIONS",specName:"Height", unitOfMeasure:"mm", specValue:""},
    {specCategory:"DIMENSIONS",specName:"Tire Size", unitOfMeasure:"---", specValue:""},
    {specCategory:"WEIGHT",specName:"Dry Weight", unitOfMeasure:"kg", specValue:""},
    {specCategory:"WEIGHT",specName:"Operating Weight", unitOfMeasure:"kg", specValue:""},
    {specCategory:"OTHER",specName:"Sound Level", unitOfMeasure:"dB", specValue:""}
];

var compressorSpecTemplate = [
    {specCategory:"COMPRESSOR",specName:"Type", unitOfMeasure:"---", specValue:""},
    {specCategory:"COMPRESSOR",specName:"Free Air Delivery", unitOfMeasure:"m3/min", specValue:""},
    {specCategory:"COMPRESSOR",specName:"Rated Pressure", unitOfMeasure:"MPa", specValue:""},
    {specCategory:"COMPRESSOR",specName:"Air Outlet Valve", unitOfMeasure:"---", specValue:""},
];

var generatorSpecTemplate = [
    {specCategory:"GENERATOR",specName:"Make", unitOfMeasure:"---", specValue:""},
    {specCategory:"GENERATOR",specName:"Model", unitOfMeasure:"---", specValue:""},
    {specCategory:"GENERATOR",specName:"Frequency", unitOfMeasure:"Hz", specValue:"50"},
    {specCategory:"GENERATOR",specName:"Output Rating (Prime)", unitOfMeasure:"KVA", specValue:""},
    {specCategory:"GENERATOR",specName:"Output Rating (Standby)", unitOfMeasure:"KVA", specValue:""},
    {specCategory:"GENERATOR",specName:"Poles", unitOfMeasure:"---", specValue:""},
    {specCategory:"GENERATOR",specName:"Phase", unitOfMeasure:"---", specValue:""},
    {specCategory:"GENERATOR",specName:"Rated Voltage", unitOfMeasure:"V", specValue:""},
    {specCategory:"GENERATOR",specName:"Power Factor", unitOfMeasure:"---", specValue:"0.8"},
    {specCategory:"GENERATOR",specName:"Voltage Regulation", unitOfMeasure:"%", specValue:""}
];

compressorSpecTemplate = compressorSpecTemplate.concat(baseSpecTemplate);
generatorSpecTemplate = generatorSpecTemplate.concat(baseSpecTemplate);

export const attachmentSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    filepath: Joi.string()
    .min(3)
    .max(300)
    .required(),
    description: Joi.string()
    .min(6)
    .max(100)
    .required(),
    filetype: Joi.string()
    .valid(...enumAttachment)
    .required(), 
});

export const specSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    specCategory: Joi.string()
    .valid(...enumSpecCategory)
    .required(),
    specName: Joi.string()
    .min(3)
    .max(150)
    .required(),
    unitOfMeasure: Joi.string()
    .min(0)
    .max(50)
    .optional(),
    specValue: Joi.string()
    .min(0)
    .max(200)
    .optional()
})

export const productSchema = Joi.object({
    _id:Joi.string(),
    category: Joi.array()
    .items(Joi.string().valid(...enumProductCategory))
    .required(),
    name: Joi.string()
    .min(6)
    .max(150)
    .required(),
    model: Joi.string()
    .min(6)
    .max(255)
    .required(),
    modelyear: Joi.string()
    .min(3)
    .max(10)
    .optional(),
    make: Joi.string()
    .min(3)
    .max(100)
    .optional(),
    serialno: Joi.string()
    .min(3)
    .max(100)
    .required(),
    active: Joi.boolean()
    .required(),
    description: Joi.string()
    .min(6)
    .max(500)
    .required(),
    shortdesc: Joi.string()
    .min(6)
    .max(150)
    .required(),
    operatinghours: Joi.number()
    .min(0)
    .required(),
    condition: Joi.string()
    .valid(...enumProductCondition)
    .required(),
    specifications: Joi.array()
    .items(specSchema).optional(),
    features: Joi.array()
    .items(Joi.string().min(6).max(255).optional())
    .optional(),
    profilephoto: Joi.string()
    .optional(),
    photos: Joi.array()
    .items(attachmentSchema).optional(),
    otherfiles: Joi.array()
    .items(attachmentSchema).optional(),
    updated_at: Joi.date()
    .optional(),
    created_at: Joi.date()
    .optional(),
    __v: Joi.number()
    .optional()
})

export default class ProductsForm extends Component {
    state = { 
        product: {
            category:[],
            name:"",
            model:"",
            modelyear:"",
            make:"",
            serialno:"",
            active:false,
            description:"",
            shortdesc:"",
            operatinghours:0,
            condition:"BRAND NEW",
            specifications:[],
            features:[],
            profilephoto:"",
            photos:[],
            otherfiles:[]
        },
        errors: {},
        products : [],
        filteredProducts: [],
        productBrowser: false,
        currentFormDisplay: "MainForm"
     } 

     componentDidMount() {
        if (updateRemoveView.includes(this.props.mode)) {
            this.setState({
                product: {
                    _id:this.props.product._id,
                    category:this.props.product.category,
                    name:this.props.product.name,
                    model:this.props.product.model,
                    modelyear:this.props.product.modelyear,
                    make:this.props.product.make,
                    serialno:this.props.product.serialno,
                    active:this.props.product.active,
                    description:this.props.product.description,
                    shortdesc:this.props.product.shortdesc,
                    operatinghours:this.props.product.operatinghours,
                    condition:this.props.product.condition,
                    specifications:this.props.product.specifications,
                    features:this.props.product.features,
                    profilephoto:this.props.product.profilephoto,
                    photos:this.props.product.photos,
                    otherfiles:this.props.product.otherfiles
                }
            })
        }  else if (this.props.mode === "add") {
            this.setState({products: this.props.products,
                filteredProducts: this.props.products
            });
        }
    }

    appendPhotos = (pa) => {
        //console.log(pa);
        const tempProduct = {...this.state.product}
        if (this.props.mode==="add")
            tempProduct.photos =  pa
        else if (this.props.mode==="update")    
            tempProduct.photos = tempProduct.photos.concat(pa);
        this.setState({product:tempProduct});
    }

    handlePhoto = (path) =>{
        const tempProduct = {...this.state.product};
        tempProduct.profilephoto=path;
        this.setState({product:tempProduct});

    }

    appendDocus = (da) => {
        const tempProduct = {...this.state.product}
        if (this.props.mode==="update")
            tempProduct.otherfiles =  tempProduct.otherfiles.concat(da)
        else if (this.props.mode==="add")
            tempProduct.otherfiles =  da;
        this.setState({product:tempProduct});
    }

    renderTabs = () => { 
        return (
            <ul className="nav nav-tabs">
                <li className="nav-item" key="details">
                    {(this.state.currentFormDisplay==="MainForm") && <button className="nav-link active apc-tab-button" aria-current="page" onClick={()=> {this.setState({currentFormDisplay: "MainForm"})}}>Details</button>}
                    {(this.state.currentFormDisplay!=="MainForm") && <button className="nav-link apc-tab-button" aria-current="page" onClick={()=> {this.setState({currentFormDisplay: "MainForm"})}}>Details</button>}
                </li>
                <li className="nav-item" key="specs">
                    {(this.state.currentFormDisplay==="FeaturesForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "FeaturesForm"})}}>Features</button>}
                    {(this.state.currentFormDisplay!=="FeaturesForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "FeaturesForm"})}}>Features</button>}
                </li>
                <li className="nav-item" key="features">
                    {(this.state.currentFormDisplay==="SpecForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "SpecForm"})}}>Specifications</button>}
                    {(this.state.currentFormDisplay!=="SpecForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "SpecForm"})}}>Specifications</button>}
                </li>
                <li className="nav-item" key="photos">
                    {(this.state.currentFormDisplay==="PhotosForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "PhotosForm"})}}>Photos</button>}
                    {(this.state.currentFormDisplay!=="PhotosForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "PhotosForm"})}}>Photos</button>}
                </li>
                <li className="nav-item" key="docs">
                    {(this.state.currentFormDisplay==="DocsForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "DocsForm"})}}>Documents</button>}
                    {(this.state.currentFormDisplay!=="DocsForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "DocsForm"})}}>Documents</button>}
                </li>
            </ul> 
        )
    }

    featuresFields = () =>{
        return(
            <WoyTags
                label="Features" 
                mode={this.props.mode}
                tags={this.state.product.features}
                setTags={this.handleFeaturesChange}
            />
        );
            
    }

    specFields = () => {
        return (
            <React.Fragment>
                 <SpecsBox 
                    specs={this.state.product.specifications}
                    specChange={this.handleSpecChange}
                />
            </React.Fragment>
        )
    }

    photoFields = () => {
        const multiuploader =  updateRemoveView.includes(this.props.mode)? <WoyMultiUploader uploadButtonLabel="Upload Product Photo(s)" preview={this.state.product.photos} filetype="image" setParentFiles={this.appendPhotos} mode={this.props.mode}/> : <WoyMultiUploader uploadButtonLabel="Upload Product Photo(s)" filetype="image" setParentFiles={this.appendPhotos} mode={this.props.mode}/>;
        const uploader = updateRemoveView.includes(this.props.mode) ? <WoyUploader uploadButtonLabel="Upload Feature Photo" preview={this.state.product.profilephoto} setParentField={this.handlePhoto} mode={this.props.mode}/>:<WoyUploader uploadButtonLabel="Upload Feature Photo" setParentField={this.handlePhoto} mode={this.props.mode}/>; 
        
        return (
            <div className="apc-form-photocontainer mb-3">
                <h4>Product Main Photo</h4>
                {uploader}
                <h4>Product Photo(s)</h4>
                {multiuploader}
            </div>
        )
    }

    docsFields = () => {
        return (
            <div className="mb-3">
                <h4>Document attachment(s)</h4>
                <WoyMultiUploader uploadButtonLabel="Upload Product Documents" filetype="docs" setParentFiles={this.appendDocus} preview={this.state.product.otherfiles} mode={this.props.mode}/>
            </div>
        )
    }

    formFields = () => {
        return(
            <React.Fragment>
                
                    <WoyTags
                        label="Categories" 
                        mode={"enum-"+this.props.mode}
                        options={enumProductCategory}
                        tags={this.state.product.category}
                        setTags={this.handleTagsChange}
                    />
                    <InputBox 
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Product name"
                        error={this.state.errors.name}
                        onchange={this.handleChange}
                        value={this.state.product.name}
                    />
                    <div className="apc-form-grid-3-cols">
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Make"
                            type="text"
                            name="make"
                            placeholder="Make"
                            error={this.state.errors.make}
                            onchange={this.handleChange}
                            value={this.state.product.make}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Model"
                            type="text"
                            name="model"
                            placeholder="Model"
                            error={this.state.errors.model}
                            onchange={this.handleChange}
                            value={this.state.product.model}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Year"
                            type="text"
                            name="modelyear"
                            placeholder="Model"
                            error={this.state.errors.modelyear}
                            onchange={this.handleChange}
                            value={this.state.product.modelyear}
                            />
                        </div>
                    </div>
                    
                    <div className="apc-form-grid-3-cols">
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Serial No"
                            type="text"
                            name="serialno"
                            placeholder="Serial No"
                            error={this.state.errors.serialno}
                            onchange={this.handleChange}
                            value={this.state.product.serialno}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Hours"
                            type="text"
                            name="operatinghours"
                            placeholder="0"
                            error={this.state.errors.operatinghours}
                            onchange={this.handleChange}
                            value={this.state.product.operatinghours}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <CheckBox 
                            label="Publish to Website"
                            name="active"
                            error={this.state.errors.active}
                            onchange={this.handleChange}
                            value={this.state.product.active}
                            />
                        </div>
                    </div>
                    
                    
                    
                    <TextBox 
                        label="Description"
                        name="description"
                        placeholder="Description"
                        error={this.state.errors.description}
                        onchange={this.handleChange}
                        value={this.state.product.description}
                    />
                    <InputBox 
                        label="Short Desc"
                        type="text"
                        name="shortdesc"
                        placeholder="shortdesc"
                        error={this.state.errors.shortdesc}
                        onchange={this.handleChange}
                        value={this.state.product.shortdesc}
                    />
                    <SelectBox
                        label="Codition"
                        name="condition"
                        placeholder="Select Condition"
                        error={this.state.errors.condition}
                        onchange={this.handleChange}
                        value={this.state.product.condition}
                        items={enumProductCondition}
                    />
            </React.Fragment>
        )
    }

    validate = () => {
        const {product} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(product, productSchema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currProduct  = {...this.state.product};
        currProduct[name] = value;

        this.setState({
            product: currProduct
        });
    };

    handleTagsChange = (tagsValue) => {
        let currProduct  = {...this.state.product};
        if ((Array.isArray(tagsValue) && tagsValue.length)){
            if (tagsValue.length === 0)
                currProduct.category.length = 0
            else
                currProduct.category = tagsValue;
        } else
            currProduct.category.length = 0;
    
        if (this.props.mode==="add"){
            
            let gensetGroup = ['DIESEL GENERATOR', 'PETROL GENERATOR','PORTABLE GENERATOR'];
            let compressorGroup = ['COMPRESSOR'];
    
            if ((currProduct.category.length > 0) && currProduct.category.every(val => gensetGroup.includes(val)) ) {
                
                currProduct.specifications = generatorSpecTemplate;
            } else if ((currProduct.category.length > 0) &&  currProduct.category.every(val => compressorGroup.includes(val))) {
                
                currProduct.specifications = compressorSpecTemplate;
            } else {
                currProduct.specifications.length = 0;
            };
        };

        this.setState({
            product:currProduct 
        });
    }

    handleFeaturesChange = (tagsValue) => {
        let currProduct  = this.state.product;
        if ((Array.isArray(tagsValue) && tagsValue.length)){
            if (tagsValue.length === 0)
                currProduct.features.length = 0
            else
                currProduct.features = tagsValue;
        } else
            currProduct.category.length = 0;
        
        this.setState({
            product:currProduct 
        });
    }

    handleSpecChange = (index, event) => {
        let data = [...this.state.product.specifications];
        let prod = this.state.product;
        data[index][event.target.name] = event.target.value;
        prod.specifications = data;
        this.setState({product:prod});
        //console.log(this.state.product.specifications);
    }

    handleSubmit = async (e) => {
        //e.preventDefault();

        let url = "";
        let method = "";

        if (this.props.mode === "add") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/products');
            method = 'post';
        } else if (this.props.mode === "update") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/products/'+this.props.product._id);
            method = 'put';
            //console.log("update url and method");
        } else if (this.props.mode === "remove") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/products/'+this.props.product._id);
            method = 'delete';
        }
             
        if (updateInsert.includes(this.props.mode)){
            const errors = this.validate();
            console.log(errors);
            this.setState({errors: errors || {}});
            if (errors) return;

        } 

        const {product} = this.state;
        let data = JSON.stringify({
            category:product.category,
            name:product.name,
            model:product.model,
            modelyear:product.modelyear,
            make:product.make,
            serialno:product.serialno,
            active:product.active,
            description:product.description,
            shortdesc:product.shortdesc,
            operatinghours:product.operatinghours,
            condition:product.condition,
            specifications:product.specifications,
            features:product.features,
            profilephoto:product.profilephoto,
            photos:product.photos,
            otherfiles:product.otherfiles
        });
        
        //console.log(data);  
        var config = {
        method: method,
        url: url,
        headers: { 
            'x-auth-token': localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            const response = await Axios(config);
            if (response) {
                console.log(response);
                const updatedProduct = response.data;
                if (this.props.mode === "add"){
                    this.props.insertProduct(updatedProduct);
                } else if (this.props.mode === "update"){
                    this.props.updateProduct(updatedProduct);
                } else if (this.props.mode === "remove"){
                    this.props.removeProduct(updatedProduct._id);
                }
                this.props.closeForm();
            } 
        } catch (error) {
            console.log(error.message);
            this.props.closeForm();
        }
    };

    selectProductClick = (selectedProduct) => {
        //this.setState({productToCopy: selectedProduct});
        //this.props.setProductToCopy(selectedProduct);
        let tempProduct = {
                category:selectedProduct.category,
                name:selectedProduct.name,
                model:selectedProduct.model,
                modelyear:selectedProduct.modelyear,
                make:selectedProduct.make,
                serialno:selectedProduct.serialno,
                active:selectedProduct.active,
                description:selectedProduct.description,
                shortdesc:selectedProduct.shortdesc,
                operatinghours:selectedProduct.operatinghours,
                condition:selectedProduct.condition,
                specifications:selectedProduct.specifications,
                features:selectedProduct.features
        }
        this.setState({
            product: tempProduct
        })
        
        this.closeProductBrowser();
    }

    copyProductDetails = () => {
        this.setState({productBrowser:true});
    }

    handleSearch = (colName, searchValue) => {
        // console.log(colName + " - " + searchValue);
         //const $regex = escapeStringRegexp(searchValue);
         const {products} = this.state;
         let newProducts = [];
         if (colName==="category"){
             newProducts = products.filter( x => 
                 x[colName].some(t => t.includes(searchValue)) 
               );
         } else {
             newProducts = products.filter( x => 
                 x[colName].includes(searchValue) 
               );
         }
         
         this.setState({filteredProducts:newProducts})   
     }

     closeProductBrowser = () => {
        this.setState({productBrowser:false});
    }

    /* handleClickBrochure = async (e) => {
        e.preventDefault();
        var url = process.env.REACT_APP_BACKEND_URL + "/api/products/brochure/"+e.target.value;
        var res = await Axios.get(url)
        var file = new Blob([res], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL,'_blank', 'noopener,noreferrer');
    } */

    render() { 
        
        let formcontents = "";
        if (viewRemove.includes(this.props.mode)){
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = <fieldset disabled="disabled">{this.formFields()}</fieldset>
            else if (this.state.currentFormDisplay==="SpecForm")
                formcontents = <fieldset disabled="disabled">{this.specFields()}</fieldset>
            else if (this.state.currentFormDisplay==="PhotosForm")
                formcontents = this.photoFields()
            else if (this.state.currentFormDisplay==="DocsForm")
                formcontents = <fieldset disabled="disabled">{this.docsFields()}</fieldset>
            else if (this.state.currentFormDisplay==="FeaturesForm")
                formcontents = <fieldset disabled="disabled">{this.featuresFields()}</fieldset>;
        } else {
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = this.formFields()
            else if (this.state.currentFormDisplay==="SpecForm")
                formcontents = this.specFields()
            else if (this.state.currentFormDisplay==="PhotosForm")
                formcontents = this.photoFields()
            else if (this.state.currentFormDisplay==="DocsForm")
                formcontents = this.docsFields()
            else if (this.state.currentFormDisplay==="FeaturesForm")
                formcontents = this.featuresFields();
        }

        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"A",
                value:"active"
            },
            {
                label:"Make",
                value:"make"
            },
            {
                label:"Model",
                value:"model"
            },
            {
                label:"Year",
                value:"modelyear"
            },
            {
                label:"Description",
                value:"description"
            },
            {
                label:"Category Tag",
                value:"category"
            }
        ];

        const displayProduct = this.state.filteredProducts;

        const searchOptions = [
            {label:"Product Category", value:"category"},
            {label:"Name", value:"name"},
            {label:"Make", value:"make"},
            {label:"Model", value:"model"},
            {label:"Year", value:"modelyear"},
            {label:"Description", value:"description"}
        ];

        return (
            <div>
                    <div>
                    {
                        this.state.productBrowser && 
                        <WoyPopUp handleClose={this.closeProductBrowser}>
                            <div>
                                <WoyCRUDSearch options={searchOptions} defaultOption="name" searchClick={this.handleSearch}/>
                            </div>
                            <DBTableBrowse 
                            data={displayProduct} 
                            user={this.props.user} 
                            display={display} 
                            selectMethod={this.selectProductClick} 
                            sortBy={this.state.activeColumn} 
                            sortData={this.handleSort}
                            tagsColumns={["category"]}
                        />
                        </WoyPopUp> 
                    }
                    </div>
                    <h4>{this.props.title}</h4>
                    <div className='apc-form-tabs'>{this.renderTabs()}</div>
                    <div className='container '>
                        
                        {formcontents}
                        
                        {(this.props.mode !== "view") && <div className="row apc-modaldialog-addbutton-container">
                            <div className='col apc-modaldialog-addbutton-cell'>
                                <button className="btn btn-primary" type="button" onClick={this.handleSubmit}>
                                    {this.props.mode}
                                </button>
                            </div>
                            
                            {/* <span>&nbsp;</span> */}
                            <div className='col apc-modaldialog-addbutton-cell'>
                                <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                                    close
                                </button>
                            </div>
                            <div className='col apc-modaldialog-addbutton-cell'>
                            {(this.props.mode === "add") && 
                            <button className="btn btn-warning" type="button" onClick={this.copyProductDetails}>
                                copy
                            </button>
                            }</div>
                        </div>}

                        {(this.props.mode === "view") && <div>
                            <a href={process.env.REACT_APP_BACKEND_URL + "/public/uploads/brochures/"+this.state.product._id+".pdf"} target="new" >
                                <button className="btn btn-primary" value={this.state.product._id} type="button">
                                    PDF Brochure
                                </button>
                            </a>
                            <span>&nbsp;</span>
                            <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                                close
                            </button>
                        </div>}
                        
                        
                    </div>
                    
                </div>
        );
    }
} 
