import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import '../woydbtable/woydbtable.css';

class WoyFileTable extends Component {
    state = {  } 
    render() {
        const {files} = this.props;
        return (
            <div>
                <table className="apc-table-container">
                    <thead>
                        <tr key="filetableHeader">
                        <th className='apc-woytable-row-header'>Document</th>
                            <th className='apc-woytable-row-header'>Action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        files && files.map((file,index) => 
                        <tr className='apc-woytable-row' key={index}>
                            <td className='apc-woytable-col'><a target="_newdocpreview" href={process.env.REACT_APP_BACKEND_URL + "\\" + file.filepath}>{file.description}</a></td>
                            <td className='apc-woytable-col apc-woytable-col-actions'>
                                <div>
                                    <button value={file.filepath} type="button" onClick={() => {this.props.deleteMethod(file)}} className="btn btn-danger apc-actionbutton"><FontAwesomeIcon icon={faTrashCan} /></button>
                                </div>
                            </td>
                        </tr>
                        )    
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}
 
export default WoyFileTable;