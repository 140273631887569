import React, { Component } from 'react';
class Machinery extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>APC Machinery Page</h1>
            </div>
        );
    }
}
 
export default Machinery;