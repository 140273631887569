import React from 'react';

const SelectBox = ({label, name, placeholder, error, onchange, value, items}) =>{
    const setItems = () => {
        return(
            items.map((item,index) =>
               
                   
                    <option value={item} key={index}>{item}</option>
                
                )    
        )    
    }    
    return(
        <div className="form-floating mb-3">
            <select className="form-select" id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange} value={value}>
                {setItems()}       
            </select>
            <label htmlFor={"id-"+name}>{label}</label>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default SelectBox;