import React, { Component } from 'react';
import Joi from 'joi-browser';
import Axios from 'axios';
import ProductDialog from '../dialogs/productDialog';
import ProductsForm, {productSchema} from './productsform';
import InputBox from '../input/inputbox2';
import CheckBox from '../input/checkbox';

const updateRemove = ["update","remove","view"];
const updateInsert = ["update","insert"];
const viewRemove = ["view","remove"];

const invTypes = ["STOCK","PARTS"];

export const joiDispatchSchema = Joi.object({
    _id:Joi.string()
        .optional(),
    dispatchDate:Joi.date()
        .optional(),
    qty: Joi.number(),
    invoiceid: Joi.string(),
    receivername: Joi.string(),
    receivercontactno: Joi.string(),
    receiveraddress: Joi.string(),
    description: Joi.string(),
    dispatchby: Joi.string(),
    created_at:Joi.date()
        .optional(),
    updated_at:Joi.date()
        .optional()
});

export const joiReceivingSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    receivingDate:Joi.date()
    .optional(),
    supplier:Joi.string()
    .optional(),
    qty: Joi.number(),
    cost: Joi.number(),
    totalCost: Joi.number(),
    purchaseOrder: Joi.number(),
    invoice:Joi.string()
    .optional(),
    receivedBy:Joi.string()
    .optional(),
    created_at:Joi.date()
    .optional(),
    updated_at:Joi.date()
    .optional(),
});

export const invSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    product: productSchema
    .required(),
    qty: Joi.number()
    .min(0)
    .required(),
    type: Joi.string()
    .valid(...invTypes),
    cost: Joi.number()
    .min(0)
    .required(),
    price: Joi.number()
    .min(0)
    .optional(),
    publish: Joi.boolean()
    .required(),
    receive: joiReceivingSchema
    .optional(),
    dispatch: joiDispatchSchema
    .optional(),
    status:Joi.string()
    .optional(),
    updated_at: Joi.date()
    .optional(),
    created_at: Joi.date()
    .optional(),
    __v: Joi.number()
    .optional()
});

export default class InvForm extends Component {
    state = {
        inv: {
            product:{},
            qty:0,
            type:"STOCK",
            cost:0,
            price:0,
            status:"ENTERED",
            publish:false
        },
        errors: {},
        currentFormDisplay: "MainForm"
    }

    componentDidMount() {
        if (updateRemove.includes(this.props.mode)) {
            this.setState({
                inv: {
                    product:this.props.inv.product,
                    qty:this.props.inv.qty,
                    type:this.props.inv.type,
                    cost:this.props.inv.cost,
                    price:this.props.inv.price,
                    status:this.props.inv.status,
                    publish:this.props.inv.publish
                }
            })
        }     
    }

    setProduct = (product) => {
        let {...inv} = this.state;
        inv.product = product;
        this.setState({inv:inv});
    }

    renderTabs = () => { 
        return (
            <ul className="nav nav-tabs">
                <li className="nav-item" key="inventory">
                    {(this.state.currentFormDisplay==="MainForm") && <button className="nav-link active apc-tab-button" aria-current="page" onClick={()=> {this.setState({currentFormDisplay: "MainForm"})}}>Inventory</button>}
                    {(this.state.currentFormDisplay!=="MainForm") && <button className="nav-link apc-tab-button" aria-current="page" onClick={()=> {this.setState({currentFormDisplay: "MainForm"})}}>Inventory</button>}
                </li>
                <li className="nav-item" key="product">
                    {(this.state.currentFormDisplay==="ProductForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "ProductForm"})}}>Product</button>}
                    {(this.state.currentFormDisplay!=="ProductForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "ProductForm"})}}>Product</button>}
                </li>
            </ul> 
        )
    }

    formFields = () => {
        return(
            <React.Fragment>
                
                    <ProductDialog product={this.state.inv.product} setProduct={this.setProduct}/>
                   
                    <div className="apc-form-grid-3-cols">
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Quantity"
                            type="text"
                            name="qty"
                            placeholder="Quantity"
                            error={this.state.errors.qty}
                            onchange={this.handleChange}
                            value={this.state.inv.qty}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Cost"
                            type="text"
                            name="cost"
                            placeholder="Cost"
                            error={this.state.errors.cost}
                            onchange={this.handleChange}
                            value={this.state.inv.cost}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Price"
                            type="text"
                            name="price"
                            placeholder="price"
                            error={this.state.errors.price}
                            onchange={this.handleChange}
                            value={this.state.inv.price}
                            />
                        </div>
                    </div>
                    <div>
                        <CheckBox 
                        label="Publish to Website"
                        name="publish"
                        error={this.state.errors.publish}
                        onchange={this.handleChange}
                        value={this.state.inv.publish}
                        /> 
                    </div>
            </React.Fragment>
        )
    }

    productView = () => {
        return(
            <React.Fragment>
                {(Object.keys(this.state.inv.product).length !== 0) && <ProductsForm mode="view" title="Product Details" product={this.state.inv.product}/>}
                {(Object.keys(this.state.inv.product).length === 0) && <h2>No Product Selected for this Inventory</h2>}
            </React.Fragment>
        );
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currInv  = {...this.state.inv};
        currInv[name] = value;

        this.setState({
            inv: currInv
        });
    };

    validate = () => {
        const {inv} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(inv, invSchema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    }

    handleSubmit = async (e) => {
        //e.preventDefault();

        let url = "";
        let method = "";

        if (this.props.mode === "add") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory');
            method = 'post';
        }  else if (this.props.mode === "update") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/'+this.props.inv._id);
            method = 'put';
        }  else if (this.props.mode === "remove") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/'+this.props.inv._id);
            method = 'delete';
        }
             
        if (updateInsert.includes(this.props.mode)){
            const errors = this.validate();
            //console.log(errors);
            this.setState({errors: errors || {}});
            if (errors) return;

        } 

        const {inv} = this.state;
        let data = JSON.stringify({
            product:inv.product,
            qty:inv.qty,
            type:inv.type,
            cost:inv.cost,
            price:inv.price,
            publish:inv.publish,
            status:inv.status
        });
        
        //console.log(data);  
        var config = {
        method: method,
        url: url,
        headers: { 
            'x-auth-token': localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            const response = await Axios(config);
            if (response) {
                console.log(response);
                const updatedInv = response.data;
                if (this.props.mode === "add"){
                    this.props.insertInv(updatedInv);
                } else if (this.props.mode === "update"){
                    this.props.updateInv(updatedInv);
                } else if (this.props.mode === "remove"){
                    this.props.removeInv(updatedInv._id);
                }
                
            } 
            this.props.closeForm();
        } catch (error) {
            console.log(error.message);
            this.props.closeForm();
        }
    };

    render() { 
        let formcontents = "";
        if (this.props.mode==="remove"){
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = <fieldset disabled="disabled">{this.formFields()}</fieldset>
            else if (this.state.currentFormDisplay==="ProductForm")
                formcontents = <fieldset disabled="disabled">{this.productView()}</fieldset>
        }
        else if (this.props.mode==="view"){
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = <fieldset disabled="disabled">{this.formFields()}</fieldset>
            else if (this.state.currentFormDisplay==="ProductForm")
                formcontents = this.productView()
            
        } 
        else {
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = this.formFields()
            else if (this.state.currentFormDisplay==="ProductForm")
                formcontents = this.productView()
        }

        return (
            <div>
                <h2>{this.props.title}</h2>
                    <div className='apc-form-tabs'>{this.renderTabs()}</div>
                    <div className='container '>
                        {formcontents}
                        <div>
                            {(this.props.mode !== "view") && <React.Fragment><button className="btn btn-primary" type="button" onClick={this.handleSubmit}>
                                {this.props.mode}
                            </button>
                            <span>&nbsp;</span>
                            <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                                close
                            </button>
                            </React.Fragment>}
                        </div>
                        {/* </form> */}
                    </div>
            </div>
        );
    }
}
