import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faHouse } from '@fortawesome/free-solid-svg-icons';
import DBTable from '../../../components/woydbtable/woydbtable';
import Woymodal from '../../../components/woymodal/Woymodal';
import CustomersForm from '../../../components/forms/customersForm';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate, sortbyColumn} from '../../../utils/paginate';
import WoyCRUDSearch from '../../../components/woycrudsearch/WoyCRUDSearch';

class CustomersMaintenance extends Component {
    state = { 
        customers:[],
        filteredCustomers:[],
        selectedCustomer:{},
        showModal:false,
        dialogMode:"",
        formTitle:"",
        pageSize:25,
        currentPage:1,
        activeColumn:""
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/customers'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:customers} = await Axios(config);
            this.setState({customers:customers,filteredCustomers:customers});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
       // console.log(colName + " - " + searchValue);
        //const $regex = escapeStringRegexp(searchValue);
        const {customers} = this.state;
        let newCustomers = [];
        if (colName==="tags"){
            newCustomers = customers.filter( x => 
                x[colName].some(t => t.includes(searchValue)) 
              );
        } else {
            newCustomers = customers.filter( x => 
                x[colName].includes(searchValue) 
              );
        }
        
        this.setState({filteredCustomers:newCustomers})   
    }

    openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false,dialogMode:"add"});
    }

    addCustomerClick = () => {
        this.setState({dialogMode:"add"});
        this.openModalDialog();   
        this.setState({formTitle:"Add New Customer"});
    }

    editCustomerClick = (customer) => {
        this.setState({dialogMode:"update"});
        this.openModalDialog();      
        this.setState({selectedCustomer:customer});
        this.setState({formTitle:"Update - "+customer.name});
    }

    viewCustomerClick = (customer) => {
        this.setState({dialogMode:"view"});
        this.openModalDialog();      
        this.setState({selectedCustomer:customer});
        this.setState({formTitle:"View - "+customer.name});
    }

    removeCustomerClick = (customer) => {
        this.setState({dialogMode:"remove"});
        this.openModalDialog();      
        this.setState({selectedCustomer:customer});
        this.setState({formTitle:"Are you sure you want to delete this?"});
    }

    removeFromCustomers = (id) => {
        let newCustomers = this.state.customers.slice();
        let newCustomers2 = this.state.filteredCustomers.slice();
        newCustomers = newCustomers.filter((customer) => customer._id !== id);
        newCustomers2 = newCustomers2.filter((customer) => customer._id !== id);
        this.setState({customers:newCustomers,filteredCustomers:newCustomers2});
    }

    editFromCustomers = (editedCustomer) => {
        let editedCustomers = this.state.customers.slice();
        let editedCustomers2 = this.state.filteredCustomers.slice();
        editedCustomers = editedCustomers.map(customer => customer._id !== editedCustomer._id ? customer : editedCustomer);
        editedCustomers2 = editedCustomers2.map(customer => customer._id !== editedCustomer._id ? customer : editedCustomer);
        this.setState({customers:editedCustomers,filteredCustomers:editedCustomers2,selectedCustomer:editedCustomer});
    }

    addToCustomers = (newcustomer) => {
        let newcustomers = this.state.customers.slice();
        let newcustomers2 = this.state.filteredCustomers.slice();
        newcustomers.push(newcustomer);
        newcustomers2.push(newcustomer);
        this.setState({customers:newcustomers,filteredCustomers:newcustomers2})  
    }

    handlePageChange = (page) =>{
        //console.log(page);
        this.setState({currentPage:page});
        //this.setState({displaySuppliers:display});
    }

    handleSort = (colName) => {
        const sortedCustomers = sortbyColumn(this.state.filteredCustomers,colName);
        this.setState({filteredCustomers:sortedCustomers,activeColumn:colName});
    }

    setSelected = (selected) => {
        this.setState({selectedCustomer:selected});
    }

    setDialogMode = (mode) => {
        this.setState({dialogMode:mode});
    }
    
    render() { 
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"Contact",
                value:"contactperson"
            },
            {
                label:"Phone",
                value:"phoneno"
            },
            {
                label:"Address",
                value:"address"
            },
            {
                label:"Email",
                value:"email"
            },
                        
        ];

        const displayCustomer = paginate(this.state.filteredCustomers,this.state.currentPage,this.state.pageSize);

        const searchOptions = [
            {label:"Name", value:"name"},
            {label:"Contact Person", value:"contactperson"},
            {label:"Phone", value:"phoneno"}
        ];

        return (
            <div className="apc-maintenance-container">
            {(this.state.showModal) && (this.state.dialogMode==="add") && 
                <Woymodal>
                    <CustomersForm mode={this.state.dialogMode} title={this.state.formTitle} customer={this.state.selectedCustomer} insertCustomer={this.addToCustomers} updateCustomer={this.editFromCustomers} removeCustomer={this.removeFromCustomers} closeForm={this.closeModalDialog}/>
                </Woymodal>
            }
            <div className='apc-maintenance-title-container'>
                <div>
                    <h2>Manage Customers</h2>
                </div>
                <div>
                    <WoyCRUDSearch options={searchOptions} defaultOption="tags" searchClick={this.handleSearch}/>
                </div>
                <div className='apc-maintenance-addbutton-container'>
                    <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                    <span>&nbsp;</span>
                    <button type="button" onClick={this.addCustomerClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
            
           {/*  <DBTable 
                data={displayCustomer} 
                user={this.props.user} 
                display={display} 
                addMethod={this.addCustomerClick} 
                updateMethod={this.editCustomerClick} 
                deleteMethod={this.removeCustomerClick} 
                sortBy={this.state.activeColumn} 
                sortData={this.handleSort} 
                linkColumns={["website"]}
            /> */}

            <DBTable 
                data={displayCustomer} 
                user={this.props.user} 
                display={display} 
                addMethod={this.addCustomerClick} 
                updateMethod={this.editCustomerClick} 
                deleteMethod={this.removeCustomerClick}
                viewMethod={this.viewCustomerClick} 
                sortBy={this.state.activeColumn} 
                sortData={this.handleSort} 
                linkColumns={["website"]}
                mode={this.state.dialogMode}
                setMode={this.setDialogMode}
                setSelected={this.setSelected}
                closeForm={this.closeModalDialog}
                columns={6}     
            >
                <CustomersForm mode={this.state.dialogMode} title={this.state.formTitle} customer={this.state.selectedCustomer} insertCustomer={this.addToCustomers} updateCustomer={this.editFromCustomers} removeCustomer={this.removeFromCustomers} closeForm={this.closeModalDialog}/>
            </DBTable>
            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayCustomer.length} of {this.state.filteredCustomers.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredCustomers.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange} />
            </div>
        </div>
        );
    }
}
 
export default CustomersMaintenance;