import React, { Component } from 'react';
import WoyDatePicker from '../input/woydatepicker';
import InputBox from '../input/inputbox2';
import ButtonInvoiceDialog from '../dialogs/buttonInvoiceDialog';
import Axios from 'axios';
import Joi from 'joi-browser';

const updateview = ["update","view"];

const joiDispatchSchema = Joi.object({
    _id:Joi.string()
        .optional(),
    dispatchDate:Joi.date()
        .optional(),
    qty: Joi.number(),
    invoiceid: Joi.string(),
    receivername: Joi.string(),
    receivercontactno: Joi.string(),
    receiveraddress: Joi.string(),
    description: Joi.string(),
    dispatchby: Joi.string(),
    created_at:Joi.date()
        .optional(),
    updated_at:Joi.date()
        .optional()
});

const dispatchInputBox = (label, type, name, placeholder, error, onchange, value) =>{
    if (value === null)
        value='';
    const textbox = <input className="form-control" type={type} id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange} value={value}/>;
    return(
        <div className="form-floating mb-3">
           
            {textbox}
            <label htmlFor={"id-"+name}>{label}</label>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}

class DispatchForm extends Component {
    state = {
        dispatch:{
            dispatchDate:Date.now(),
            qty:1,
            invoiceid:"----",
            receivername:"----",
            receivercontactno:"----",
            receiveraddress:"----",
            description:"",
            dispatchby: this.props.user.name,
        },
        selectedInvoice:{},
        errors:{}
    }
    
    componentDidMount = () => {
        if (updateview.includes(this.props.mode)){
            if (this.props.dispatch) {
                if (Object.keys(this.props.dispatch).length !== 0){
                    this.setDispatch(this.props.dispatch);
                }
            }
            
        }
    }

    validate = () => {
        const {dispatch} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(dispatch, joiDispatchSchema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    }

    handleChange = (e) => {
        //e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currRec  = {...this.state.dispatch};
        currRec[name] = value;
        this.setState({dispatch:currRec});
        this.props.setDispatch(currRec);
    };

    setDispatch = (dis) =>{
        this.setState({dispatch:dis});
      }

    setDispatchDate = (disdate) => {
        let dis = {...this.state.dispatch};
        dis.dispatchDate = disdate;
        this.setDispatch(dis);
      }

    setInvoice = (inv) => {
        
        let tempDis = {...this.state.dispatch};
        tempDis.invoiceid = inv._id.toString();
        tempDis.receivername = inv.customer.name +" (" + inv.customer.contactperson + ")";
        tempDis.receiveraddress = inv.customer.address;
        tempDis.receivercontactno = inv.customer.phoneno;
        this.props.setDispatch(inv,tempDis);
        this.setState({dispatch:tempDis,selectedInvoice:inv});
    }

    handleSubmit = async (e) => {
        //e.preventDefault();

        let url = "";
        let method = "";
       
        url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/'+this.props.inv._id);
        method = 'put';
        const errors = this.validate();
        console.log(errors);
        this.setState({errors: errors || {}});
        if (errors) return;

        //const {selectedInvoice} = this.state;
        let data = JSON.stringify({
            product:this.props.inv.product,
            qty:0,
            type:this.props.inv.type,
            cost:this.props.inv.cost,
            price:this.props.inv.price,
            publish:this.props.inv.publish,
            dispatch:this.state.dispatch,
            status:"DISPATCHED"
        });
        
        //console.log(data);  
        var config = {
            method: method,
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        try {
            const response = await Axios(config);
            if (response) {
                //console.log(response);
                const updatedInv = response.data;
                this.props.updateInv(updatedInv);                
            } 
            this.props.closeForm();
        } catch (error) {
            console.log(error.message);
            this.props.closeForm();
        }
    };

    render() { 
        return (
        <div>
            <div>
                <h3>{this.props.title}</h3>
            </div>
            <div className="row g-2 mt-1">
                <div className="col-md">
                    <WoyDatePicker selectedDate={this.state.dispatch.dispatchDate} selectDate={this.setDispatchDate} label="Dispatch Date"/>
                </div>
                <div className="col-md">
               
                            <ButtonInvoiceDialog
                                label="Invoice ID"
                                value={this.state.dispatch.invoiceid}
                                setInvoice={this.setInvoice}
                                user={this.props.user}
                            />
               
                </div>
            </div>
           
            <div className="row g-2 mt-1">
                <div className="col-md">
                    {dispatchInputBox("Receiver Name", "text", "receivername", "Receiver Name", this.state.errors.receivername, this.handleChange, this.state.dispatch.receivername)}
                    {/* <dispatchInputBox 
                    label="Receiver Name"
                    type="text"
                    name="receivername"
                    placeholder="Receiver Name"
                    error={this.state.errors.receivername}
                    onchange={this.handleChange}
                    value={this.state.dispatch.receivername}
                    />   */}
                </div>
                <div className="col-md">
                    {dispatchInputBox("Contact No", "text", "receivercontactno", "Contact No", this.state.errors.receivercontactno, this.handleChange, this.state.dispatch.receivercontactno)}
                    {/* <dispatchInputBox
                    label="Contact No"
                    type="text"
                    name="receivercontactno"
                    placeholder="Contact No"
                    error={this.state.errors.receivercontactno}
                    onchange={this.handleChange}
                    value={this.state.dispatch.receivercontactno}
                    />   */}
                </div>
            </div>
            <div className="row g-1 mt-1">
                {dispatchInputBox("Receiver Address", "text", "receiveraddress", "Receiver Address", this.state.errors.receiveraddress, this.handleChange, this.state.dispatch.receiveraddress)}
                {/* <div className="col-md">
                    <dispatchInputBox 
                    label="Receiver Address"
                    type="text"
                    name="receiveraddress"
                    placeholder="Receiver Address"
                    error={this.state.errors.receiveraddress}
                    onchange={this.handleChange}
                    value={this.state.dispatch.receiveraddress}
                    />  
                </div> */}
            </div>

            <div className="row g-1 mt-1">
               {dispatchInputBox("Description", "text", "description", "Description", this.state.errors.description, this.handleChange, this.state.dispatch.description)}
               {/* <div className="col-md">
                    <dispatchInputBox 
                    label="Description"
                    type="text"
                    name="description"
                    placeholder="----"
                    error={this.state.errors.description}
                    onchange={this.handleChange}
                    value={this.state.dispatch.description}
                    />  
                </div> */}
            </div>

            <div>
                <button className="btn btn-primary" type="button" onClick={this.handleSubmit}>Save</button>
                <span>&nbsp;</span>
                <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>Close</button>
            </div>
        </div>);
    }
}
 
export default DispatchForm;