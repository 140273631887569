import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './woydialog.css'
import WoyPopUp from '../popup/woyPopUp';
import WoyCRUDSearch from '../woycrudsearch/WoyCRUDSearch';
import DBTableBrowse from '../woydbtable/woydbtablebrowse';
import {sortbyColumn} from '../../utils/paginate';

class ButtonCustomerDialog extends Component {
    state = {
        customers: [],
        filteredCustomers: [],
        selectedCustomer: {},
        activeColumn: "name",
        showPopUp: false    
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/customers'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:customers} = await Axios(config);
            this.setState({customers:customers,filteredCustomers:customers});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
        // console.log(colName + " - " + searchValue);
         //const $regex = escapeStringRegexp(searchValue);
        const {customers} = this.state;
        let newCustomers = [];
         
        newCustomers = customers.filter( x => 
            x[colName].includes(searchValue) 
        );
         
         this.setState({filteredCustomers:newCustomers})   
     }

    openCustomerBrowser = () => {
        this.setState({showPopUp: true});
    }

    closeCustomerBrowser = () => {
        this.setState({showPopUp: false});
    }

    selectCustomerClick = (selectedCustomer) => {
        this.setState({selectedCustomer: selectedCustomer});
        this.props.setCustomer(selectedCustomer);
        this.closeCustomerBrowser();
    }

    handleSort = (colName) => {
        const sortedCustomers = sortbyColumn(this.state.filteredCustomers,colName);
        this.setState({filteredCustomers:sortedCustomers,activeColumn:colName});
    }

    render() {
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"Contact",
                value:"contactperson"
            },
            {
                label:"Phone",
                value:"phoneno"
            },
            {
                label:"Address",
                value:"address"
            },
            {
                label:"Email",
                value:"email"
            },
                        
        ];

        const displayCustomer = this.state.filteredCustomers;
        //console.log(displayProduct);

        const searchOptions = [
            {label:"Name", value:"name"},
            {label:"Contact Person", value:"contactperson"},
            {label:"Phone", value:"phoneno"}
        ];

        return (
         <div>
            {
                this.state.showPopUp && 
                    <WoyPopUp handleClose={this.closeCustomerBrowser}>
                        <div>
                            <WoyCRUDSearch options={searchOptions} defaultOption="name" searchClick={this.handleSearch}/>
                        </div>
                        <DBTableBrowse 
                        data={displayCustomer} 
                        user={this.props.user} 
                        display={display} 
                        selectMethod={this.selectCustomerClick} 
                        sortBy={this.state.activeColumn} 
                        sortData={this.handleSort} 
                        linkColumns={["website"]}
                        />
                    </WoyPopUp>    
            } 
            <div class="input-group">
                <button className="btn btn-outline-success" type="button" id="button-addon1" onClick={this.openCustomerBrowser}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
            </div>
         </div>
        );
    }
}
 
export default ButtonCustomerDialog;