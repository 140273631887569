import React, { Fragment } from 'react';

const InvoiceInputBox = ({type, name, placeholder, error, onchange, value, align, custom}) =>{
    
    return(
        <Fragment>
            <input className={"tbEdit "+align+" "+custom} type={type} id={"id-"+name} name={name} placeholder={placeholder} onChange={(event) => {onchange(event)}} defaultValue={value}/>
            {error && <div className="alert alert-danger">{error}</div>}
        </Fragment>
    );
       
}
export default InvoiceInputBox;