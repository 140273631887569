import React, { Component } from 'react';
class Hire extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>APC Hire Page</h1>
            </div>
        );
    }
}
 
export default Hire;