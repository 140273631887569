import React, { Component } from 'react';
//import moment from 'moment';
import InvoiceInputBox from './invoiceinputbox';
import InvoiceCheckBox from './invoicecheckbox';
import InvoiceDetailsInputBox from './invoicedetailsinputbox';
import InvoiceDetailsDescInputBox from './invoicedetailsdescinputbox';
import ButtonCustomerDialog from '../../dialogs/buttonCustomerDialog';
import ButtonWoyDatePicker from '../../input/buttonwoydatepicker';
import WoyPopUp from '../../popup/woyPopUp';
import InventoryBrowser from '../../woydbtable/inventoryBrowser';
import WoyRemittanceUploader from '../../fileupload/WoyRemittanceUploader';
import SelectBox from '../../input/selectbox';
import {formatMoney, formatDate} from '../../../utils/woyutils';
import './invoiceformtemplate.css';

class InvoiceFormCashSale extends Component {
    state = {
        productBrowser:false,
        selectedInv:{}
    } 

    poText = (po) =>  {
        if (po === "----") 
            return "Cash Sale Acct" 
        else
            return po;
    }

    getqrcode = (folder) => {
        const idstr = this.props.invoice._id.toString();
        const qrcode = "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=https://api.australianpower.net/public/uploads/"+folder+"/"+idstr+".pdf&choe=UTF-8"
        return qrcode;
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currRec  = {...this.props.invoice};
        currRec[name] = value;

        if (name === "withGST")
        {
            
            if (value) {
                //console.log("GST Enabled");
                this.calcTotals(currRec);
            } else {
                //console.log("GST Disabled");
                currRec.gstRate=0;
                this.calcTotals(currRec);
            }    
        }
        
        if (name === "gstRate"){
            if (currRec.withGST) {
                this.calcTotals(currRec);
            }
        }

        this.props.setInv(currRec);
    };

    calcTotals = (inv) => {
        let tempGST = 0;
        let sumAmt = 0;
        let tempDetails = inv.invoiceDetails.slice();
        
        if (Array.isArray(tempDetails)) {
            sumAmt = tempDetails.reduce((accumulator, object) => {
                return accumulator + object.totalAmt;
              }, 0);
        
        } else {
            sumAmt = tempDetails.totalAmt;
        } 
        
        if (inv.withGST) {
            tempGST = sumAmt * (inv.gstRate/100);
        }
        
        inv.subTotal = sumAmt;
        inv.gst = tempGST;
        inv.total = sumAmt + tempGST; 
      };

    handleDetailsChange = (event,index) => {
        let data = [...this.props.invoice.invoiceDetails];
        data[index][event.target.name] = event.target.value;
        this.props.setInvDetails(index, data[index]);
    }

    handleDescChange = (event,detailIndex,descIndex) => {
        let invoiceDetails = this.props.invoice.invoiceDetails.slice();
        let descArray = invoiceDetails[detailIndex][event.target.name];
        descArray[descIndex] = event.target.value;
        this.props.setInvDetailsDesc(detailIndex,descIndex,descArray[descIndex]);
    }

    HandleAddBlankDescToDetails = (event,detailIndex) => {
        this.props.addBlankDescToDetails(detailIndex);
    }

    HandleRemoveDescFromDetails  = (e,index) => {
        this.props.removeDescFromDetails (index,e.target.value);
    }

    HandleRemoveDetailEntry = (e) => {
        this.props.removeDetailEntry(e.target.value);
    }

    HandleInvoiceDateChange = (invdate) => {
        this.props.setInvDate(invdate);
    }

    HandleRemittance = (path) =>{
        this.props.setRemittance(path);
    }

    HandleAddNewDetailEntryOther = () => {
        let detail = {
            detailType: "SALE",
            productid: "----",
            description: [
                "",
                ""
            ],
            qty: 1,
            uom: "PC",
            amt: 0,
            totalAmt: 0
        };

        this.props.addNewDetail(detail);
    }

    HandleAddNewDetailEntryProduct = (inv) => {
        this.setInv(inv);
        let detail = {
            detailType: "SALE",
            productid: inv.product._id.toString(),
            description: [
                inv.product.name,
                "Serial No. "+inv.product.serialno
            ],
            qty: 1,
            uom: "PC",
            amt: inv.price,
            totalAmt: inv.price
        };
        this.props.addNewDetail(detail);
        this.closeProductBrowser();
    }

    closeProductBrowser = () => {
        this.setState({productBrowser:false});
    }


    openProductBrowser = () => {
        this.setState({productBrowser:true});
    }

    setInv = (inv) => {
        this.setState({selectedInv:inv});
    }

    render() { 
        const editViewDelete = ["view","update","remove"];
        const enumInvoiceStatus = ["ISSUED","PAID","CANCELLED"];
        const letterHeader = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_INVOICE_LETTERHEAD;
        //const letterFooter = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_INVOICE_FOOTER;
        //console.log(letterHeader);
        const remittanceUploader = editViewDelete.includes(this.props.mode) ? <WoyRemittanceUploader uploadButtonLabel="Remittance Proof" preview={this.props.invoice.remittanceEvidence} setParentField={this.HandleRemittance} mode={this.props.mode}/>:<WoyRemittanceUploader uploadButtonLabel="Upload Remittance Proof" setParentField={this.HandleRemittance} mode={this.props.mode}/>; 
        let saveButton = "";
        let btnType = "";
        if (this.props.mode === "add"){
            saveButton = "create invoice";
            btnType = "btn-primary";
        } else if (this.props.mode === "update"){
            saveButton = "save invoice";
            btnType = "btn-primary";
        }else{
            saveButton = "remove invoice";
            btnType = "btn-warning";
        };

        return (
            <div className="pageA4">
                <div className="letterHead">
                    <img className="compLogo" src={letterHeader} alt="APC Letter Head"/>
                </div>
                <div className="content">
                    <table className="header-layout">
                        <tbody>
                        <tr>
                            <td className="invoice-header-left">
                                <div className="customerHeaderContiner">
                                    <div>
                                        <div className="header-label">Bill  To:</div>
                                        <div>{this.props.invoice.customer.name}</div>
                                        <div>{this.props.invoice.customer.address}</div>
                                    </div>
                                    <div>
                                        <div className='customerButtonContainer'>
                                            <ButtonCustomerDialog
                                                setCustomer={this.props.setCustomer}
                                                user={this.props.user}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="blankLine"></div>
                                <div className="header-label">Attention:</div>
                                <div>{this.props.invoice.customer.contactperson}</div>
                                <div>{this.props.invoice.customer.email}</div>
                                <div>{this.props.invoice.customer.phoneno}</div>
                            </td>
                            <td className="invoice-header-right">
                                <div className="header-title">TAX INVOICE</div>
                                <table className="subTable">
                                    <colgroup>
                                        <col className="col40mm"/>
                                        <col className="col30mm"/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td className="invoiceNo-layout">No:</td>
                                        <td className="invoiceNo-layout">
                                            <InvoiceInputBox 
                                                align="alignRight"
                                                type="text"
                                                name="invoiceNo"
                                                placeholder="----"
                                                onchange={this.handleChange}
                                                value={this.props.invoice.invoiceNo}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="invoiceNo-layout">Date:</td>
                                        <td className="invoiceNo-layout">
                                            <div className='dateContainer'>
                                                <div className='dateBox'>{formatDate(this.props.invoice.invoiceDate)}</div>
                                                <div>
                                                    <ButtonWoyDatePicker 
                                                        label="Invoice Date"
                                                        selectedDate={this.props.invoice.invoiceDate}
                                                        selectDate={this.HandleInvoiceDateChange}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="invoiceNo-layout">PO:</td>
                                        <td className="invoiceNo-layout">{this.poText(this.props.invoice.purchaseOrderNo)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                        
                    
                    <div className="blankLine"></div>
                    <table className="header-layout">
                        <colgroup>
                        <col className="col90mm"/>
                        <col className="col10mm"/>
                        <col className="col50mm"/>
                        <col className="col50mm"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="details-header">Description</th>
                                <th className="details-header">Qty</th>
                                <th className="details-header money">Price</th>
                                <th className="details-header-total money">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                        {this.props.invoice.invoiceDetails.map((detail,index) =>
                        <tr key={index}>
                            <td className="details-content">
                               {detail.description.map((desc,descindex) => <div className='descContainer' key={descindex}>
                                    <div>
                                        <InvoiceDetailsDescInputBox
                                            detailindex={index}
                                            descindex={descindex} 
                                            align="alignLeft maxWidth"
                                            type="text"
                                            name="description"
                                            placeholder="----"
                                            onchange={this.handleDescChange}
                                            value={desc}
                                        />
                                    </div>
                                    <div>
                                        <button className='minusButton' value={descindex} onClick={(e)=> this.HandleRemoveDescFromDetails(e,index)}>-</button>    
                                    </div>
                                </div>)}
                                <div className='entryButtonsContainer'>
                                    <div><button className='plusButton' onClick={(e)=> this.HandleAddBlankDescToDetails(e,index)}>+ description </button></div>
                                    <div><button className='minusButton' value={index} onClick={(e)=> this.HandleRemoveDetailEntry(e)}>- remove this entry </button></div>
                                    <div></div>
                                </div>
                            </td>
                            <td className="details-content">
                                <InvoiceDetailsInputBox
                                        index={index} 
                                        align="alignLeft"
                                        type="text"
                                        name="qty"
                                        placeholder="0"
                                        onchange={this.handleDetailsChange}
                                        value={detail.qty}
                                    />  
                            </td>
                            <td className="details-content money">
                                <InvoiceDetailsInputBox
                                    index={index} 
                                    align="alignRight"
                                    type="text"
                                    name="amt"
                                    placeholder="0.00"
                                    onchange={this.handleDetailsChange}
                                    value={detail.amt}
                                />  
                            </td>
                            <td className="details-content money">
                                {formatMoney(detail.totalAmt)}
                            </td>
                        </tr>)}
                        
                        <tr>
                            <td className="blank-subtotal-content" colSpan={2}>
                                <div>
                                    {
                                    this.state.productBrowser && 
                                    <WoyPopUp handleClose={this.closeProductBrowser}>
                                        <div>
                                            <InventoryBrowser type="STOCK" setInv={this.HandleAddNewDetailEntryProduct}/>
                                        </div>
                                    </WoyPopUp>    
                                    }
                                </div>
                                <div className='entryButtonsContainer'>
                                    <div className='entryButtonDiv'><button className='entryButtons btn btn-outline-success' onClick={this.openProductBrowser}> + product </button></div>
                                    <div className='entryButtonDiv'><button className='entryButtons btn btn-outline-success' onClick={this.HandleAddNewDetailEntryOther}> + other </button></div>
                                    {/* <div className='entryButtonDiv'>
                                        
                                    </div> */}
                                </div>
                            </td>
                            {/* <td className="blank-subtotal-content"></td>  */}
                            <td className="subtotal-content money">
                                Sub-Total
                            </td>
                            <td className="subtotal-content money">
                                {formatMoney(this.props.invoice.subTotal)}
                            </td>
                        </tr>
                        <tr>
                            <td className="blank-subtotal-content">
                                <SelectBox
                                    label="Invoice Status"
                                    name="invoiceStatus"
                                    placeholder="Select Status"
                                    error=""
                                    onchange={this.handleChange}
                                    value={this.props.invoice.invoiceStatus}
                                    items={enumInvoiceStatus}
                                />     
                            </td>
                            <td className="blank-subtotal-content"></td>
                            <td className="subtotal-content money">
                                <div  className='gstGrid'>
                                    <div>
                                        <InvoiceCheckBox
                                            label = "GST  (%)"
                                            name = "withGST" 
                                            placeholder = "GST" 
                                            error="" 
                                            onchange={this.handleChange} 
                                            value={this.props.invoice.withGST} 
                                        />
                                    </div>
                                    <div>
                                        <InvoiceInputBox 
                                            align="alignLeft"
                                            type="text"
                                            name="gstRate"
                                            placeholder="----"
                                            onchange={this.handleChange}
                                            value={this.props.invoice.gstRate}
                                            custom="gstRateBox"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td className="subtotal-content money">
                                {formatMoney(this.props.invoice.gst)}
                            </td>
                        </tr>
                        <tr>
                            <td className="blank-subtotal-content" colSpan={2}>
                                {(this.props.invoice.invoiceStatus === "PAID") && <div>
                                <div>
                                    Remittance Proof
                                    {remittanceUploader}   
                                </div>
                                </div>}

                            </td>
                            {/* <td className="blank-subtotal-content"></td>  */}
                            <td className="details-header money">
                                Grand Total
                            </td>
                            <td className="details-header-total money">
                                {formatMoney(this.props.invoice.total)}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="bottomBox">
                        <table className="header-layout">
                        
                            <colgroup>
                            <col className="col160mm"/>
                            <col className="col40mm"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td>
                                    <div className="header-label">Payment Information:</div>
                                    <table className="subTable">
                                        <colgroup>
                                        <col className="col40mm"/>
                                        <col className="col120mm"/>
                                        </colgroup>
                                        <tr>
                                            <td className="paymentInfo-lbl">Bank</td>
                                            <td className="paymentInfo-txt">Commonwealth Bank of Australia</td>
                                        </tr>
                                        <tr>
                                            <td className="paymentInfo-lbl">Account</td>
                                            <td className="paymentInfo-txt">Australian Power Corporation</td>
                                        </tr>
                                        <tr>
                                            <td className="paymentInfo-lbl">BSB</td>
                                            <td className="paymentInfo-txt">064-186</td>
                                        </tr>
                                        <tr>
                                            <td className="paymentInfo-lbl">Account No.</td>
                                            <td className="paymentInfo-txt">1096 7401</td>
                                        </tr>
                                        <tr>
                                            <td className="paymentInfo-lbl">Email</td>
                                            <td className="paymentInfo-txt">accounts@australianpower.net</td>
                                        </tr>
                                        <tr>
                                            <td className="paymentInfo-lbl">ABN</td>
                                            <td className="paymentInfo-txt">44 638 146 335</td>
                                        </tr>
                                    </table>    
                                </td>
                                <td>
                                    <div className="qrCodeBox">
                                        <img className="compLogo" src={this.getqrcode("invoices")} alt="QR Code"/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>    
                        </table>
                        <div className='entryButtonsContainer'>
                            <div  className='entryButtonDiv'>
                                <button className={"btn "+btnType} type="button" onClick={this.props.handleSubmit}>
                                    {saveButton}
                                </button>
                            </div>
                            {((this.props.mode === "update") || (this.props.mode === "remove")) && <div  className='entryButtonDiv'>
                                <button className="btn btn-success" type="button" onClick={this.props.sendInvoice}>
                                    email to client
                                </button>
                            </div>}
                            <div  className='entryButtonDiv'>
                                <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                                    close
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {/* <div className="letterFoot">
                    <img className="compLogo" src={letterFooter} alt="APC Letter Head"/>
                </div> */}
        </div>);
    }
}
 
export default InvoiceFormCashSale;