import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faHouse } from '@fortawesome/free-solid-svg-icons';
import DBTable from '../../../components/woydbtable/woydbtable';
import Woymodal from '../../../components/woymodal/Woymodal';
import SuppliersForm from '../../../components/forms/suppliersform';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate, sortbyColumn} from '../../../utils/paginate';
import WoyCRUDSearch from '../../../components/woycrudsearch/WoyCRUDSearch';

class SuppliersMaintenance extends Component {
    state = { 
        suppliers:[],
        filteredSuppliers:[],
        selectedSupplier:{},
        showModal:false,
        dialogMode:"",
        formTitle:"",
        pageSize:10,
        currentPage:1,
        activeColumn:""
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/suppliers'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:suppliers} = await Axios(config);
            this.setState({suppliers:suppliers,filteredSuppliers:suppliers});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
       // console.log(colName + " - " + searchValue);
        //const $regex = escapeStringRegexp(searchValue);
        const {suppliers} = this.state;
        let newSuppliers = [];
        if (colName==="tags"){
            newSuppliers = suppliers.filter( x => 
                x[colName].some(t => t.includes(searchValue)) 
              );
        } else {
            newSuppliers = suppliers.filter( x => 
                x[colName].includes(searchValue) 
              );
        }
        
        this.setState({filteredSuppliers:newSuppliers})   
    }

    openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false,dialogMode:"add"});
    }

    setDialogMode = (mode) => {
        this.setState({dialogMode:mode});
    }

    addSupplierClick = () => {
        this.setState({dialogMode:"add"});
        this.openModalDialog();   
        this.setState({formTitle:"Add New Supplier"});
    }

    editSupplierClick = (supplier) => {
        this.setState({dialogMode:"update"});
        this.openModalDialog();      
        this.setState({selectedSupplier:supplier});
        this.setState({formTitle:"Update - "+ supplier.name});
    }

    viewSupplierClick = (supplier) => {
        this.setState({dialogMode:"view"});
        this.openModalDialog();      
        this.setState({selectedSupplier:supplier});
        this.setState({formTitle:"View - "+ supplier.name});
    }

    removeSupplierClick = (supplier) => {
        this.setState({dialogMode:"remove"});
        this.openModalDialog();      
        this.setState({selectedSupplier:supplier});
        this.setState({formTitle:"Are you sure you want to delete this?"});
    }

    removeFromSuppliers = (id) => {
        let newSuppliers = this.state.suppliers.slice();
        let newSuppliers2 = this.state.filteredSuppliers.slice();
        newSuppliers = newSuppliers.filter((supplier) => supplier._id !== id);
        newSuppliers2 = newSuppliers2.filter((supplier) => supplier._id !== id);
        this.setState({suppliers:newSuppliers,filteredSuppliers:newSuppliers2});

    }

    editFromSuppliers = (editedSupplier) => {
        let editedSuppliers = this.state.suppliers.slice();
        let editedSuppliers2 = this.state.filteredSuppliers.slice();
        editedSuppliers = editedSuppliers.map(supplier => supplier._id !== editedSupplier._id ? supplier : editedSupplier);
        editedSuppliers2 = editedSuppliers2.map(supplier => supplier._id !== editedSupplier._id ? supplier : editedSupplier);

        this.setState({suppliers:editedSuppliers,filteredSuppliers:editedSuppliers2,selectedSupplier:editedSupplier})  
    }

    addToSuppliers = (newsupplier) => {
        let newsuppliers = this.state.suppliers.slice();
        let newsuppliers2 = this.state.filteredSuppliers.slice();
        newsuppliers.push(newsupplier);
        newsuppliers2.push(newsupplier);
        this.setState({suppliers:newsuppliers,filteredSuppliers:newsuppliers2})  
    }

    setSelected = (selected) => {
        this.setState({selectedSupplier:selected});
    }

    handlePageChange = (page) =>{
        //console.log(page);
        this.setState({currentPage:page});
        //this.setState({displaySuppliers:display});
    }

    handleSort = (colName) => {
        const sortedSuppliers = sortbyColumn(this.state.filteredSuppliers,colName);
        this.setState({filteredSuppliers:sortedSuppliers,activeColumn:colName});
    }
    
    render() { 
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"CA",
                value:"withcreditaccount"
            },
            {
                label:"Address",
                value:"address"
            },
            {
                label:"Email",
                value:"email"
            },
            {
                label:"Website",
                value:"website"
            },
            {
                label:"Supply Tags",
                value:"tags"
            }
        ];

        const displaySupplier = paginate(this.state.filteredSuppliers,this.state.currentPage,this.state.pageSize);

        const searchOptions = [
            {label:"Supplier Tag", value:"tags"},
            {label:"Name", value:"name"}
        ];

        return (
            <div className="apc-maintenance-container">
            {(this.state.showModal) && (this.state.dialogMode === "add") && 
                <Woymodal>
                    <SuppliersForm mode={this.state.dialogMode} title={this.state.formTitle} supplier={this.state.selectedSupplier} insertSupplier={this.addToSuppliers} updateSupplier={this.editFromSuppliers} removeSupplier={this.removeFromSuppliers} closeForm={this.closeModalDialog}/>
                </Woymodal>
            }
            <div className='apc-maintenance-title-container'>
                <div>
                    <h2>Manage Suppliers</h2>
                </div>
                <div>
                    <WoyCRUDSearch options={searchOptions} defaultOption="tags" searchClick={this.handleSearch}/>
                </div>
                <div className='apc-maintenance-addbutton-container'>
                    <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                    <span>&nbsp;</span>
                    <button type="button" onClick={this.addSupplierClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
            
            <DBTable 
                data={displaySupplier} 
                user={this.props.user} 
                display={display} 
                addMethod={this.addSupplierClick} 
                updateMethod={this.editSupplierClick} 
                viewMethod={this.viewSupplierClick} 
                deleteMethod={this.removeSupplierClick} 
                sortBy={this.state.activeColumn} 
                sortData={this.handleSort} 
                tagsColumns={["tags"]} 
                linkColumns={["website"]}
                mode={this.state.dialogMode}
                setMode={this.setDialogMode}
                setSelected={this.setSelected}
                closeForm={this.closeModalDialog}
                columns={7}     
            >
                <SuppliersForm mode={this.state.dialogMode} title={this.state.formTitle} supplier={this.state.selectedSupplier} insertSupplier={this.addToSuppliers} updateSupplier={this.editFromSuppliers} removeSupplier={this.removeFromSuppliers} closeForm={this.closeModalDialog}/>
            </DBTable>
            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displaySupplier.length} of {this.state.filteredSuppliers.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredSuppliers.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange} />
            </div>
        </div>
        );
    }
}
 
export default SuppliersMaintenance;