import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { DayPicker } from 'react-day-picker';
//import { format } from 'date-fns';
import moment from 'moment'
import 'react-day-picker/dist/style.css';
import WoyPopUp from '../popup/woyPopUp';

class WoyDatePicker extends Component {
    state = {  
        //selectedDate: this.props.selectedDate,
        showPicker: false
    } 

    componentDidMount() {
        if (this.props.selectedDate)
            this.setSelectedDate(Date.parse(this.props.selectedDate));
        //this.setState({selectedDate: this.props.selectedDate});
        //console.log(this.state.selectedDate);
    }
    
    setSelectedDate = (selectedDate) => {
        //this.setState({selectedDate: selectedDate});
        this.props.selectDate(selectedDate);
        this.closePicker();
    }

    showPicker = () => {
        this.setState({showPicker:true});
    }

    closePicker = () => {
        this.setState({showPicker:false});
    }

    formattedDate = () =>{
        
        //const dateVal = this.state.selectedDate;
        try {
            return(
                //format(this.props.selectedDate, 'dd-MMM-yyyy')
                moment(this.props.selectedDate).format("DD-MMM-YYYY")
            );        
        } catch (error) {
            console.log(error);
            return(moment().format("DD-MMM-YYYY"));
            //return(format(new Date(), 'dd-MMM-yyyy'));
        };
        
    }

    handleChange = (e) => {
        try {
            //this.setState({selectedDate: Date.parse(e.target.value)}); 
            this.setSelectedDate(Date.parse(e.target.value));   
        } catch (error) {
            console.log("Date Parse Error. Returning present date.")    
        }
        
    }

    render() { 
        return (
            <div>
                <div>
                    {(this.state.showPicker) && 
                    <WoyPopUp handleClose={this.closePicker} small={true}>
                        <h3>{this.props.label}</h3>
                        <DayPicker
                        mode="single"
                        selected={this.props.selectedDate}
                        onSelect={this.setSelectedDate}
                        />    
                    </WoyPopUp>}
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">{this.props.label}</span>
                    <input type="text" className="form-control" id="floatingInputGrid" placeholder={this.formattedDate()} />
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.showPicker}><FontAwesomeIcon icon={faCalendar}/></button>
                </div>
            </div>
        );
    }
}
 
export default WoyDatePicker;