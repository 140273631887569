import _ from 'lodash';

export function paginate(items, pageNumber, pageSize) {
    if (items.length <= pageSize)
        return(items);
    const startIndex = (pageNumber -1) * pageSize;
    return _(items)
        .slice(startIndex)
        .take(pageSize)
        .value();
}

export function sortbyColumn(items, colName){
    return items.sort((a, b) => (a[colName] > b[colName]) ? 1 : -1)
}