import {React, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

class WoyCRUDSearch extends Component {
    state = {
        searchText:"",
        searchColName:""
    }

    componentDidMount(){
        this.setState({searchColName:this.props.defaultOption});
    }

    handleColNameChange = (e) =>{
        this.setState({searchColName:e.target.value});
    }

    handleSearchTextChange = (e) =>{
        this.setState({searchText:e.target.value});
    }

    render() {
        const {options, searchClick} = this.props;
        return (
            <div className="input-group mb-3 apc-maintenance-search">
                <select className="custom-select" id="inputGroupSelect01" onChange={this.handleColNameChange}>
                {options.map(option => 
                        <option key={"id-"+option.value} value={option.value}>{option.label}</option>
                )}
                </select>
                
                <input type="text" className="form-control" placeholder="Enter Text to Search" aria-label="Enter Text to Search" onChange={this.handleSearchTextChange}/>
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" onClick={()=>{searchClick(this.state.searchColName,this.state.searchText)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                </div>
            </div>
        );
    }
}

export default WoyCRUDSearch;