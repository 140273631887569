import React from 'react';

const InputBox = ({label, type, name, placeholder, error, onchange, value, readonly}) =>{
    const textbox = ((readonly === true) ? <input className="form-control" type={type} id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange} value={value} readOnly/> : <input className="form-control" type={type} id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange} value={value}/>);
    return(
        <div className="form-floating mb-3">
           
            {textbox}
            <label htmlFor={"id-"+name}>{label}</label>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default InputBox;