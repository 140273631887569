import React, { Component } from 'react';
import WoyPopUp from '../../popup/woyPopUp';
import WoyImgCarousel from '../../imgcarousel/imgcarousel';
class InventoryPhotos extends Component {
    state = {  } 
    render() { 
        const images = this.props.inv.product.photos;
        //console.log(images);
        return (
        <div>
            <WoyPopUp handleClose={this.props.closePhotos}>
                <WoyImgCarousel mode="view" images={images}/>
            </WoyPopUp>
        </div>
        );
    }
}
 
export default InventoryPhotos;