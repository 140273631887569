import React, { Component } from 'react';
class ShippingAffiliates extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>APC Shipping Affiliates Page</h1>
            </div>
        );
    }
}
 
export default ShippingAffiliates;