import React from 'react';
import '../../containers/dashboard/dashboard.css';

const TextBox = ({label, name, placeholder, error, onchange, value}) =>{
    return(
        <div className="form-floating mb-3">
           
            <textarea className="form-control apc-form-textbox" id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange} value={value}/>
            <label htmlFor={"id-"+name}>{label}</label>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default TextBox;