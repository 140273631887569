import React, { Component } from 'react';
import {formatMoneyWOCurrency, formatDate} from '../../../utils/woyutils';
import './inventorydetails.css';
import SpecsTable from './specstable';
import InventoryPhotos from './invproductphotos';

const dispatched = "DISPATCHED";
const received = "RECEIVED";

class InventoryDetails extends Component {
    state = {
        viewspec: false,
        viewphotos: false
    }; 
    
    setInvoiceLink = (invoiceid) => {
        const invoiceurl = process.env.REACT_APP_BACKEND_URL + "/public/uploads/invoices/" + invoiceid + ".pdf";
        if (invoiceid !== null) {
            return(
              <a className="invoicelink" target="_newtab" href={invoiceurl}>Click this link to open invoice </a>  
            );
        } else {
            return ("----");
        }
    }

    setBrochureButton = (inv) =>{
        return(
            <a href={process.env.REACT_APP_BACKEND_URL + "/public/uploads/brochures/"+inv.product._id+".pdf"} target="new" >
                <button className="btn btn-success inv-detail-topbutton" value={inv.product._id} type="button">
                    Brochure
                </button>
            </a>
        );
    }

    setCloseButton = () =>{
        return(
            <button className="btn btn-danger inv-detail-topbutton" value="close" type="button" onClick={this.props.closeForm}>
                Close
            </button>
        );
    }

    setReceivingDetails = (inv) => {
        //const rec = {...this.state.receiving};
        if (inv.status !== received && inv.status !== dispatched) {
            return(
            <div className='inv-detail-stockheadercell'>
                <div className='inv-detail-stocksubheaderfont'>-- No Receiving Data --</div>
            </div>
            )
        } else {
            //console.log("not empty");
            const rec = inv.receive;
            return(
            <div className='inv-details-subgrid'>
                <div className='inv-detail-stockheadercell inv-detail-recHeaderBG'>
                    <div className='inv-detail-stocksubheaderfont'>RECEIVING DATA</div>
                </div>
                <div className='inv-detail-sd-cell'>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>DATE RECEIVED</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{formatDate(rec.receivingDate)}</div>
                    </div>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>SUPPLIER</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{rec.supplier}</div>
                    </div>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>QUANTITY</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{rec.qty}</div>
                    </div>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>COST</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{formatMoneyWOCurrency(rec.totalCost)}</div>
                    </div>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>SUPPLIER INVOICE</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{rec.invoice}</div>
                    </div>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>PURCHASE ORDER NO</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{rec.purchaseOrder}</div>
                    </div>
                    <div className='inv-details-sd-grid'>
                        <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>RECEIVED BY</div>
                        <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{rec.receivedBy}</div>
                    </div>
                </div>
            </div>
            );
        }
    };

    setDespatchingDetails = (inv) => {
        
        if (inv.status !== dispatched) {
            return(
            <div className='inv-detail-stockheadercell inv-detail-dispHeaderBG'>
                <div className='inv-detail-stocksubheaderfont'>-- No Dispatching Data --</div>
            </div>
            )
        } else {
            if (!inv.dispatch) {
                return(
                <div className='inv-detail-stockheadercell inv-detail-dispHeaderBG'>
                    <div className='inv-detail-stocksubheaderfont'>-- No Dispatching Data --</div>
                </div>
                );
            }
            else {
                const disp = inv.dispatch;
                let invoice = "MANUAL INVOICE ISSUED";
                if (disp.invoiceid !== "----")
                    invoice = this.setInvoiceLink(disp.invoice);
                return(
                <div className='inv-details-subgrid'>
                    <div className='inv-detail-stockheadercell inv-detail-dispHeaderBG'>
                        <div className='inv-detail-stocksubheaderfont'>DISPATCHING DATA</div>
                    </div>
                    <div className='inv-detail-sd-cell'>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>DATE DISPATCHED</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{formatDate(disp.dispatchDate)}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>QUANTITY</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{disp.qty}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>INVOICE</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{invoice}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>Details</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{disp.description}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>CUSTOMER</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{disp.receivername}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>CONTACT NO</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{disp.receivercontactno}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>ADDRESS</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{disp.receiveraddress}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>DISPATCHED BY</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{disp.dispatchby}</div>
                        </div>
                    </div>
                </div>
                );
            };    //console.log("not empty");
            
        }
    };

    setStockDetails = (inv) => {
        let cost = inv.cost;
        if (inv.receive.totalCost)
            cost = inv.receive.totalCost;
        return(
            <div className='inv-details-maingrid'>
                {this.state.viewphotos===true && <InventoryPhotos inv={inv} closePhotos={()=>{this.setState({viewphotos:false})}}/>}
                <div className='inv-detail-stockheadercell'>
                    <div className='inv-detail-headerCell2Grid'>
                        <div className='inv-detail-stockheaderfont'>{inv.product.name}</div>
                        <div className='inv-details-sd-grid'>
                            <div>{this.setBrochureButton(inv)}</div>
                            <div>{this.setCloseButton()}</div>
                        </div>
                    </div>
                </div>
                <div className='inv-details-sd-grid'>
                    <div className='inv-detail-sd-cell'>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>STATUS</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{inv.status}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>QTY</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{inv.qty}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>COST</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{formatMoneyWOCurrency(cost)}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>MAKE</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{inv.product.make}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>MODEL / YEAR</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{inv.product.model} / {inv.product.modelyear}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>SERIAL NO</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{inv.product.serialno}</div>
                        </div>
                        <div className='inv-details-sd-grid'>
                            <div className='inv-detail-StockLabelCell inv-detail-stockdetailfont'>HOURS</div>
                            <div className='inv-detail-StockValueCell inv-detail-stockdetailfont'>{inv.product.operatinghours}</div>
                        </div>
                    </div>
                    <div>
                        <img className='inv-detail-photo' src={process.env.REACT_APP_BACKEND_URL +'/'+ inv.product.profilephoto} alt="Stock Photo" onClick={()=>{this.setState({viewphotos:true})}}/>
                    </div>
                    
                </div>
            </div>
        );
    }

    setSpecifications = (inv) =>  {
        return(
            <div className='inv-details-subgrid'>
                <div className='inv-detail-stockheadercell inv-detail-specHeaderBG'>
                    <div className='inv-detail-stocksubheaderfont'><button className='inv-detail-VewSpecButton' onClick={()=>{this.setState({viewspec:false})}}>CLOSE SPECIFICATIONS</button></div>
                </div>
                <div className='inv-detail-sd-cell'>
                    <SpecsTable inv={inv}/>
                </div>
            </div>
        );
    }

    setSpecButton = () =>  {
        return(
            <div className='inv-details-subgrid'>
                <div className='inv-detail-stockheadercell inv-detail-specHeaderBG'>
                    <div className='inv-detail-stocksubheaderfont'><button className='inv-detail-VewSpecButton' onClick={()=>{this.setState({viewspec:true})}}>VIEW SPECIFICATIONS </button></div>
                </div>
            </div>
        );
    }

    render() { 
        return (
        <div className="inv-details-container">
         {this.setStockDetails(this.props.inv)}     
            
         {(this.state.viewspec === true) && this.setSpecifications(this.props.inv)}
         {(this.state.viewspec === false) && this.setSpecButton()}
        
         {this.setReceivingDetails(this.props.inv)}
         {this.setDespatchingDetails(this.props.inv)}
        </div>
        );
    }
}
 
export default InventoryDetails;