import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import './woydbtable.css';
import WoyTags from '../input/woytags/woytags';

class DBTableBrowse extends Component {
    state = {  } 

    setDisplayCell = (item,colname) => {
        if (this.props.tagsColumns){
            if (this.props.tagsColumns.includes(colname))
             return <WoyTags editMode={false} tags={item[colname]}/>   
        }

        if (this.props.linkColumns) {
            if (this.props.linkColumns.includes(colname))
                return <a className="apc-link" href={item[colname]} target="new_" exact>{item[colname]}</a>
        }

        if (item[colname]===true)
            return <input type="checkbox" readOnly checked />
        else if (item[colname]===false)
            return <input type="checkbox" disabled/>
        else
            return item[colname];    
    }

    render() {
        const { data, display, sortBy, sortData} = this.props; 
        //console.log(data);
        return (
            <div>
                <table className="apc-table-container">
                    <thead>
                        <tr>
                        {
                            display.map((col,index) =>
                                <th className={sortBy === col.value ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} key={"id-"+index} onClick={()=>{sortData(col.value)}}>{col.label}</th>
                            )
                        }
                        <th className='apc-woytable-row-header'>Action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    data && data.map((user,index) => 
                        <tr className='apc-woytable-row' key={"id-"+index}>
                            {
                                display.map((col,colindex) =>
                                    <td className='apc-woytable-col' key={"id-"+colindex}>{this.setDisplayCell(user,col.value)}</td>
                                )
                            }
                            <td className='apc-woytable-col apc-woytable-col-actions' key={"id-"+user._id+"-actions-"+index}>
                                <div>
                                    <button value={user._id} type="button" onClick={() => {this.props.selectMethod(user)}} className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faCircleCheck} /></button>
                                </div>
                            </td>
                        </tr>
                    )    
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}
 
export default DBTableBrowse;