import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faEdit, faTrashCan, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate, sortbyColumn} from '../../../utils/paginate';
import ReceivingForm from '../../../components/forms/receivingform';
import WoyReceivingSearch from '../../../components/woycrudsearch/WoyReceivingSearch';

const editViewDelete = ["update","view","remove"];

class ReceivingStock extends Component {
    state = { 
        recs : [],
        filteredRecs:[],
        selectedRec:{},
        pageSize:25,
        currentPage:1,
        activeColumn:"",
        recType:"STOCK",
        filterDateFrom: new Date(),
        filterDateTo: new Date(),
        showReceivingForm: false,
        dialogMode: "add",
        recReportFile: ""
    } 

    async componentDidMount() {
        
        const date = new Date();
        const firstDayCurrentMonth = this.getFirstDayOfMonth(date);
        const lastDayCurrentMonth = this.getLastDayOfMonth(date);

        this.setState({filterDateFrom: firstDayCurrentMonth, filterDateTo: lastDayCurrentMonth});

        //let url = (process.env.REACT_APP_BACKEND_URL + '/api/receiving/byType/STOCK'); 
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/receiving/recSearch/STOCK/'+this.formatMongoDate(firstDayCurrentMonth)+'/'+this.formatMongoDate(lastDayCurrentMonth));
        
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        //console.log(config);
        try {
            const {data:rec} = await Axios(config);
            //console.log(rec);
            if (Object.keys(rec).length !== 0) {
                this.setState({recs:rec,filteredRecs:rec});
            }
            //console.log(rec);
            
        } catch (error) {
            console.log(error);
        }    
    };

    async createReceivingPDF(from,to,search) {
        //process.env.REACT_APP_BACKEND_URL + '/api/receiving/recReport/'+this.formatMongoDate(this.state.filterDateFrom)+'/'+this.formatMongoDate(this.state.filterDateTo)
        let url = process.env.REACT_APP_BACKEND_URL + '/api/receiving/recReport/'+this.formatMongoDate(from)+'/'+this.formatMongoDate(to);
        
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        //console.log(config);
        try {
            const {data:receivingReport} = await Axios(config);

            console.log(receivingReport);
            if (Object.keys(receivingReport).length !== 0) {
                this.setState({recReportFile: receivingReport.filename});
            }
            //console.log(rec);
            console.log(this.state.recReportFile);
            
        } catch (error) {
            console.log(error);
        }    
    } 

    formatMoney = (n) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
          return formatter.format(n);
    }

    handleReceivingQuery = async (from,to,desc) => {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/receiving/recSearch/STOCK/'+this.formatMongoDate(from)+'/'+this.formatMongoDate(to)+'/'+desc);
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        //console.log(config);
        try {
            const {data:rec} = await Axios(config);
            //console.log(rec);
            if (Object.keys(rec).length !== 0) {
                this.setState({recs:rec,filteredRecs:rec});
            }
            //console.log(rec);
            
        } catch (error) {
            console.log(error);
        }  
    }

    getFirstDayOfMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay;
    }

    getLastDayOfMonth = (date) => {
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return lastDay;
    }

    formattedDate = (date) =>{
        //console.log(date);
        let newdate = new Date(date);
        return(
            format(newdate, 'dd-MMM-yyyy')
        );
    }

    formatQueryDate = (date) => {
        let newdate = new Date(date);
        return(
            format(newdate, 'dd-MM-yyyy')
        );
    }

    formatMongoDate = (date) => {
        let newdate = new Date(date);
        return(
            format(newdate, 'yyyy-MM-dd')
        );
    }

    handlePageChange = (page) =>{
        this.setState({currentPage:page});
    }

    handleSort = (colName) => {
        const sortedRecs = sortbyColumn(this.state.filteredRecs,colName);
        this.setState({filteredRecs:sortedRecs,activeColumn:colName});
    }

    addRecClick = () => {
        this.setState({dialogMode:"add", showReceivingForm: true, formTitle:"Receive new STOCK"});
    }

    editRecClick = (rec) => {
        this.setState({dialogMode:"update", showReceivingForm: true, formTitle:"Update - "+rec.inv.product.name, selectedRec:rec});
    }

    viewRecClick = (rec) => {
        this.setState({dialogMode:"view", showReceivingForm: true, formTitle:"View - "+rec.inv.product.name, selectedRec:rec});
    }

    removeRecClick = (rec) => {
        this.setState({dialogMode:"remove", showReceivingForm: true, formTitle:"Are you sure you want to delete this?", selectedRec:rec});
    }

    closeReceivingForm = () =>{
        this.setState({showReceivingForm: false, dialogMode:"add"});
    }

    removeFromRecs = (id) => {
        let newRecs = this.state.recs.slice();
        let newRecs2 = this.state.filteredRecs.slice();
        newRecs = newRecs.filter((rec) => rec._id !== id);
        newRecs2 = newRecs2.filter((rec) => rec._id !== id);
        this.setState({recs:newRecs,filteredRecs:newRecs2});
    }

    editFromRecs = (editedRec) => {
        let editedRecs = this.state.recs.slice();
        let editedRecs2 = this.state.filteredRecs.slice();
        editedRecs = editedRecs.map(rec => rec._id !== editedRec._id ? rec : editedRec);
        editedRecs2 = editedRecs2.map(rec => rec._id !== editedRec._id ? rec : editedRec);
        this.setState({recs:editedRecs,filteredRecs:editedRecs2,selectedRec:editedRec})  
    }

    addToRecs = (newrec) => {
        let newrecs = this.state.recs.slice();
        let newrecs2 = this.state.filteredRecs.slice();
        newrecs.push(newrec);
        newrecs2.push(newrec);
        this.setState({recs:newrecs,filteredRecs:newrecs2})  
    }

    setDateFrom = (dateFrom) => {
        this.setState({filterDateFrom:dateFrom});
    }

    setDateTo = (dateTo) => {
        this.setState({filterDateTo:dateTo});
    }

    handleSearch = (startDate, endDate, colName, searchValue) => {
        //console.log(startDate + " - " + endDate);
        //const $regex = escapeStringRegexp(searchValue);
        const {recs} = this.state;
        let newRecs = [];
        
        const start =  startDate.getTime();
        let end = new Date(endDate);
       /*  end.setHours(23,59,59,999);
        end.getTime();
 */
        newRecs = recs.filter(x => {
            let date = new Date(x.receivingDate).getTime();
            return date >= start && date <= end;
         });

        if (colName==="inv.product.name") {
            newRecs = newRecs.filter( x => 
                x.inv.product.name.includes(searchValue) 
                );
        } 
        else if (colName==="inv.supplier.name") {
            newRecs = newRecs.filter( x => 
                x.inv.supplier.name.includes(searchValue) 
                );
        } 
        else if (colName==="inv.product.description") {
            newRecs = newRecs.filter( x => 
                x.inv.product.description.includes(searchValue) 
                );
        };
                
         this.setState({filteredRecs:newRecs})   
     }

    
    render() { 
        const displayRecs = paginate(this.state.filteredRecs,this.state.currentPage,this.state.pageSize);
        /* const searchOptions = [
            {label:"Product", value:"inv.product.name"},
            {label:"Supplier", value:"inv.supplier.name"},
            {label:"Description", value:"inv.product.description"}
        ]; */

        return (
        <div>
             <div className='apc-maintenance-title-container'>
                <div className="mt-3">
                    <h2>Stocks Receiving</h2>
                </div>
                <div>
                    <WoyReceivingSearch defaultOption="inv.product.name" searchClick={this.handleReceivingQuery} dateFrom={this.state.filterDateFrom} dateTo={this.state.filterDateTo} setFilterDateFrom={this.setDateFrom} setFilterDateTo={this.setDateTo}/>
                </div>
                <div className="row align-items-start">
                    <div className="col mt-3 pl-2">
                        {/* {console.log("Date From: "+this.formatMongoDate(this.state.filterDateFrom))}
                        {console.log("Date To: "+this.formatMongoDate(this.state.filterDateTo))}
                        {console.log(process.env.REACT_APP_BACKEND_URL + '/api/receiving/recReport/'+this.formatMongoDate(this.state.filterDateFrom)+'/'+this.formatMongoDate(this.state.filterDateTo))} 
                        <a href={process.env.REACT_APP_BACKEND_URL + '/api/receiving/recReport/'+this.formatMongoDate(this.state.filterDateFrom)+'/'+this.formatMongoDate(this.state.filterDateTo)} target="_new"><button type="button" className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faFilePdf} /></button></a>*/}
                        <button type="button" className="btn btn-success apc-maintenance-addbutton" onClick={()=>this.createReceivingPDF(this.state.filterDateFrom,this.state.filterDateTo)}><FontAwesomeIcon icon={faFilePdf} /></button>
                    </div>
                    {this.state.recReportFile !== "" && <div className="col mt-3 pl-2">
                         <a href={process.env.REACT_APP_BACKEND_URL + `/${this.state.recReportFile}`} target="_recReportNew"><button type="button" className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faEye} /></button></a>
                    </div>}
                    <div className="col mt-3 pr-2">
                        <button type="button" onClick={this.addRecClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                    </div>
                </div>
            </div>
            <div className='apc-form-recpanel'>
                {(this.state.showReceivingForm === true) && (this.state.dialogMode==="add") && <ReceivingForm rec={this.state.selectedRec} title={this.state.formTitle} mode={this.state.dialogMode} user={this.props.user} insertRec={this.addToRecs} updateRec={this.editFromRecs} removeRec={this.removeFromRecs} closeForm={this.closeReceivingForm}/>}    
            </div>
            <div>
                <table className="apc-table-container">
                <thead>
                    <tr>
                        <th className={this.state.activeColumn === "receivingDate" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("receivingDate")}}>Date Received</th>
                        <th className={this.state.activeColumn === "inv.product.name" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("inv.product.name")}}>Product</th>
                        <th className={this.state.activeColumn === "inv.product.serialno" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("inv.product.serialno")}}>Serial No</th>
                        <th className={this.state.activeColumn === "inv.product.operatinghours" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("inv.product.operatinghours")}}>Hours</th>
                        <th className={this.state.activeColumn === "supplier.name" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("supplier.name")}}>Supplier</th>
                        <th className={this.state.activeColumn === "purchaseOrder" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("purchaseOrder")}}>PO</th>
                        <th className={this.state.activeColumn === "invoice" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("invoice")}}>Invoice</th>
                        {/* <th className={this.state.activeColumn === "description" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("description")}}>Details</th> */}
                        {/* <th className={this.state.activeColumn === "qty" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("qty")}}>Qty</th> */}
                        <th className={this.state.activeColumn === "cost" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("cost")}}>Cost</th>
                        {/* <th className={this.state.activeColumn === "totalCost" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("totalCost")}}>Total</th> */}
                        <th className='apc-woytable-row-header'>Action(s)</th>
                    </tr>
                </thead>
                 <tbody>
                {
                    displayRecs.map((rec,index) =>
                    <React.Fragment> 
                    <tr className='apc-woytable-row' key={"id-"+index}>
                       <td className='apc-woytable-col'>{this.formattedDate(rec.receivingDate)}</td>
                       <td className='apc-woytable-col'>{rec.inv.product.name}</td>
                       <td className='apc-woytable-col'>{rec.inv.product.serialno}</td>
                       <td className='apc-woytable-col'>{rec.inv.product.operatinghours}</td>
                       <td className='apc-woytable-col'>{rec.supplier.name}</td>
                       <td className='apc-woytable-col'>{rec.purchaseOrder}</td>
                       <td className='apc-woytable-col'>{rec.invoice}</td>
                       {/* <td className='apc-woytable-col'>{rec.description}</td> */}
                       {/* <td className='apc-woytable-col'>{rec.qty}</td> */}
                       <td className='apc-woytable-col'>{ this.formatMoney(rec.cost)}</td>
                       {/* <td className='apc-woytable-col'>{rec.totalCost}</td> */}
                       <td className='apc-woytable-col'>
                            <div className="apc-actionbuttongrid">
                                <div>
                                {
                                    (this.props.user.isAdmin) && <button value={rec._id} type="button" onClick={() => {this.removeRecClick(rec)}} className="btn btn-danger apc-actionbutton"><FontAwesomeIcon icon={faTrashCan} /></button>
                                }
                                </div>
                                <div>
                                    <button value={rec._id} type="button" onClick={() => {this.editRecClick(rec)}} className="btn btn-warning apc-actionbutton"><FontAwesomeIcon icon={faEdit} /></button>
                                </div> 
                                <div>
                                    <button value={rec._id} type="button" onClick={() => {this.viewRecClick(rec)}} className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faEye} /></button>
                                </div> 
                            </div>
                       </td>
                    </tr>
                    {(editViewDelete.includes(this.state.dialogMode))&&(this.state.showReceivingForm) && (this.state.selectedRec._id === rec._id) && <tr><td colSpan={10} className="apc-table-editRow"><div className='apc-table-editRow-Content'><ReceivingForm rec={this.state.selectedRec} title={this.state.formTitle} mode={this.state.dialogMode} user={this.props.user} insertRec={this.addToRecs} updateRec={this.editFromRecs} removeRec={this.removeFromRecs} closeForm={this.closeReceivingForm}/></div></td></tr>}
                    </React.Fragment>
                    )    
                }
                </tbody>
                </table>
            </div>
            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayRecs.length} of {this.state.filteredRecs.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredRecs.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange}/>
            </div>
        </div>
        );
    }
}
 
export default ReceivingStock;