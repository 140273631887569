// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Work+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".apc-navbar {\r\n    background-color: #17181a;\r\n    width: 100%;\r\n    height: 100px;\r\n    z-index: 0;\r\n    display: grid;\r\n    grid-template-columns: 300px 30px auto;\r\n    color: #ffcb29;\r\n    font-family: \"Raleway\";\r\n    font-weight:500;\r\n}\r\n\r\n.apc-mainscreen {\r\n    background-color: #41475A;\r\n}\r\n\r\n.apc-contentscreen {\r\n    font-family: \"Space Mono\";\r\n    font-weight:\"Regular\";\r\n    background-color: #41475A;\r\n    width: 1870px;\r\n    min-height: 1000px;\r\n    height: auto;\r\n    margin: auto;\r\n    z-index: 0;\r\n}", "",{"version":3,"sources":["webpack://./src/containers/main/main.css"],"names":[],"mappings":"AACA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,UAAU;IACV,aAAa;IACb,sCAAsC;IACtC,cAAc;IACd,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,UAAU;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Work+Sans&display=swap');\r\n.apc-navbar {\r\n    background-color: #17181a;\r\n    width: 100%;\r\n    height: 100px;\r\n    z-index: 0;\r\n    display: grid;\r\n    grid-template-columns: 300px 30px auto;\r\n    color: #ffcb29;\r\n    font-family: \"Raleway\";\r\n    font-weight:500;\r\n}\r\n\r\n.apc-mainscreen {\r\n    background-color: #41475A;\r\n}\r\n\r\n.apc-contentscreen {\r\n    font-family: \"Space Mono\";\r\n    font-weight:\"Regular\";\r\n    background-color: #41475A;\r\n    width: 1870px;\r\n    min-height: 1000px;\r\n    height: auto;\r\n    margin: auto;\r\n    z-index: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
