import React, { Component } from 'react';
import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import './Accordion.css';


class WoyAccordion extends Component {
    state = {
                dash:[],
                admindash:[],
                user:{}
            } 
    async componentDidMount() {
        try {
            const jwt = localStorage.getItem("token");
            const user = jwtDecode(jwt);
            this.setState({user});    
        } catch (error) {

        }  
        
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/dash'); 
        let adminurl = (process.env.REACT_APP_BACKEND_URL + '/api/dash/admin'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        var adminconfig = {
            method: 'get',
            url: adminurl,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        try {
                //console.log(this.state.user);
                const {data:dash} = await Axios(config);
                this.setState({dash});
                if (this.state.user.isAdmin){
                    const {data:admindash} = await Axios(adminconfig);
                    this.setState({admindash});
                    //console.log(this.state.admindash);
                }
            } catch (error) {
                console.log(error);
        }
        
    }

    render() { 
        const { dash } = this.state;
        const { admindash } = this.state;
        //console.log(this.props.user);
        var username = this.state.user.name;
        if (username)
            username = username.charAt(0).toUpperCase() + username.slice(1);
        
        return (
            <div>
                <div className="apc-accordion-title" key="acc-title-nonadmin"><h4>{username + "'s"} Dashboard</h4></div>
                <div className="accordion accordion-flush" id="nonadmin-menu" key="key-nonadmin-menu">
                { dash.map((menuHeader,index) => 
                    <div className="accordion-item apc-accordion-header" key={index}>
                        <h2 className="accordion-header" id={"flush-heading-" + menuHeader._id}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse-" + menuHeader._id} aria-expanded="false" aria-controls={"flush-collapse-" + menuHeader._id} key={"button" + index}>
                            {!(menuHeader.label === "My Account") && menuHeader.label}
                            {(menuHeader.label === "My Account") && username+"'s Account"}   
                        </button>
                        </h2>
                        <div id={"flush-collapse-" + menuHeader._id} className="accordion-collapse collapse" aria-labelledby={"#flush-heading-" + menuHeader._id} data-bs-parent="#nonadmin-menu" key={"key-flush-collapse-" + index}>
                        <div className="accordion-body" key={"acc-body-key-"+index}>
                            <div className="d-grid gap-0" key={"acc-buttons-container-"+index}>
                                {menuHeader.menuItems.map((item,itemindex) =>
                                <div className="apc-accordion-div" key={itemindex}>
                                    {item.accessedBy.includes(this.state.user.userRole) &&  <button value={item.url} type="button" onClick={this.props.onClick} className="btn text-left apc-accordion-button" key={itemindex}>{item.label}</button>}
                                </div>)} 
                            </div>
                        </div>
                        </div>
                    </div>
                )}
                </div>
                {
                    (admindash.length>0) && <React.Fragment>
                        <div className="apc-accordion-title" key="acc-title-admin"><h3>Administrator</h3></div>
                        <div className="accordion accordion-flush" id="admin-menu" key="admin-menu">
                        { admindash.map((menuHeader,index) => 
                            <div className="accordion-item apc-accordion-header" key={index}>
                                <h2 className="accordion-header" id={"flush-heading-" + menuHeader._id}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse-" + menuHeader._id} aria-expanded="false" aria-controls={"flush-collapse-" + menuHeader._id} key={"button" + index}>
                                    {menuHeader.label}
                                </button>
                                </h2>
                                <div id={"flush-collapse-" + menuHeader._id} className="accordion-collapse collapse" aria-labelledby={"#flush-heading-" + menuHeader._id} data-bs-parent="#admin-menu" key={"key-flush-collapse-" + index}>
                                <div className="accordion-body" key={"acc-body-"+index}>
                                    <div className="d-grid gap-0" key={"acc-buttons-container-"+index}>
                                        {menuHeader.menuItems.map((item,itemindex) =>
                                       <div className="apc-accordion-div" key={itemindex}>
                                            {item.accessedBy.includes(this.state.user.userRole) && <button value={item.url} type="button" onClick={this.props.onClick} className="btn apc-accordion-button" key={itemindex}>{item.label}</button>}
                                        </div>)} 
                                    </div>
                                </div>
                                </div>
                            </div>
                        )}
                        </div>
                    </React.Fragment>    
                }
            </div>
        );
    }
}
 
export default WoyAccordion;