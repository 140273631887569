import {React, Component} from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

class WoyInvSearch extends Component {
    state = {
    }

    render() {
        const {statOptions, options, handleStatOptionChange, handleColNameChange, handleSearchTextChange} = this.props;
        return (
            <div className="input-group mb-3 apc-maintenance-search">
                <select className="custom-select" id="inputGroupSelect02" onChange={handleStatOptionChange}>
                {statOptions.map((statoption,index) => 
                        <option key={index} value={statoption.value}>{statoption.label}</option>
                )}
                </select>

                <select className="custom-select" id="inputGroupSelect01" onChange={handleColNameChange}>
                {options.map((option,index2) => 
                        <option key={index2} value={option.value}>{option.label}</option>
                )}
                </select>
                
                <input type="text" className="form-control" placeholder="Enter Text to Search" aria-label="Enter Text to Search" onChange={handleSearchTextChange}/>
               {/*  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" onClick={()=>{searchClick(this.state.searchColName,this.state.searchText)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                </div> */}
            </div>
        );
    }
}

export default WoyInvSearch;