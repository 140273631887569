import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../dialogs/woydialog.css';
import WoyCRUDSearch from '../woycrudsearch/WoyCRUDSearch';
import WoyTags from '../input/woytags/woytags';
import WoyPagination from '../pagination/pagination';
import {paginate} from '../../utils/paginate';


class InventoryBrowser extends Component {
    state = { 
        invs:[],
        filteredInvs:[],
        selectedInv:{},
        showModal:false,
        dialogMode:"",
        formTitle:"",
        pageSize:20,
        currentPage:1,
        activeColumn:""
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/byType/'+ this.props.type); 
        /* let data = JSON.stringify({
            type:this.props.type
        }); */

        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }
        };
        
        try {
            const {data:inv} = await Axios(config);
            this.setState({invs:inv,filteredInvs:inv});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
       // console.log(colName + " - " + searchValue);
        //const $regex = escapeStringRegexp(searchValue);
        const {invs} = this.state;
        let newInvs = [];
        if (colName==="category"){
            newInvs = invs.filter( x => 
                x.product.category.includes(searchValue) 
              );    
        } else if (colName==="type"){
            newInvs = invs.filter( x => 
                x.type.includes(searchValue) 
              );
        } else if (colName==="name"){
            newInvs = invs.filter( x => 
                x.product.name.includes(searchValue) 
              );
        }

        this.setState({filteredInvs:newInvs})   
    }

    openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false});
    }

    
    selectInvClick = (selectedInv) => {
        this.setState({selectedInv: selectedInv});
        this.props.setInv(selectedInv);
        //this.closeProductBrowser();
    }

    handlePageChange = (page) =>{
        //console.log(page);
        this.setState({currentPage:page});
        //this.setState({displaySuppliers:display});
    }

    handleSort = (colName) => {
        const sortedInvs = this.sortInvByColumn(this.state.filteredInvs,colName);
        this.setState({filteredInvs:sortedInvs,activeColumn:colName});
    }

    sortInvByColumn = (inv,colName) => {
        if (colName==="name") 
            return inv.sort((a, b) => (a.product.name > b.product.name) ? 1 : -1)
        else if (colName==="category") 
            return inv.sort((a, b) => (a.product.category > b.product.category) ? 1 : -1)
        else if (colName==="serialno") 
            return inv.sort((a, b) => (a.product.serialno > b.product.serialno) ? 1 : -1)
        else if (colName==="type") 
            return inv.sort((a, b) => (a.type > b.type) ? 1 : -1)
        else if (colName==="qty") 
            return inv.sort((a, b) => (a.qty > b.qty) ? 1 : -1)
        else if (colName==="cost") 
            return inv.sort((a, b) => (a.cost > b.cost) ? 1 : -1);
    }

    setDisplayCell = (inv,colname) => {
        if (colname==="category")
             return <WoyTags editMode={false} tags={inv.product.category}/>
        else if (colname==="name")
            return inv.product.name  
        else if (colname==="serialno")
            return inv.product.serialno  
        else
            return inv[colname];    
    }
    
    render() { 
        let display = [
            {
                label:"Qty",
                value:"qty"
            },
            {
                label:"Category",
                value:"category",
            },
            {
                label:"Name",
                value:"name",
            },
            {
                label:"Serial No",
                value:"serialno",
            },
            {
                label:"Type",
                value:"type"
            },
            
            {
                label:"Cost",
                value:"cost"
            }
        ];

        const displayInv = paginate(this.state.filteredInvs,this.state.currentPage,this.state.pageSize);

        const searchOptions = [
            {label:"Product Name", value:"name"},
            {label:"Product Category", value:"category"}
        ];

        return (
            <div className="apc-maintenance-container">
            
            <div className='apc-maintenance-title-container'>
                <div>
                    <h2>Manage Inventory</h2>
                </div>
                <div>
                    <WoyCRUDSearch options={searchOptions} defaultOption="name" searchClick={this.handleSearch}/>
                </div>
                <div className='apc-maintenance-addbutton-container'>
                    {/* <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                    <span>&nbsp;</span> 
                    <button type="button" onClick={this.addInvClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                    */}
                </div>
            </div>
          
            <div>
                <table className="apc-table-container">
                    <thead>
                        <tr>
                            {
                                display.map((col,colindex) =>
                                    <th className={this.state.activeColumn === col.value ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} key={colindex} onClick={()=>{this.handleSort(col.value)}}>{col.label}</th>
                                )  
                            }
                        <th className='apc-woytable-row-header'>Action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    displayInv && displayInv.map((inv,index) => 
                        <tr className='apc-woytable-row' key={"id-"+index}>
                            {
                                display.map((col,colindex) =>
                                    <td className='apc-woytable-col' key={"id-"+colindex}>{this.setDisplayCell(inv,col.value)}</td>
                                )
                            }
                            <td className='apc-woytable-col apc-woytable-col-actions' key={"id-"+inv._id+"-actions-"+index}>
                                <div className="apc-actionbuttongrid">
                                     
                                    <div>
                                         <button value={inv._id} type="button" onClick={() => {this.selectInvClick(inv)}} className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faCheckCircle} /></button>
                                    </div> 
                                </div>
                            </td>
                        </tr>
                    )    
                }
                    </tbody>
                </table>
            </div> 

            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayInv.length} of {this.state.filteredInvs.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredInvs.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange}/>
            </div>
        </div>
        );
    }
}
 
export default InventoryBrowser;