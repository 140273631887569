import React, { Component } from 'react';
import Axios from 'axios';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate} from '../../../utils/paginate';
import WoyCRUDSearch from '../../../components/woycrudsearch/WoyCRUDSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck} from '@fortawesome/free-solid-svg-icons';
import DispatchForm from '../../../components/forms/dispatchform';
import WoyTags from '../../../components/input/woytags/woytags';

const editView = ["update","view"];

class StockDispatching extends Component {
    state = {
        invs:[],
        filteredInvs:[],
        selectedInv:{},
        selectedInvoice:{},
        showModal:false,
        dialogMode:"",
        formTitle:"",
        pageSize:20,
        currentPage:1,
        activeColumn:""
    } 


    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/STOCK/InStock'); 

        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }
        };
        
        try {
            const {data:inv} = await Axios(config);
            this.setState({invs:inv,filteredInvs:inv});
        } catch (error) {
            console.log(error);
        }
    }
    
    refreshFilter = () => {
        const {invs} = this.state;
        let newInvs = [];
        invs.map((inv) => {
            
            if (inv.qty > 0){
                newInvs.push(inv);
            }
        });
        this.setState({filteredInvs:newInvs}); 
    }

    refreshFilter2 = async () => {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/STOCK/InStock'); 

        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }
        };
        
        try {
            const {data:inv} = await Axios(config);
            this.setState({invs:inv,filteredInvs:inv});
        } catch (error) {
            console.log(error);
        }
    }

    handlePageChange = (page) =>{
        //console.log(page);
        this.setState({currentPage:page});
        //this.setState({displaySuppliers:display});
    }
      

    handleSearch = (colName, searchValue) => {
         const {invs} = this.state;
         let newInvs = [];
         if (colName==="type"){
             newInvs = invs.filter( x => 
                 x.type.includes(searchValue) 
               );
         }
         else if (colName==="name"){
             newInvs = invs.filter( x => 
                 x.product.name.includes(searchValue) 
               );
         }
         else if (colName==="description"){
            newInvs = invs.filter( x => 
                x.product.description.includes(searchValue) 
              );
        }
        else if (colName==="serialno"){
            newInvs = invs.filter( x => 
                x.product.serialno.includes(searchValue) 
              );
        }
        else if (colName==="category"){
             newInvs = invs.filter( x => 
                 x.product.category.includes(searchValue) 
               );
        }   
        this.setState({filteredInvs:newInvs})   
     }

     setDispatch = (invoice,dispatch) => {
        //this.setState({selectedInvoice:invoice});
        let tempInventory = {...this.state.selectedInv};
        tempInventory.dispatch = dispatch;
        this.setState({selectedInv:tempInventory,selectedInvoice:invoice});
     }

     openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false});
    }

    setDisplayCell = (inv,colname) => {
        if (colname==="category")
             return <WoyTags editMode={false} tags={inv.product.category}/>
        else if (colname==="name")
            return inv.product.name  
        else if (colname==="serialno")
            return inv.product.serialno  
        else
            return inv[colname];    
    }

    replaceInventory = (col, edited) => {
        let tempInvs = col.slice();
        tempInvs.map((inv)=>{
            if (inv._id.toString() === edited._id.toString())
                inv = edited;    
        })
        return (tempInvs);
    }

    editFromInvs = (editedInv) => {
        //let editedInvs = this.state.invs.slice();
        //let editedInvs2 = this.state.filteredInvs.slice();
        //editedInvs = editedInvs.map(inv => inv._id !== editedInv._id ? inv : editedInv);
        //editedInvs2 = editedInvs2.map(inv => inv._id !== editedInv._id ? inv : editedInv);
        //console.log(editedInv);
        // let editedInvs = this.replaceInventory(this.state.invs,editedInv);
        // let editedInvs2 = this.replaceInventory(this.state.filteredInvs,editedInv);
        // this.setState({invs:editedInvs,filteredInvs:editedInvs2,selectedInv:editedInv});
        this.refreshFilter2();
    }

    editInvClick = (inv) => {
        this.setState({});
        this.openModalDialog();      
        this.setState({selectedInv:inv, dialogMode:"update", formTitle:"Dispatch " + inv.product.name});
    }

    render() { 
        let display = [
            {
                label:"Qty",
                value:"qty"
            },
            {
                label:"Category",
                value:"category",
            },
            {
                label:"Name",
                value:"name",
            },
            {
                label:"Serial No",
                value:"serialno",
            },
            {
                label:"Type",
                value:"type"
            },
            
            {
                label:"Cost",
                value:"cost"
            }
        ];

        const displayInv = paginate(this.state.filteredInvs,this.state.currentPage,this.state.pageSize);

        const searchOptions = [
            {label:"Product Name", value:"name"},
            {label:"Serial No", value:"serialno"},
            {label:"Description", value:"description"},
            {label:"Inventory Type", value:"type"},
            {label:"Product Category", value:"category"},
        ];

        return (
            <div className="apc-maintenance-container">
            <div className='apc-maintenance-title-container'>
                <div>
                    <h2>Stocks Dispatching</h2>
                </div>
                <div>
                    <WoyCRUDSearch options={searchOptions} defaultOption="name" searchClick={this.handleSearch}/>
                </div>
                <div className='apc-maintenance-addbutton-container'>
                    {/* <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                    <span>&nbsp;</span> 
                    <button type="button" onClick={this.addInvClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button> */}
                </div>
            </div>
          
            <div>
                <table className="apc-table-container">
                    <thead>
                        <tr>
                            {
                                display.map((col,colindex) =>
                                    <th className={this.state.activeColumn === col.value ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} key={colindex} onClick={()=>{this.handleSort(col.value)}}>{col.label}</th>
                                )  
                            }
                        <th className='apc-woytable-row-header'>Action(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    displayInv && displayInv.map((inv,index) =>
                        <React.Fragment>
                        <tr className='apc-woytable-row' key={"id-"+index}>
                            {
                                display.map((col,colindex) =>
                                    <td className='apc-woytable-col' key={"id-"+colindex}>{this.setDisplayCell(inv,col.value)}</td>
                                )
                            }
                            <td className='apc-woytable-col apc-woytable-col-actions' key={"id-"+inv._id+"-actions-"+index}>
                                <div className="apc-actionbuttongrid">
                                    {/* <div>
                                    {
                                        (this.props.user.isAdmin) && <button value={inv._id} type="button" onClick={() => {this.removeInvClick(inv)}} className="btn btn-danger apc-actionbutton"><FontAwesomeIcon icon={faTrashCan} /></button>
                                    }
                                    </div> */}
                                    <div>
                                         {(this.props.user.isAdmin) && (inv.status !=="DISPATCHED") && <button value={inv._id} type="button" onClick={() => {this.editInvClick(inv)}} className="btn btn-warning apc-actionbutton"><FontAwesomeIcon icon={faTruck} /></button>}
                                         {(inv.status ==="DISPATCHED") && <span>DISPATCHED</span>}
                                    </div> 
                                    {/* <div>
                                         <button value={inv._id} type="button" onClick={() => {this.viewInvClick(inv)}} className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faEye} /></button>
                                    </div>  */}
                                </div>
                            </td>
                        </tr>
                        {(editView.includes(this.state.dialogMode))&&(this.state.showModal) && (this.state.selectedInv._id === inv._id) && <tr><td colSpan={7} className="apc-table-editRow"><div className='apc-table-editRow-Content'><DispatchForm mode={this.state.dialogMode} user={this.props.user} title={this.state.formTitle} inv={this.state.selectedInv} closeForm={this.closeModalDialog} setDispatch={this.setDispatch} updateInv={this.editFromInvs}/></div></td></tr>}
                        </React.Fragment>
                    )    
                }
                    </tbody>
                </table>
            </div> 

            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayInv.length} of {this.state.filteredInvs.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredInvs.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange}/>
            </div>
        </div>);
    }
}
 
export default StockDispatching;