import React, { Component } from 'react';
class Home extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>Welcome to Homepage</h1>
            </div>
        );
    }
}
 
export default Home;