import React, { Component } from 'react';
import Joi from 'joi-browser';
import Axios from 'axios';
import CustomersForm,{customerSchema} from './customersForm';

import WoyTags from '../input/woytags/woytags';
import InvoiceFormCashSale from './invoiceformtemplate/invoiceform-cashsale';
import InvoiceFormDepositCashSale from './invoiceformtemplate/invoiceform-deposit-cashsale';
import InvoiceFormPO from './invoiceformtemplate/invoiceform-po';
import InvoiceFormDepositPO from './invoiceformtemplate/invoiceform-deposit-po';
import {formatMoney} from '../../utils/woyutils'
//import InvoiceFormTemplateProject from './invoiceformtemplate/invoiceformtemplateproject';

const enumInvoiceStatus = ["ISSUED","PAID","CANCELLED"];
const enumInvoiceDetailType = ["SALE","HIRE","LABOUR","FREIGHT"];
const enumInvoiceType = ["CASH SALE","DEPOSIT - CASH SALE","PURCHASE ORDER", "DEPOSIT - PURCHASE ORDER"];
const accountsEmail = "invoice@admin.australianpower.net";

export const invoiceDetailSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    detailType:Joi.string()
    .valid(...enumInvoiceDetailType)
    .required(),
    productid:Joi.string()
    .max(100)
    .required(),
    description:Joi.array()
    .items(Joi.string())
    .required(),
    qty:Joi.number()
    .min(1)
    .required(),
    uom:Joi.string()
    .required(),
    amt:Joi.number()
    .required(),
    totalAmt:Joi.number()
    .required(),
    updated_at: Joi.date()
    .optional(),
    created_at: Joi.date()
    .optional(),
    __v: Joi.number()
    .optional()
});

export const invoiceSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    customer: customerSchema
    .required(),
    invoiceDate:Joi.date()
    .required(),
    invoiceType:Joi.string()
    .valid(...enumInvoiceType),
    invoiceMonthNo:Joi.number()
    .optional(),
    invoiceSeqNo:Joi.number()
    .optional(),
    invoiceNo:Joi.string()
    .max(20)
    .optional(),
    invoiceStatus:Joi.string()
    .valid(...enumInvoiceStatus)
    .required(),
    purchaseOrderNo:Joi.string()
    .max(50)
    .optional(),
    remittanceEvidence:Joi.string()
    .max(500)
    .optional(),
    invoiceDetails:Joi.array()
    .items(invoiceDetailSchema).required(),
    depositRequired:Joi.number()
    .optional(),
    remainingBalance:Joi.number()
    .optional(),
    subTotal:Joi.number()
    .min(0)
    .required(),
    gst:Joi.number()
    .optional(),
    total:Joi.number()
    .min(0)
    .required(),
    entryBy:Joi.string()
    .max(50)
    .required(),
    withProjectName: Joi.boolean(),
    projectName:Joi.string(),
    withGST: Joi.boolean()
    .required(),
    gstRate: Joi.number()
    .optional(),
    updated_at: Joi.date()
    .optional(),
    created_at: Joi.date()
    .optional(),
    __v: Joi.number()
    .optional()    
});

const updateRemove = ["update","remove","view"];
const updateInsert = ["update","add"];

class InvoiceForm2 extends Component {
    state = {
        
       invoice: {
            _id: "----",
            customer: {
              name: "----",
              address: "----",
              active: false,
              email: "----",
              phoneno: "----",
              contactperson: "----",
              website: "----",
              created_at:  new Date(),
              updated_at: new Date(),
              _id: "----",
              __v: 0
            },
            invoiceDate: new Date(),
            invoiceType: this.props.invoicemode,
            invoiceSeqNo: 1,
            invoiceNo: "",
            invoiceStatus: "ISSUED",
            purchaseOrderNo: "",
            remittanceEvidence: "----",
            invoiceDetails: [],
            remainingBalance: 0,
            subTotal: 0,
            total: 0,
            entryBy: this.props.user.name,
            withProjectName: false,
            projectName: "",
            withGST: true,
            gstRate: 10,
            gst: 0,
            created_at: new Date(),
            updated_at:new Date(),
            __v: 0
          },
        errors: {},
        productBrowser: false
    } 

    async componentDidMount() {

        if (updateRemove.includes(this.props.mode)) {
            this.setState({
                invoice: this.props.invoice,
                selectedCustomer:this.props.invoice.customer,
                invoiceDetails:this.props.invoice.invoiceDetails
            });
        } else if (this.props.mode==="add"){
            let url = (process.env.REACT_APP_BACKEND_URL + '/api/invoiceOptn/getLatestInvoice'); 
            let tempInv = this.state.invoice;
            if ((this.props.invoicemode === "CASH SALE") || (this.props.invoicemode === "DEPOSIT - CASH SALE")) {
                tempInv.purchaseOrderNo = "----";
                tempInv.projectName = "----";
            };
            this.setState({invoice:tempInv});
            var config = {
                method: 'get',
                url: url,
                headers: { 
                    'x-auth-token': localStorage.getItem("token")
                }};
            try {
                const {data:inv} = await Axios(config);
                
                if (Object.keys(inv).length !== 0) {
                    this.nextInvoiceNo2(inv[0]);
                };
                
            } catch (error) {
                console.log(error);
            }   
        }
    }

    /* nextInvoiceNo = () => {
        try {
            if ((this.state.lastInvoice !== null) && (Object.keys(this.state.lastInvoice).length !== 0)) {
                let lastInvoiceNo = this.state.lastInvoice.invoiceNo;  
                let first7 = lastInvoiceNo.substring(0,7);  
                let last3 = lastInvoiceNo.substring(lastInvoiceNo.length - 3);
                let invoiceNo = (parseInt(last3, 10) + 1);
                let nextLast3 = invoiceNo.toString();
                while (nextLast3.length < 3) {
                    nextLast3 = "0"+nextLast3;
                }
                //console.log(first7+nextLast3);
                let currInv = {...this.state.invoice};
                currInv.invoiceNo = first7+nextLast3;
                this.setState({invoice:currInv});
            }    
        } catch (error) {
            console.log(error);
        }
    } */

    nextInvoiceNo2 = (inv) => {
        try {
            if ((inv !== null) && (Object.keys(inv).length !== 0)) {
                
                let lastInvoiceNo = inv.invoiceNo;  
                let currInv = {...this.state.invoice};
                if ((lastInvoiceNo.split("-").length -1) !== 1) {
                    let presentDate = new Date(); 
                    let curYear = presentDate.getFullYear().toString();
                    let yearStr = curYear.substring(curYear.length - 2);
                    currInv.invoiceNo = "APC"+yearStr+"-0001";
                }
                else {
                    let first6 = lastInvoiceNo.substring(0,6);  
                    let last4 = lastInvoiceNo.substring(lastInvoiceNo.length - 4);
                    let invoiceNo = (parseInt(last4, 10) + 1);
                    let nextLast4 = invoiceNo.toString();
                    while (nextLast4.length < 4) {
                        nextLast4 = "0"+nextLast4;
                    }
                    currInv.invoiceNo = first6+nextLast4;
                    
                    if (this.props.invoicemode === "CASH SALE" || this.props.invoicemode === "DEPOSIT - CASH SALE") {
                        currInv.purchaseOrderNo = "----";
                        currInv.projectName = "----";
                    }
                }
                this.setState({invoice:currInv});
            }    
        } catch (error) {
            console.log(error);
        }
    }

    setCustomer = (cust) => {
        let tempinv = {...this.state.invoice};
        tempinv.customer = cust;
        this.setState({invoice:tempinv,selectedCustomer:cust});
    }

    setInvoiceDate = (recdate) => {
        let temprec = {...this.state.invoice};
        temprec.invoiceDate = recdate;
        this.setState({invoice:temprec});
    }

    customerView = () => {
        return(
            <React.Fragment>
                {(Object.keys(this.state.selectedCustomer).length !== 0) && <CustomersForm mode="view" title="Customer Details" customer={this.state.selectedCustomer}/>}
                {(Object.keys(this.state.selectedCustomer).length === 0) && <h2>No customer selected</h2>}
            </React.Fragment>
        );    
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currRec  = {...this.state.invoice};
        currRec[name] = value;

        this.setState({
            invoice: currRec
        });
    };

    /* formatMoney = (n) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          });
        return formatter.format(n);
    } */

    setDisplayCell = (inv,colname) => {
        if (colname==="description")
             return <WoyTags editMode={false} tags={inv.description}/>
        else if (colname==="amt" || colname==="totalAmt") {
            return formatMoney(inv[colname]);
        }
        else
            return inv[colname];  
    }

    setRemittance = (path) => {
        const tempInvoice = {...this.state.invoice};
        tempInvoice.remittanceEvidence=path;
        this.setState({invoice:tempInvoice});
    }

    addDetailClick = () => {
        this.setState({detailDialogMode:"add",openInvoiceDetailForm:true});
    }

    addToDetails = (newDetail) => {
        let newDetails = this.state.invoiceDetails.slice();
        newDetails.push(newDetail);
        this.setInvoiceDetails(newDetails);
    }

    selectInvClick = (selectedInv) => {
        this.setState({selectedInv: selectedInv, detailDialogMode:"add",addType:"SALE", productBrowser:false, showDetailForm:true, formTitle:"Add New Item"});
    }

    openProductBrowser = () => {
        this.setState({productBrowser:true});
    }

    openAddHire = () => {
        this.setState({detailDialogMode:"add",addType:"HIRE", productBrowser:false, showDetailForm:true, formTitle:"Add New Hire"}); 
    }

    openAddFreight = () => {
        this.setState({detailDialogMode:"add",addType:"FREIGHT", productBrowser:false, showDetailForm:true, formTitle:"Add New Freight"}); 
    }

    openAddLabour = () => {
        this.setState({detailDialogMode:"add",addType:"LABOUR", productBrowser:false, showDetailForm:true, formTitle:"Add New Labour"}); 
    }

    closeProductBrowser = () => {
        this.setState({productBrowser:false});
    }

    handleRemittance = (path) =>{
        const tempInvoice = {...this.state.invoice};
        tempInvoice.remittanceEvidence=path;
        this.setState({invoice:tempInvoice});
    }

    setInvoice = (inv) =>{
        this.setState({invoice:inv});
    }
    
      calcTotals = (inv) => {
        let tempGST = 0;
        let sumAmt = 0;
        let tempDetails = inv.invoiceDetails.slice();
        
        if (Array.isArray(tempDetails)) {
            sumAmt = tempDetails.reduce((accumulator, object) => {
                return accumulator + object.totalAmt;
              }, 0);
        
        } else {
            sumAmt = tempDetails.totalAmt;
        } 
        
        if (inv.withGST) {
            tempGST = sumAmt * (inv.gstRate/100);
        }
        
        inv.subTotal = sumAmt;
        inv.gst = tempGST;
        inv.total = sumAmt + tempGST; 
      };
    
      setInvoiceDetails = (index, detail) => {
        let inv = {...this.state.invoice};
        //console.log(detail);
        detail.totalAmt = detail.qty * detail.amt;
        inv.invoiceDetails[index] = detail;
        this.calcTotals(inv);
        this.setInvoice(inv);
      }
    
      setInvoiceDetailsDesc = (detailIndex,descIndex,desc) => {
        let inv = {...this.state.invoice};
        let invDetail = inv.invoiceDetails[detailIndex];
        invDetail.description[descIndex] = desc;
        inv.invoiceDetails[detailIndex] = invDetail;
        this.setInvoice(inv);
      }
    
      addBlankDescToDetails = (index) =>  {
        let desc = " ";
        let inv = {...this.state.invoice};
        let invDetail = inv.invoiceDetails[index];
        //console.log(invDetail);
        invDetail.description.push(desc);
        inv.invoiceDetails[index] = invDetail;
        this.setInvoice(inv);
      }
    
      removeDescFromDetails = (index,descindex) =>  {
        console.log(descindex);
        let inv = {...this.state.invoice};
        let invDetail = inv.invoiceDetails[index];
        //let descindex = invDetail.description.indexOf(descval);
        if (descindex > -1)
            invDetail.description.splice(descindex,1);
        //invDetail.description = descArray;
        console.log(invDetail.description);
        inv.invoiceDetails[index] = invDetail;
        this.setInvoice(inv);
      }
    
      removeDetailEntry = (index) => {
        let inv = {...this.state.invoice};
        if (index > -1)
            inv.invoiceDetails.splice(index,1);
        //inv.invoiceDetails = removedDetail;
        this.calcTotals(inv);
        this.setInvoice(inv);
      }

      setInvDate = (invdate) => {
        let inv = {...this.state.invoice};
        inv.invoiceDate = invdate;
        this.setInvoice(inv);
      }

      addNewDetail = (detail) => {
        let inv = {...this.state.invoice};
        inv.invoiceDetails.push(detail);
        this.calcTotals(inv);
        this.setInvoice(inv);
      }

    validate = () => {
        const {invoice} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(invoice, invoiceSchema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    }

    handleSendInvoice = async (e) => {
        e.preventDefault();
        const method = "post";
        const url = process.env.REACT_APP_BACKEND_URL + "/api/invoice/sendInvoice";
        if (this.props.mode === "remove" || this.props.mode === "update"){
            const {invoice} = this.state;
            let data = JSON.stringify({
               invoice : invoice
            });
            
            const config = {
                method: method,
                url: url,
                headers: { 
                    'x-auth-token': localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                },
                data : data
            };
            
            try {
                const response = await Axios(config);
                if (response) {
                    this.props.emailSending("Invoice Email Sent Successfuly");
                    //console.log("Invoice Email Sent Successfuly.");
                    //console.log(response);
                } 
                this.props.closeForm();
            } catch (error) {
                this.props.emailSending(error.message);
                //console.log(error.message);
                this.props.closeForm();
            }
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let url = "";
        let method = "";

        if (this.props.mode === "add") {
            console.log("you are on add mode");
            url = (process.env.REACT_APP_BACKEND_URL + '/api/invoice');
            method = 'post';
        }  else if (this.props.mode === "update") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/invoice/'+this.props.invoice._id);
            method = 'put';
        }  else if (this.props.mode === "remove") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/invoice/'+this.props.invoice._id);
            method = 'delete';
        }


        if (updateInsert.includes(this.props.mode)){
            const errors = this.validate();
            //console.log(errors);
            this.setState({errors: errors || {}});
            if (errors) {
                console.log(errors);
                return
            };

        } 

        const {invoice} = this.state;

        let data = JSON.stringify({
            customer: invoice.customer,
            invoiceDate: invoice.invoiceDate,
            invoiceType: invoice.invoiceType,
            invoiceMonthNo: invoice.invoiceMonthNo,
            invoiceSeqNo: invoice.invoiceSeqNo,
            invoiceNo: invoice.invoiceNo,
            invoiceStatus: invoice.invoiceStatus,
            purchaseOrderNo:invoice.purchaseOrderNo,
            remittanceEvidence: invoice.remittanceEvidence,
            invoiceDetails: invoice.invoiceDetails,
            depositRequired: invoice.depositRequired,
            remainingBalance: invoice.remainingBalance,
            subTotal: invoice.subTotal,
            gst: invoice.gst,
            total:invoice.total,   
            entryBy: invoice.entryBy,
            withProjectName: invoice.withProjectName,
            projectName: invoice.projectName,
            withGST: invoice.withGST,
            gstRate: invoice.gstRate
        });  
        var config = {
        method: method,
        url: url,
        headers: { 
            'x-auth-token': localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            const response = await Axios(config);
            if (response) {
                const updatedInv = response.data;
                if (this.props.mode === "add"){
                    this.props.insertInvoice(updatedInv);
                } else if (this.props.mode === "update"){
                    this.props.updateInvoice(updatedInv);
                } else if (this.props.mode === "remove"){
                    this.props.removeInvoice(updatedInv._id);
                }
                
            } 
            this.props.closeForm();
        } catch (error) {
            console.log(error.message);
            this.props.closeForm();
        }
    };
    
    render() { 
        return (
        <div>
            {(this.props.invoicemode==="CASH SALE") &&
                <InvoiceFormCashSale 
                invoice={this.state.invoice} 
                setInvDetails={this.setInvoiceDetails} 
                setInv={this.setInvoice} 
                setInvDetailsDesc={this.setInvoiceDetailsDesc}
                addBlankDescToDetails={this.addBlankDescToDetails}
                removeDescFromDetails={this.removeDescFromDetails}
                removeDetailEntry={this.removeDetailEntry}
                setCustomer={this.setCustomer}
                setInvDate={this.setInvDate}
                setRemittance={this.setRemittance}
                addNewDetail={this.addNewDetail}
                closeForm={this.props.closeForm}
                handleSubmit={this.handleSubmit}
                user={this.props.user}
                mode={this.props.mode}
                sendInvoice={this.handleSendInvoice}
                setSendingStatus={this.props.emailSending}
                /> }

            {(this.props.invoicemode==="DEPOSIT - CASH SALE") &&
                <InvoiceFormDepositCashSale 
                invoice={this.state.invoice} 
                setInvDetails={this.setInvoiceDetails} 
                setInv={this.setInvoice} 
                setInvDetailsDesc={this.setInvoiceDetailsDesc}
                addBlankDescToDetails={this.addBlankDescToDetails}
                removeDescFromDetails={this.removeDescFromDetails}
                removeDetailEntry={this.removeDetailEntry}
                setCustomer={this.setCustomer}
                setInvDate={this.setInvDate}
                setRemittance={this.setRemittance}
                addNewDetail={this.addNewDetail}
                closeForm={this.props.closeForm}
                handleSubmit={this.handleSubmit}
                user={this.props.user}
                mode={this.props.mode}
                sendInvoice={this.handleSendInvoice}
                setSendingStatus={this.props.emailSending}
                /> }

            {(this.props.invoicemode==="PURCHASE ORDER") &&
                <InvoiceFormPO 
                invoice={this.state.invoice} 
                setInvDetails={this.setInvoiceDetails} 
                setInv={this.setInvoice} 
                setInvDetailsDesc={this.setInvoiceDetailsDesc}
                addBlankDescToDetails={this.addBlankDescToDetails}
                removeDescFromDetails={this.removeDescFromDetails}
                removeDetailEntry={this.removeDetailEntry}
                setCustomer={this.setCustomer}
                setInvDate={this.setInvDate}
                setRemittance={this.setRemittance}
                addNewDetail={this.addNewDetail}
                closeForm={this.props.closeForm}
                handleSubmit={this.handleSubmit}
                user={this.props.user}
                mode={this.props.mode}
                sendInvoice={this.handleSendInvoice}
                setSendingStatus={this.props.emailSending}
                /> }

            {(this.props.invoicemode==="DEPOSIT - PURCHASE ORDER") &&
                <InvoiceFormDepositPO 
                invoice={this.state.invoice} 
                setInvDetails={this.setInvoiceDetails} 
                setInv={this.setInvoice} 
                setInvDetailsDesc={this.setInvoiceDetailsDesc}
                addBlankDescToDetails={this.addBlankDescToDetails}
                removeDescFromDetails={this.removeDescFromDetails}
                removeDetailEntry={this.removeDetailEntry}
                setCustomer={this.setCustomer}
                setInvDate={this.setInvDate}
                setRemittance={this.setRemittance}
                addNewDetail={this.addNewDetail}
                closeForm={this.props.closeForm}
                handleSubmit={this.handleSubmit}
                user={this.props.user}
                mode={this.props.mode}
                sendInvoice={this.handleSendInvoice}
                setSendingStatus={this.props.emailSending}
                /> }

        </div>
        );
    }
}
 
export default InvoiceForm2;