import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import Joi from 'joi-browser';
import InputBox from '../../components/input/inputbox';
import './signin.css';

class RegisterUser extends Component {
    state = {
        user: {name:"",email:"",password:"",password2:""},
        errors: {}
    } 

    validate = () => {
        const schema = Joi.object({      
            name: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("User name"),
            email: Joi.string()
                .min(6)
                .max(255)
                .required()
                .email()
                .label("Email"),
            password: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("Password"),
            password2: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("Confirm password")
        });
            
        const {user} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(user, schema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    
    };

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currUser  = {...this.state.user};
        currUser[name] = value;

        this.setState({
            user: currUser
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/users/registerUser'); 

        const errors = this.validate();
        console.log(errors);
        this.setState({errors: errors || {}});
        if (errors) return;

        const {user} = this.state;
        var data = JSON.stringify({
            name:user.name,
            email:user.email,
            password:user.password
        });
          
        var config = {
        method: 'post',
        url: url,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            const response = await Axios(config);
            if (response) {
                localStorage.setItem("token", response.headers['x-auth-token']);
                window.location = "/";
            } 
        } catch (error) {
            console.log(error.message);
        }
    };

    render() { 
        return (
            <div className="apc-sign-in-body">
                <div className="apc-register-box">
                    <h2>Register New User</h2>
                    <Form onSubmit={this.handleSubmit}>
                    <InputBox 
                            label="User Name"
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            error={this.state.errors.name}
                            onchange={this.handleChange}
                        />
                        <InputBox 
                            label="Email address"
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            error={this.state.errors.email}
                            onchange={this.handleChange}
                        />
                        <InputBox 
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            error={this.state.errors.password}
                            onchange={this.handleChange}
                        />
                        <InputBox 
                            label="Confirm password"
                            type="password"
                            name="password2"
                            placeholder="Confirm your password"
                            error={this.state.errors.password2}
                            onchange={this.handleChange}
                        />
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit">
                                Register
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>    
        );
    }
}
 
export default RegisterUser;