import React, { Component } from 'react';
import SpecInputBox from '../specsBox/specInputBox';
import './specBox.css';

class SpecsBox extends Component {
    
    renderGroupHeader = (cat, i) => {
        return (
            <React.Fragment>
                <tr key={cat+"- Header - "+i} className="apc-specbox-tr">
                    <td className='apc-specbox-row apc-specbox-groupheader apc-specbox-groupheader-border apc-specbox-leftborder-header'>{cat}</td>
                    <td className='apc-specbox-row apc-specbox-groupheader-border'>&nbsp;</td>
                    <td className='apc-specbox-row apc-specbox-groupheader-border'>&nbsp;</td>            
                </tr>
                <tr key={cat+"- Sub Header - "+i} className='apc-specbox-tr'>
                    <td className='apc-specbox-grouheader-subheader apc-specbox-leftborder'>Specification</td>
                    <td className='apc-specbox-grouheader-subheader'>Unit of Measure</td>
                    <td className='apc-specbox-grouheader-subheader apc-specbox-rightborder'>Value</td>            
                </tr>
            </React.Fragment>
        )    
    }

    render() { 
        const {specs} = this.props;
        //const uniqueCat = [...new Set(specs.specCategory)];
        //console.log(uniqueCat);
        //console.log(specs);
        //let ctr=0;
        let currCat="";
        let groupHeader="";
        return (
            <div className="apc-specbox-container">
                <table className='apc-specbox-table'>
                    {/* <thead>
                    <tr>
                        <th className='apc-specbox-row-header'>Specification</th>
                        <th className='apc-specbox-row-header'>Unit of Measure</th>
                        <th className='apc-specbox-row-header'>Value</th>
                    </tr>
                    </thead> */}
                    <tbody>
                        {specs.map((spec, index) => {
                            if (currCat!==spec.specCategory){
                                groupHeader=this.renderGroupHeader(spec.specCategory, index);
                                currCat=spec.specCategory;
                            } else {
                                groupHeader= "";
                            }
                            //ctr++;    
                            return(
                                <React.Fragment>
                                    {groupHeader}
                                    <tr className='apc-specbox-tr' key={"row-header-" +index}>
                                        <td className='apc-specbox-row apc-specbox-inactive-row apc-specbox-leftborder'>{spec.specName}</td>
                                        <td className='apc-specbox-row apc-specbox-inactive-row'>{spec.unitOfMeasure}</td>
                                        <td key={index} className='apc-specbox-row apc-specbox-leftborder apc-specbox-rightborder'>
                                        <SpecInputBox 
                                            type="text"
                                            name="specValue"
                                            placeholder="Enter Value"
                                            index={index}
                                            onchange={this.props.specChange}
                                            value={spec.specValue}
                                        />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}
                        )}
                    </tbody>
                </table>
                {
                        
                }  
            </div>
        );
    }
}
 
export default SpecsBox;