import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './woydialog.css'
import WoyPopUp from '../popup/woyPopUp';
import InventoryBrowser from '../woydbtable/inventoryBrowser';

class InventoryDialog extends Component {
    state = {
        invs: [],
        filteredInvs: [],
        selectedInv: {},
        activeColumn: "name",
        showPopUp: false    
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/byType/STOCK'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:invs} = await Axios(config);
            this.setState({invs:invs,filteredInvs:invs,selectedInv:this.props.inv});
        } catch (error) {
            console.log(error);
        }
    }

    openInvBrowser = () => {
        this.setState({showPopUp: true});
    }

    closeInvBrowser = () => {
        this.setState({showPopUp: false});
    }

    selectInvClick = (selectedInv) => {
        this.setState({selectedInv: selectedInv});
        this.props.setInv(selectedInv);
        this.closeInvBrowser();
    }

    displayInvDetails = () => {
        if (this.state.selectedInv !== null){
            if (Object.keys(this.state.selectedInv).length !== 0)
                return (<span className="input-group-text apc-form-dialog-text">{this.state.selectedInv.product.name}</span>)
            else
            return (<span className="input-group-text apc-form-dialog-text">No inventory selected.</span>);    
        }
        else 
            return (<span className="input-group-text apc-form-dialog-text">No inventory selected.</span>);
    }

    render() {
        return (
         <div>
            {
                this.state.showPopUp && 
                    <WoyPopUp handleClose={this.closeInvBrowser}>
                        <div>
                            <InventoryBrowser type="STOCK" setInv={this.selectInvClick}/>
                        </div>
                    </WoyPopUp>    
            } 
             <div className="input-group">
                <span className="input-group-text apc-form-dialog-label">Inventory</span>
                {this.displayInvDetails()}
                {(this.props.readonly!==true)&& <button className="btn btn-outline-success" type="button" id="button-addon1" onClick={this.openInvBrowser}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>}
            </div>
         </div>
        );
    }
}
 
export default InventoryDialog;