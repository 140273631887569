import React, { Component } from 'react';
import './woytags.css';

const addUpdate = ["add","update"];
const enumAddUpdate = ["enum-add","enum-update"];

class WoyTagButton extends Component {
    state = {  } 
    render() { 
        
        let btnType = 'woytag-button';
        let editMode = true;
        if (!(addUpdate.includes(this.props.mode)) && !(enumAddUpdate.includes(this.props.mode))){
            btnType = 'woytag-button-tabular'; 
            editMode = false;
        }
            
        return (
        <div className={btnType}>
            <span>{this.props.value} &nbsp;</span>
            {editMode && <span className='woytag-x-button' onClick={()=>{this.props.removeTag(this.props.value)}}>x</span>}
        </div>
        );
    }
}
 
export default WoyTagButton;