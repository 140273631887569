import React, { Component } from 'react';
class Compressors extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>APC Compressors Page</h1>
            </div>
        );
    }
}
 
export default Compressors;