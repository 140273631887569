import React from 'react';
import _ from 'lodash';
import './pagination.css';

const WoyPagination = (props) => {
    const {itemsCount, pageSize, currentPage, pageChangeClick} = props;
    const pagesCount = Math.ceil(itemsCount / pageSize);
    //console.log(pagesCount);
    if (pagesCount === 1) return null;
    const pages = _.range(1,pagesCount + 1);
    return (
        <nav>
            <ul className="pagination">
                {pages.map(page =>
                    <li key={page} className={page === currentPage ? 'woypagination-button-active':'woypagination-button'}>
                        <button key={"btn"+page} className="btn woypagination-button-text" onClick={()=>{pageChangeClick(page)}}>{page}</button>
                    </li>    
                )}
            </ul>
        </nav>
    );
}
 
export default WoyPagination;