import React, { Component } from 'react';
import Joi from 'joi-browser';
import Axios from 'axios';
import _ from 'lodash';
import InputBox from '../input/inputbox2';
import SelectBox from '../input/selectbox';
import CheckBox from '../input/checkbox';


const userRoles = ['ADMIN','GUEST','ACCOUNTS','SALES','DIRECTOR','OPERATIONS','PURCHASING','SERVICE','SECURITY','ICT','ELECTRICAL','SUPPLIER','CUSTOMER','HR'];
const updateRemove = ["update","remove"];
const insertUpdate = ["insert","update"];


class UsersForm extends Component { 
    state = {
        user: {name:"",email:"",password:"",password2:"",userRole:"GUEST",isAdmin:false},
        errors: {}
    } 

    componentDidMount() {
        if (updateRemove.includes(this.props.mode)) {
            this.setState({
                user: {
                    name: this.props.user.name,
                    email: this.props.user.email,
                    password: "DUMMY",
                    password2: "DUMMY",
                    userRole: this.props.user.userRole,
                    isAdmin: this.props.user.isAdmin
                }
            })
        }
    }
    
    formFields = () => {
        return(
            <React.Fragment>
                
                    <InputBox 
                        label="User Name"
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        error={this.state.errors.name}
                        onchange={this.handleChange}
                        value={this.state.user.name}
                    />
                    <InputBox 
                        label="Email address"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        error={this.state.errors.email}
                        onchange={this.handleChange}
                        value={this.state.user.email}
                    />
                    {(!updateRemove.includes(this.props.mode)) && <React.Fragment>
                        <InputBox 
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            error={this.state.errors.password}
                            onchange={this.handleChange}
                            value={this.state.user.password}
                        />
                        <InputBox 
                            label="Confirm password"
                            type="password"
                            name="password2"
                            placeholder="Confirm your password"
                            error={this.state.errors.password2}
                            onchange={this.handleChange}
                            value={this.state.user.password2}
                        />
                    </React.Fragment>}
                    <SelectBox 
                        label="User Role"
                        name="userRole"
                        placeholder="Select User Role"
                        error={this.state.errors.userRole}
                        onchange={this.handleChange}
                        value={this.state.user.userRole}
                        items={userRoles}
                    />
                    <CheckBox 
                        label="Administrator"
                        name="isAdmin"
                        placeholder="Administrator"
                        error={this.state.errors.isAdmin}
                        onchange={this.handleChange}
                        value={this.state.user.isAdmin}
                    />
                    
            </React.Fragment>
        )
    }

    validateInsert = () => {
        const schema = Joi.object({      
            name: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("User name"),
            email: Joi.string()
                .min(6)
                .max(255)
                .required()
                .email()
                .label("Email"),
            password: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("Password"),
            password2: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("Confirm password"),
            userRole: Joi.string()
                .valid(...userRoles)
                .required()
                .label("User role"),
            isAdmin: Joi.boolean()
                .required()
        });
            
        const {user} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(user, schema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    
    };

    validateUpdate = () => {
        const schema = Joi.object({      
            name: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("User name"),
            email: Joi.string()
                .min(6)
                .max(255)
                .required()
                .email()
                .label("Email"),
            userRole: Joi.string()
                .valid(...userRoles)
                .required()
                .label("User role"),
            isAdmin: Joi.boolean()
                .required()
        });
            
        const {user} = this.state;
        const edituser = _.pick(user,[
            "name",
            "email",
            "userRole",
            "isAdmin"
        ])
        const options = {abortEarly:false};
        const result = Joi.validate(edituser, schema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    
    };

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currUser  = {...this.state.user};
        currUser[name] = value;

        this.setState({
            user: currUser
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        let url = "";
        let method = "";

        if (this.props.mode === "add") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/users');
            method = 'post';
        } else if (this.props.mode === "update") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/users/'+this.props.user._id);
            method = 'put';
            //console.log("update url and method");
        } else if (this.props.mode === "remove") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/users/'+this.props.user._id);
            method = 'delete';
        }
             
        if (this.props.mode==="add"){
            const errors = this.validateInsert();
            //console.log(errors);
            this.setState({errors: errors || {}});
            if (errors) return;

        } else if (this.props.mode==="update"){
            const errors = this.validateUpdate();
            //console.log("update mode validate");
            this.setState({errors: errors || {}});
            //console.log(errors);
            if (errors) return;            
        }

        const {user} = this.state;
        let data = {};
        if (this.props.mode === "add") {
            data = JSON.stringify({
                name:user.name,
                email:user.email,
                password:user.password,
                userRole:user.userRole,
                isAdmin:user.isAdmin
            });
        } else {
                data = JSON.stringify({
                name:user.name,
                email:user.email,
                userRole:user.userRole,
                isAdmin:user.isAdmin
            });
        }
        
        //console.log(data);  
        var config = {
        method: method,
        url: url,
        headers: { 
            'x-auth-token': localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            //console.log(data);
            //console.log(url);
            const response = await Axios(config);
            //console.log(response);
            if (response) {
                const updatedUser = response.data;
                if (this.props.mode === "add"){
                    this.props.insertUser(updatedUser);
                } else if (this.props.mode === "update"){
                    this.props.updateUser(updatedUser);
                } else if (this.props.mode === "remove"){
                    this.props.removeUser(updatedUser._id);
                }
                this.props.closeForm();
            } 
        } catch (error) {
            console.log(error.message);
            this.props.closeForm();
        }
    };
    
    render() {
        let formcontents = "";
        if (this.props.mode === "remove"){
            formcontents = <fieldset disabled="disabled">{this.formFields()}</fieldset>;
        } else {
            formcontents = this.formFields();
        }
        return (
            
                <div>
                    <h2>{this.props.title}</h2>
                    <form onSubmit={this.handleSubmit}>  
                    {formcontents}
                    <div>
                        <button className="btn btn-primary" type="submit">
                            {this.props.mode}
                        </button>
                        <span>&nbsp;</span>
                        <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                            close
                        </button>
                    </div>
                </form>
                </div>
        );
    }
}
 
export default UsersForm;