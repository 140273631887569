import {React, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import WoyDatePicker from '../input/woydatepicker';

class WoyReceivingSearch extends Component {
    state = {
        //DateFrom: this.props.dateFrom,
        //DateTo: this.props.dateTo,
        searchText:"",
        searchColName:""
    }

    componentDidMount(){
        this.setState({
            //DateFrom: this.props.dateFrom,
            //DateTo: this.props.dateTo,
            searchColName:this.props.defaultOption
        });
    }

    setDateFrom = (dateFrom) => {
        //this.setState({DateFrom:dateFrom});
        this.props.setFilterDateFrom(dateFrom);
    }

    setDateTo = (dateTo) => {
        //this.setState({DateTo:dateTo});
        this.props.setFilterDateTo(dateTo);
    }

    handleColNameChange = (e) =>{
        this.setState({searchColName:e.target.value});
    }

    handleSearchTextChange = (e) =>{
        this.setState({searchText:e.target.value});
    }

    render() {
        const {options, searchClick} = this.props;
        return (
            <div className="row g-3 mt-1">
                    <div className="col-md">
                        <WoyDatePicker selectedDate={this.props.dateFrom} selectDate={this.setDateFrom} label="Date From"/>
                    </div>
                    <div className="col-md">
                        <WoyDatePicker selectedDate={this.props.dateTo} selectDate={this.setDateTo} label="Date To"/>
                    </div>
                    <div className="col-md">
                        <div className="input-group mb-3 apc-maintenance-search">
                            {/* <select className="custom-select" id="inputGroupSelect01" onChange={this.handleColNameChange}>
                            {options.map(option => 
                                    <option key={"id-"+option.value} value={option.value}>{option.label}</option>
                            )}
                            </select> */}
                            
                            <input type="text" className="form-control" placeholder="Enter Text to Search" aria-label="Enter Text to Search" onChange={this.handleSearchTextChange}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={()=>{searchClick(this.props.dateFrom,this.props.dateTo,this.state.searchText)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            
        );
    }
}

export default WoyReceivingSearch;