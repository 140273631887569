import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilePdf, faEdit, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate, sortbyColumn} from '../../../utils/paginate';
import InvoiceForm2 from '../../../components/forms/invoiceForm2';
//import WoyReceivingSearch from '../../../components/woycrudsearch/WoyReceivingSearch';
import WoyInvoiceSearch from '../../../components/woycrudsearch/WoyInvoiceSearch';
import WoyPopUp from '../../../components/popup/woyPopUp';
import InvoiceOptions from '../../../components/dialogs/invoiceOptionsDialog';
import {getFirstDayOfMonth, getLastDayOfMonth, formatMoneyWOCurrency, formatDate, getLocalDate} from '../../../utils/woyutils';


const editViewDelete=["update","remove","view"];

class Invoice extends Component {
    state = { 
        invoices : [],
        filteredInvs:[],
        selectedInv:{},
        pageSize:25,
        currentPage:1,
        activeColumn:"",
        filterDateFrom: new Date(),
        filterDateTo: new Date(),
        showInvoiceForm: false,
        showInvoiceOptions: false,
        showEmailSendingPrompt: false,
        emailSendingStatus: "",
        invoiceMode: "CASH SALE",
        showPDFForm: false,
        dialogMode: "add",
        colName: "customer.name"
    } 

    async componentDidMount() {

        const date = new Date();
        const firstDayCurrentMonth = getFirstDayOfMonth(date);
        const lastDayCurrentMonth = getLastDayOfMonth(date);

        this.setState({filterDateFrom: firstDayCurrentMonth, filterDateTo: lastDayCurrentMonth});
        
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/invoice'); 
        
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        //console.log(config);
        try {
            const {data:inv} = await Axios(config);
            //console.log(rec);
            if (Object.keys(inv).length !== 0) {
                this.setState({invoices:inv,filteredInvs:inv});
            }
            //console.log(rec);
            
        } catch (error) {
            console.log(error);
        }    
    }

    closeEmailSentPrompt = ()=> {
        this.setState({showEmailSendingPrompt:false});
    }

    openEmailSentPrompt = ()=> {
        this.setState({showEmailSendingPrompt:true});
    }

    setEmailSendingStatus =(status)=> {
        this.setState({showEmailSendingPrompt:true,emailSendingStatus:status});
    }

    closeInvoiceOptions = () => {
        this.setState({showInvoiceOptions:false});
    }

    setColName = (e) => {
        this.setState({colName:e.target.value});
    }

    setCashSale = () => {
       this.setState({invoiceMode:"CASH SALE", showInvoiceForm: true, showInvoiceOptions:false});    
    }

    setDepositCashSale = () => {
        this.setState({invoiceMode:"DEPOSIT - CASH SALE",showInvoiceForm: true, showInvoiceOptions:false});    
    }

    setPOSale = () => {
        this.setState({invoiceMode:"PURCHASE ORDER",showInvoiceForm: true, showInvoiceOptions:false}); 
    }

    setDepositPOSale = () => {
        this.setState({invoiceMode:"DEPOSIT - PURCHASE ORDER",showInvoiceForm: true, showInvoiceOptions:false}); 
    }

    handlePageChange = (page) =>{
        this.setState({currentPage:page});
    }

    handleSort = (colName) => {
        const sortedInvs = sortbyColumn(this.state.filteredInvs,colName);
        this.setState({filteredInvs:sortedInvs,activeColumn:colName});
    }

    addInvClick = () => {
        this.setState({dialogMode:"add", showInvoiceOptions: true, formTitle:"Issue new invoice"});
    }

    editInvClick = (rec) => {
        /* let invoicemode = "CASHSALE";
        if (rec.withProjectName)
            invoicemode="PROJECT";
        if (rec.withGST === false)
            invoicemode="DEPOSIT"; */

        this.setState({dialogMode:"update", showInvoiceForm: true, formTitle:"Update Invoice Details", selectedInv:rec, invoiceMode:rec.invoiceType});
    }

    viewInvClick = (rec) => {
        this.setState({dialogMode:"view", showPDFForm: true, formTitle:"Print Invoice", selectedInv:rec});
    }

    removeInvClick = (rec) => {
        this.setState({dialogMode:"remove", showInvoiceForm: true, formTitle:"Are you sure you want to delete this invoice?", selectedInv:rec});
    }

    closeInvoiceForm = () =>{
        this.setState({showInvoiceForm: false});
    }

    removeFromInvs = (id) => {
        let newInvs = this.state.invoices.slice();
        let newInvs2 = this.state.filteredInvs.slice();
        newInvs = newInvs.filter((rec) => rec._id !== id);
        newInvs2 = newInvs2.filter((rec) => rec._id !== id);
        this.setState({invoices:newInvs,filteredInvs:newInvs2});

    }

    editFromInvs = (editedRec) => {
        let editedInvs = this.state.invoices.slice();
        let editedInvs2 = this.state.filteredInvs.slice();
        editedInvs = editedInvs.map(rec => rec._id !== editedRec._id ? rec : editedRec);
        editedInvs2 = editedInvs2.map(rec => rec._id !== editedRec._id ? rec : editedRec);

        this.setState({invoices:editedInvs,filteredInvs:editedInvs2,selectedInv:editedRec})  
    }

    addToInvs = (newrec) => {
        let newInvs = this.state.invoices.slice();
        let newInvs2 = this.state.filteredInvs.slice();
        newInvs.unshift(newrec);
        newInvs2.unshift(newrec);
        this.setState({invoices:newInvs,filteredInvs:newInvs2})  
    }

    setDateFrom = (dateFrom) => {
        this.setState({filterDateFrom:dateFrom});
    }

    setDateTo = (dateTo) => {
        this.setState({filterDateTo:dateTo});
    }

    handleSearch = (startDate, endDate, colName, searchValue) => {
        //console.log(startDate + " - " + endDate);
        //const $regex = escapeStringRegexp(searchValue);
        const {invoices} = this.state;
        let newRecs = [];
        
        
        let start = new Date(startDate);
        
      /*   try {
            start =  startDate.getTime();    
        } catch (error) {
            start = new Date();
        }
         */
        let end = new Date(endDate);
       /*  end.setHours(23,59,59,999);
        end.getTime();
 */
        newRecs = invoices.filter(x => {
            let date = new Date(x.invoiceDate).getTime();
            return date >= start && date <= end;
         });

         if (searchValue!==""){
            //console.log(colName);
            if (colName==="customer.name") {
                
                newRecs = newRecs.filter( x => 
                    x.customer.name.includes(searchValue) 
                    );
            } 
            else if (colName==="product.name") {
                newRecs = newRecs.filter( x => 
                    x.invoiceDetails.some(t => t.description.includes(searchValue)) 
                    );
            } 
            else if (colName==="contact.name") {
                newRecs = newRecs.filter( x => 
                    x.customer.contactperson.includes(searchValue) 
                    );
            }
            else if (colName==="invoiceNo") {
                newRecs = newRecs.filter( x => 
                    x.invoiceNo.includes(searchValue) 
                    );
            };
         }
                
        this.setState({filteredInvs:newRecs})   
     }

     closePDFForm = () => {
        this.setState({showPDFForm:false});
     }

    
    render() { 
        const displayRecs = paginate(this.state.filteredInvs,this.state.currentPage,this.state.pageSize);
        const searchOptions = [
            {label:"Customer", value:"customer.name"},
            {label:"Product", value:"product.name"},
            {label:"Contact Person", value:"contact.name"},
            {label:"Invoice No", value:"invoiceNo"}
        ];

    
        return (
        <div>
            {(this.state.showInvoiceOptions === true) && (this.state.dialogMode==="add") && <WoyPopUp small={true} handleClose={this.closeInvoiceOptions}>
                    <div>
                       {<InvoiceOptions closeForm={this.closeInvoiceOptions} setCashSale={this.setCashSale} setDepositCashSale={this.setDepositCashSale} setPOSale={this.setPOSale} setDepositPOSale={this.setDepositPOSale}/>}
                    </div>
                </WoyPopUp>   }
            
            {
                
                this.state.showPDFForm &&    
                 <WoyPopUp handleClose={this.closePDFForm}>
                    <div>
                        {/* <InvoicePDF invoice={this.state.selectedInv}/>    */}
                        {/* <WoyPDFViewer value={defaultValue} invoice={this.state.selectedInv} /> */}
                    </div>
                </WoyPopUp>  
            }
            {
                (this.state.showEmailSendingPrompt === true)  && <WoyPopUp small={true} handleClose={this.closeEmailSentPrompt}>
                    <div>
                        <h1>{this.state.emailSendingStatus}</h1>
                    </div>
                </WoyPopUp>
            }
             <div className='apc-maintenance-title-container'>
                <div className="mt-3">
                    <h2>Invoices</h2>
                </div>
                <div>
                    <WoyInvoiceSearch options={searchOptions} defaultOption="customer.name" searchClick={this.handleSearch} dateFrom={this.state.filterDateFrom} dateTo={this.state.filterDateTo} setFilterDateFrom={this.setDateFrom} setFilterDateTo={this.setDateTo} setColName={this.setColName}/>
                </div>
                
                <div className='apc-maintenance-addbutton-container mt-3 mb-1'>
                   <button type="button" onClick={this.addInvClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
            
            {(this.state.showInvoiceForm === true) && (this.state.dialogMode==="add") && <div className='apc-form-recpanel'><InvoiceForm2 invoice={this.state.selectedInv} invoicemode={this.state.invoiceMode} mode={this.state.dialogMode} user={this.props.user} insertInvoice={this.addToInvs} updateInvoice={this.editFromInvs} removeInvoice={this.removeFromInvs} closeForm={this.closeInvoiceForm}/></div>}
            <div>
                <table className="apc-table-container">
                <thead>
                    <tr>
                        <th className={this.state.activeColumn === "invoiceDate" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("invoiceDate")}}>Invoice Date</th>
                        <th className={this.state.activeColumn === "invoiceNo" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("invoiceNo")}}>Invoice No</th>
                        <th className={this.state.activeColumn === "invoiceType" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("invoiceType")}}>Type</th>
                        <th className={this.state.activeColumn === "customer.name" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("customer.name")}}>Customer</th>
                        <th className={this.state.activeColumn === "purchaseOrderNo" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("purchaseOrderNo")}}>PO</th>
                        <th className={this.state.activeColumn === "invoiceStatus" ? 'apc-woytable-row-header-active':'apc-woytable-row-header'} onClick={() => {this.handleSort("invoiceStatus")}}>Status</th>
                        <th className={this.state.activeColumn === "total" ? 'apc-woytable-row-header-active apc-table-cell-align-right':'apc-woytable-row-header apc-table-cell-align-right'} onClick={() => {this.handleSort("total")}}>Total</th>
                        <th className='apc-woytable-row-header'>Action(s)</th>
                    </tr>
                </thead>
                 <tbody>
                {
                    displayRecs.map((rec,index) => 
                    <React.Fragment><tr className='apc-woytable-row' key={index}>
                       <td className='apc-woytable-col'>{formatDate(getLocalDate("BRISBANE",rec.invoiceDate))}</td>
                       <td className='apc-woytable-col'>{rec.invoiceNo}</td>
                       <td className='apc-woytable-col'>{rec.invoiceType}</td>
                       <td className='apc-woytable-col'>{rec.customer.name}</td>
                       <td className='apc-woytable-col'>{rec.purchaseOrderNo}</td>
                       <td className='apc-woytable-col'>{rec.invoiceStatus}</td>
                       <td className='apc-woytable-col apc-table-cell-align-right'>{formatMoneyWOCurrency(rec.total)}</td>
                       <td className='apc-woytable-col'>
                            <div className="apc-actionbuttongrid" key={index}>
                                <div>
                                {
                                    (this.props.user.isAdmin) && <button value={rec._id} type="button" onClick={() => {this.removeInvClick(rec)}} className="btn btn-danger apc-actionbutton"><FontAwesomeIcon icon={faTrashCan} /></button>
                                }
                                </div>
                                <div>
                                    <button value={rec._id} type="button" onClick={() => {this.editInvClick(rec)}} className="btn btn-warning apc-actionbutton"><FontAwesomeIcon icon={faEdit} /></button>
                                </div> 
                                <div>
                                    <a href={process.env.REACT_APP_BACKEND_URL + '/public/uploads/invoices/'+rec._id+'.pdf'} target="new" ><button type="button" className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faFilePdf} /></button></a>
                                </div> 
                            </div>
                       </td>
                    </tr>
                    {(editViewDelete.includes(this.state.dialogMode))&&(this.state.showInvoiceForm) && (this.state.selectedInv._id === rec._id) && 
                    <tr>
                        <td colSpan={9} className="apc-table-editRow">
                            <div className='apc-table-editInvoice-Content'>
                                <InvoiceForm2 
                                invoice={this.state.selectedInv} 
                                invoicemode={this.state.selectedInv.invoiceType}
                                mode={this.state.dialogMode}
                                user={this.props.user}
                                insertInvoice={this.addToInvs}
                                updateInvoice={this.editFromInvs}
                                removeInvoice={this.removeFromInvs}
                                emailSending={this.setEmailSendingStatus}
                                closeForm={this.closeInvoiceForm}
                                />
                            </div>
                        </td>
                    </tr>}
                    </React.Fragment>
                    )    
                }
                </tbody>
                </table>
            </div>
            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayRecs.length} of {this.state.filteredInvs.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredInvs.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange}/>
            </div>
        </div>
        );
    }
}
 
export default Invoice;