import React, { Component } from 'react';
import moment from 'moment';
import WoyTags from '../../../components/input/woytags/woytags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashCan, faEdit, faEye} from '@fortawesome/free-solid-svg-icons';
import "./invrow.css";

class InventoryRow extends Component {
    state = {  } 

    formatMoney = (n) => {
      var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      return formatter.format(n);
    }

    setInvImageCell = (inv) => {
        let className = "inv-image-cell";
        if (inv.status === "RECEIVED")
          className = className + " inv-rec-border"
        else
          className = className + " inv-disp-border";
        
        return (
            <div>
              {this.setStatusCell(inv)}
              <img src={process.env.REACT_APP_BACKEND_URL +"\\" + inv.product.profilephoto} className={className} alt="feature photo"/>
            </div>
        );
    }

    setInvMovementCell = (inv) => {
        let dispatchDateStr = "";
        let receiveDateStr = "";
        
        try {
          if (inv.status !== "RECEIVED" && inv.status !== "DISPATCHED")
              receiveDateStr = "---"
          else if (inv.status === "RECEIVED" && !(inv.receive))
              receiveDateStr = "---"
          else{
            if (inv.receive.supplier) {
              let recDate = moment(inv.receive.receivingDate);
              recDate.add(10,'hours');
              receiveDateStr = recDate.format("DD MMM YYYY"); 
            }
            else
              receiveDateStr = "---";
          }  
        } catch (error) {
          receiveDateStr = "---"; 
        };
        
        if (inv.status !== "DISPATCHED")
            dispatchDateStr = "---"
        else{
            let recDate = moment(inv.dispatch.dispatchDate);
            recDate.add(10,'hours');
            dispatchDateStr = recDate.format("DD MMM YYYY");
        }
        
        const id = inv.product._id.toString(); 
        const jobno = id.substr(id.length - 5);

        return (
            <div className="container">
            <div className="row">
              <div className="col-sm inv-rec-cell">
                Received
              </div>
              <div className="col-sm inv-rec-cell">
                {receiveDateStr}
              </div>
            </div>
            <div className="row">
              <div className="col-sm inv-disp-cell">
                Dispatched
              </div>
              <div className="col-sm inv-disp-cell">
                {dispatchDateStr}
              </div>
            </div>
            <div className="row">
              <div className="col-sm inv-job-cell">
                Job Number
              </div>
              <div className="col-sm inv-job-cell">
                {jobno}
              </div>
            </div>

          </div>     
        )
    }

    setStatusCell = (inv) =>{
      let classname = "inv-stat-cell";
      let status = "IN STOCK";
      if (inv.status === "RECEIVED")
        classname = classname + " inv-rec-cell"
      else if (inv.status === "DISPATCHED"){
        status = "SOLD";
        classname = classname + " inv-disp-cell";
      }
        
      
      return(
        <div className={classname}>
          {status}
        </div>
      )
    }

    setDetailsCell = (inv) => {
      let totalcost = 0;
      if (inv.receive)
        totalcost = inv.receive.totalCost;

      return (
        <div className="container">
        <div className="row">
          <div className="col-2 inv-details-cell inv-details-label">
            Name:
          </div>
          <div className="col-sm inv-details-cell">
            {inv.product.name}
          </div>
        </div>
        <div className="row">
          <div className="col-2 inv-details-cell inv-details-label">
            Serial No:
          </div>
          <div className="col-sm inv-details-cell">
            {inv.product.serialno}
          </div>
        </div>
        <div className="row">
          <div className="col-2 inv-details-cell inv-details-label">
            Hour(s):
          </div>
          <div className="col-sm inv-details-cell">
            {inv.product.operatinghours}
          </div>
        </div>
        <div className="row">
          <div className="col-2 inv-details-cell inv-details-label">
            Cost:
          </div>
          <div className="col-sm inv-details-cell">
            {this.formatMoney(totalcost)}
          </div>
        </div>
      </div>     
    )
    }

    render() {
        let rowColor  = "inv-row-odd";
        if (this.props.index % 2 === 0)
          rowColor  = "inv-row-even";  
        return (
            <tr className={rowColor}>
              <td className="inv-cell-padding">
                {this.setInvImageCell(this.props.inv)}
              </td>
              {/* <td className="inv-cell-padding">
                {this.setStatusCell(this.props.inv)}
              </td> */}
              <td className="inv-cell-padding">
                <WoyTags editMode={false} tags={this.props.inv.product.category}/>
              </td>
              <td className="inv-cell-padding">
                {this.setInvMovementCell(this.props.inv)}
              </td>
              <td className="inv-cell-padding">
                {this.setDetailsCell(this.props.inv)}
              </td>
              <td className='inv-action-cell className="inv-cell-padding"'>
                  <div className="apc-actionbuttongrid">
                      <div>
                      {
                          (this.props.user.isAdmin) && <button value={this.props.inv._id} type="button" onClick={() => {this.props.removeInvClick(this.props.inv)}} className="btn btn-danger apc-actionbutton"><FontAwesomeIcon icon={faTrashCan} /></button>
                      }
                      </div>
                      <div>
                            {(this.props.user.isAdmin) && <button value={this.props.inv._id} type="button" onClick={() => {this.props.editInvClick(this.props.inv)}} className="btn btn-warning apc-actionbutton"><FontAwesomeIcon icon={faEdit} /></button>}
                      </div> 
                      <div>
                            <button value={this.props.inv._id} type="button" onClick={() => {this.props.viewInvClick(this.props.inv)}} className="btn btn-success apc-actionbutton"><FontAwesomeIcon icon={faEye} /></button>
                      </div> 
                  </div>
              </td>
            </tr>
        );
    }
}
 
export default InventoryRow;