import React,{Component} from "react";
import './woyPopUp.css';

class WoyPopUp extends Component {
  
  render() {
    let boxclass = "woy-box"; 
    if (this.props.small === true)
      boxclass = "woy-box-small";
    return (
      <div className="woy-popup-box">
        <div className={boxclass}>
          <span className="woy-close-icon" onClick={this.props.handleClose}>x</span>
          {this.props.children}
        </div>
      </div>
    );
  }
}
 
export default WoyPopUp ;

/* const WoyPopUp = props => {
  return (
    <div className="woy-popup-box">
      <div className="woy-box">
        <span className="woy-close-icon" onClick={props.handleClose}>x</span>
        {props.content}
      </div>
    </div>
  );
};
 
export default WoyPopUp; */