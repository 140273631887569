import React, { Component } from 'react';
import Joi from 'joi-browser';
import Axios from 'axios';
import InventoryDialog from '../dialogs/invDialog';
import SupplierDialog from '../dialogs/supplierDialog';
import InvForm,{invSchema} from './invform';
import SuppliersForm,{supplierSchema} from './suppliersform';
import WoyDatePicker from '../input/woydatepicker';
import InputBox from '../input/inputbox2';
import TextBox from '../input/textbox';

const updateRemove = ["update","remove","view"];
const updateInsert = ["update","add"];

export const recSchema = Joi.object({
    _id:Joi.string()
    .optional(),
    inv:invSchema
    .required(),
    supplier:supplierSchema
    .required(),
    receivingDate: Joi.date()
    .required(),
    description: Joi.string()
    .required(),
    purchaseOrder:  Joi.string()
    .optional(),
    invoice:  Joi.string()
    .optional(),
    qty: Joi.number()
    .min(0)
    .required(),
    cost: Joi.number()
    .optional(),
    totalCost: Joi.number()
    .optional(),
    receivedBy: Joi.string()
    .required(),
    updated_at: Joi.date()
    .optional(),
    created_at: Joi.date()
    .optional(),
    __v: Joi.number()
    .optional()
});

class ReceivingForm extends Component {
    state = {
        rec: {
            inv:{},
            supplier:{},
            receivingDate: new Date(),
            description: "",
            purchaseOrder: "NA",
            invoice: "NA",
            qty: 1,
            cost: 0,
            totalCost:0,
            receivedBy: this.props.user.name
        },
        selectedInv:{},
        selectedSupplier:{},
        errors: {},
        currentFormDisplay:"MainForm"
    } 

    componentDidMount() {
        if (updateRemove.includes(this.props.mode)) {
            this.setState({
                rec: {
                    inv: this.props.rec.inv,
                    supplier: this.props.rec.supplier,
                    receivingDate: this.props.rec.receivingDate,
                    description: this.props.rec.description,
                    purchaseOrder: this.props.rec.purchaseOrder,
                    invoice: this.props.rec.invoice,
                    qty: this.props.rec.qty,
                    cost: this.props.rec.cost,
                    totalCost: this.props.rec.totalCost,
                    receivedBy: this.props.rec.receivedBy
                },
                selectedInv:this.props.rec.inv,
                selectedSupplier:this.props.rec.supplier,
            })
        }
    }

    setInv = (inv) => {
        //this.setState({selectedInv:inv});
        let temprec = {...this.state.rec};
        temprec.inv = inv;
        temprec.description = inv.product.description;
        this.setState({rec:temprec,selectedInv:inv});
    }

    setSupplier = (supplier) => {
        //this.setState({selectedSupplier:supplier})
        let temprec = {...this.state.rec};
        temprec.supplier = supplier;
        this.setState({rec:temprec,selectedSupplier:supplier});
    }

    setReceivingDate = (recdate) => {
        //this.setState({selectedSupplier:supplier})
        let temprec = {...this.state.rec};
        temprec.receivingDate = recdate;
        this.setState({rec:temprec});
    }

    invView = () => {
        return(
            <React.Fragment>
                {(Object.keys(this.state.selectedInv).length !== 0) && <InvForm mode="view" title="Inventory Details" inv={this.state.selectedInv}/>}
                {(Object.keys(this.state.selectedInv).length === 0) && <h2>No Inventory selected to receive</h2>}
            </React.Fragment>
        );    
    }

    supplierView = () => {
        return(
            <React.Fragment>
                {(Object.keys(this.state.selectedSupplier).length !== 0) && <SuppliersForm mode="view" title="Supplier Details" supplier={this.state.selectedSupplier}/>}
                {(Object.keys(this.state.selectedSupplier).length === 0) && <h2>No Supplier selected for this Stock</h2>}
            </React.Fragment>
        );
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currRec  = {...this.state.rec};
        currRec[name] = value;
        if (name==="cost" || name==="qty") {
            currRec["totalCost"] = (currRec["cost"] * currRec["qty"]);
        }
        //console.log(value);
        this.setState({rec:currRec});
        //console.log(currRec["cost"]+" -- "+this.state.rec.totalCost.toString());
        //console.log(this.state.rec);
    };

    formFields = (mode) => {
        let readonly = false;
        if (mode==="update") 
            readonly=true;
        return(
            <React.Fragment>
                
                    <InventoryDialog inv={this.state.selectedInv} mode="receiving" setInv={this.setInv} readonly={readonly}/>
                    <SupplierDialog supplier={this.state.selectedSupplier} setSupplier={this.setSupplier}/>
                    <div className="row g-3 mt-1">
                        <div className="col-md">
                            <WoyDatePicker selectedDate={this.state.rec.receivingDate} selectDate={this.setReceivingDate} label="Date Received"/>
                        </div>
                        <div className="col-md">
                            <InputBox 
                            label="PO #"
                            type="text"
                            name="purchaseOrder"
                            placeholder="PO #"
                            error={this.state.errors.purchaseOrder}
                            onchange={this.handleChange}
                            value={this.state.rec.purchaseOrder}
                            />
                        </div>
                        <div className="col-md">
                            <InputBox 
                            label="Invoice #"
                            type="text"
                            name="invoice"
                            placeholder="Invopice #"
                            error={this.state.errors.invoice}
                            onchange={this.handleChange}
                            value={this.state.rec.invoice}
                            />
                        </div>
                    </div>
                    <div className="row g-1 mt-1">
                        <div className="col-md">
                            <TextBox 
                            label="Description"
                            name="description"
                            placeholder="Description"
                            error={this.state.errors.description}
                            onchange={this.handleChange}
                            value={this.state.rec.description}
                            />
                        </div>
                    </div>
                    <div className="row g-3 mt-1">
                        <div className="col-md">
                            <InputBox 
                            label="Quantity"
                            type="text"
                            name="qty"
                            placeholder="Quantity"
                            error={this.state.errors.qty}
                            onchange={this.handleChange}
                            value={this.state.rec.qty}
                            />
                        </div>
                        <div className="col-md">
                            <InputBox 
                            label="Cost"
                            type="text"
                            name="cost"
                            placeholder="Cost"
                            error={this.state.errors.cost}
                            onchange={this.handleChange}
                            value={this.state.rec.cost}
                            />
                        </div>
                        <div className="col-md">
                            <InputBox 
                            label="Total Cost"
                            type="text"
                            name="totalCost"
                            placeholder="Total Cost"
                            error={this.state.errors.totalCost}
                            onchange={this.handleChange}
                            value={this.state.rec.totalCost}
                            />
                        </div>
                    </div>
                   
            </React.Fragment>
        )
    }

    validate = () => {
        const {rec} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(rec, recSchema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    }

    handleSubmit = async (e) => {
        //e.preventDefault();
        console.log("submit clicked");
        let url = "";
        let method = "";

        if (this.props.mode === "add") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/receiving');
            method = 'post';
        }  else if (this.props.mode === "update") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/receiving/'+this.props.rec._id);
            method = 'put';
        }  else if (this.props.mode === "remove") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/receiving/'+this.props.rec._id);
            method = 'delete';
        }
             
        if (updateInsert.includes(this.props.mode)){
            const errors = this.validate();
            //console.log(errors);
            this.setState({errors: errors || {}});
            console.log(errors);
            if (errors) return;

        } 

        const {rec} = this.state;
        if (updateInsert.includes(this.props.mode)){
            rec.inv.status = "RECEIVED";
        }
            
        let data = JSON.stringify({
            inv: rec.inv,
            supplier: rec.supplier,
            receivingDate: rec.receivingDate,
            description: rec.description,
            purchaseOrder: rec.purchaseOrder,
            invoice: rec.invoice,
            qty: rec.qty,
            cost: rec.cost,
            totalCost: rec.totalCost,
            receivedBy: rec.receivedBy
        });
        
        //console.log(data);  
        var config = {
        method: method,
        url: url,
        headers: { 
            'x-auth-token': localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            const response = await Axios(config);
            if (response) {
                console.log(response);
                const updatedRec = response.data;
                if (this.props.mode === "add"){
                    this.props.insertRec(updatedRec);
                } else if (this.props.mode === "update"){
                    this.props.updateRec(updatedRec);
                } else if (this.props.mode === "remove"){
                    this.props.removeRec(updatedRec._id);
                }
                
            } 
            this.props.closeForm();
        } catch (error) {
            console.log(error.response.data);
            console.log(error);
            this.props.closeForm();
        }
    };

    renderTabs = () => { 
        return (
            <ul className="nav nav-tabs">
                <li className="nav-item" key="receiving">
                    {(this.state.currentFormDisplay==="MainForm") && <button className="nav-link active apc-tab-button" aria-current="page" onClick={()=> {this.setState({currentFormDisplay: "MainForm"})}}>Receiving</button>}
                    {(this.state.currentFormDisplay!=="MainForm") && <button className="nav-link apc-tab-button" aria-current="page" onClick={()=> {this.setState({currentFormDisplay: "MainForm"})}}>Receiving</button>}
                </li>
                <li className="nav-item" key="inventory">
                    {(this.state.currentFormDisplay==="InvForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "InvForm"})}}>Inventory</button>}
                    {(this.state.currentFormDisplay!=="InvForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "InvForm"})}}>Inventory</button>}
                </li>
                <li className="nav-item" key="supplier">
                    {(this.state.currentFormDisplay==="SupplierForm") && <button className="nav-link active apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "SupplierForm"})}}>Supplier</button>}
                    {(this.state.currentFormDisplay!=="SupplierForm") && <button className="nav-link apc-tab-button" onClick={()=> {this.setState({currentFormDisplay: "SupplierForm"})}}>Supplier</button>}
                </li>
            </ul> 
        )
    }
    

    render() { 
        let formcontents = "";
        if (this.props.mode==="remove"){
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = <fieldset disabled="disabled">{this.formFields(this.props.mode)}</fieldset>
            else if (this.state.currentFormDisplay==="InvForm")
                formcontents = <fieldset disabled="disabled">{this.invView()}</fieldset>
            else if (this.state.currentFormDisplay==="SupplierForm")
                formcontents = <fieldset disabled="disabled">{this.supplierView()}</fieldset>
        }
        else if (this.props.mode==="view"){
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = <fieldset disabled="disabled">{this.formFields(this.props.mode)}</fieldset>
            else if (this.state.currentFormDisplay==="InvForm")
                formcontents = this.invView()
            else if (this.state.currentFormDisplay==="SupplierForm")
                formcontents = this.supplierView()
            
        } 
        else {
            if (this.state.currentFormDisplay==="MainForm")
                formcontents = this.formFields(this.props.mode)
            else if (this.state.currentFormDisplay==="InvForm")
                formcontents = this.invView()
            else if (this.state.currentFormDisplay==="SupplierForm")
                formcontents = this.supplierView()
        }

        return (
            <div>
                <h2>{this.props.title}</h2>
                    <div className='apc-form-tabs'>{this.renderTabs()}</div>
                    <div className='container '>
                        {formcontents}
                        <div>
                            {(this.props.mode !== "view") && <button className="btn btn-primary" type="button" onClick={this.handleSubmit}>
                                {this.props.mode}
                            </button>}
                            <span>&nbsp;</span>
                            <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                                close
                            </button>
                        </div>
                        {/* </form> */}
                    </div>
            </div>
        );
    }
}
 
export default ReceivingForm;