import React from 'react';

const InvoiceCheckBox = ({label, name, placeholder, error, onchange, value, align}) =>{
    return(
        <div className="form-check form-switch">
           
                {(value) && <input type="checkbox" className="form-check-input invoice-checkbox" id={name} name={name} placeholder={placeholder} onChange={onchange} checked/>}
                {(!value) && <input type="checkbox" className="form-check-input invoice-checkbox" id={name} name={name} placeholder={placeholder} onChange={onchange}/>}
            <label className="form-check-label cbInvoice" htmlFor={name}>
                {label}
            </label>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default InvoiceCheckBox;