import React, { Component } from 'react';
import './apclogo.css';
class APCLogo extends Component {
    render() { 
        return (
            <img className="apclogo-logocontainer" src="/img/APC-side-logo-yellow.png" alt="Australian Power Corporation"/>
        );
    }
}
 
export default APCLogo;