import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import WoyPopUp from '../popup/woyPopUp';

class ButtonWoyDatePicker extends Component {
    state = {  
        //selectedDate: this.props.selectedDate,
        showPicker: false
    } 

    componentDidMount() {
        //this.setState({selectedDate: this.props.selectedDate});
        //console.log(this.state.selectedDate);
    }
    
    setSelectedDate = (selectedDate) => {
        //this.setState({selectedDate: selectedDate});
        this.props.selectDate(selectedDate);
        this.closePicker();
    }

    showPicker = () => {
        this.setState({showPicker:true});
    }

    closePicker = () => {
        this.setState({showPicker:false});
    }

    formattedDate = () =>{
        
        //const dateVal = this.state.selectedDate;
        try {
            return(
                format(this.props.selectedDate, 'dd-MMM-yyyy')
            );        
        } catch (error) {
            return(format(new Date(), 'dd-MMM-yyyy'))    
        };
        
    }

    handleChange = (e) => {
        try {
            //this.setState({selectedDate: Date.parse(e.target.value)}); 
            this.setSelectedDate(Date.parse(e.target.value));   
        } catch (error) {
            console.log("Date Parse Error. Returning present date.")    
        }
        
    }

    render() { 
        return (
            <div>
                <div>
                    {(this.state.showPicker) && 
                    <WoyPopUp handleClose={this.closePicker} small={true}>
                        <h3>{this.props.label}</h3>
                        <DayPicker
                        mode="single"
                        selected={this.props.selectedDate}
                        onSelect={this.setSelectedDate}
                        />    
                    </WoyPopUp>}
                </div>
                <div>
                    <button className="plusButton" type="button" id="button-addon2" onClick={this.showPicker}><FontAwesomeIcon icon={faCalendar}/></button>
                </div>
            </div>
        );
    }
}
 
export default ButtonWoyDatePicker;