import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import './WoyUploader.css';

const blankMachineFilename = "1652233408510-genset-blank.jpg";
const addUpdate = ["add","update"];

class WoyUploader extends Component {
    state = { 
        file:null,
        filename:"Choose File",
        preview: ""
    } 

    componentDidMount() {
        if (this.props.preview) {
            this.setState({preview:this.props.preview})
        }
    }
    

    handleFileChange = e => {
        this.setState({
            file:e.target.files[0],
            filename: e.target.files[0].name
        });
    
        /* console.log(e.target.files[0]);
        console.log(e.target.files[0].name); */
    }

    handleSubmit = async () => {
        //e.preventDefault();
       
        //initialize axios parameters
        const url = process.env.REACT_APP_BACKEND_URL + '/api/uploads/profilePhoto';
        const formData = new FormData();
        formData.append("image",this.state.file);
        const config = {
            method:"post",
            url: url,
            data: formData,
            headers: {
                "Content-Type":"multipart/form-data",
                "x-auth-token": localStorage.getItem("token")
            }
        };

        try {
            const res = await axios(config);
            if (res) {
                /* const filename = process.env.REACT_APP_BACKEND_URL + "\\"+res.data.file.path;
                console.log(res.data.msg);
                console.log(res.data);
                console.log(filename); */
                this.setState({preview: res.data.file.path})
                this.props.setParentField(res.data.file.path);
            }
        } catch (error) {
          console.log(error);  
        }
    }

    render() { 
        const {filename} = this.state;
        //const blankImg = process.env.REACT_APP_BACKEND_URL + '/public/uploads/' + blankMachineFilename;
        return (
            <Fragment>
                <div className="input-group mb-3">
                    {addUpdate.includes(this.props.mode) && <Fragment><input type="file" className="form-control" id="inputGroupFile02" placeholder={filename} onChange={this.handleFileChange}/>
                    <div className="input-group-append btn btn-success" onClick={this.handleSubmit}>
                        {/* <span>{this.props.uploadButtonLabel}</span> */}
                        <span><FontAwesomeIcon icon={faUpload} /></span>
                    </div>
                    </Fragment> }
                </div>
                <div className="row justify-content-center">
                    {(this.state.preview !== "") && <img className="woyuploader-featurephoto-img" src={process.env.REACT_APP_BACKEND_URL +'/'+ this.state.preview} alt="Profile Avatar"/>}
                    
                </div>
            </Fragment>
        );
    }
}
 
export default WoyUploader;