import React, { Component } from 'react';
import WoyAccordion from './Accordion';
//import Accordion from 'react-bootstrap/Accordion'
import './DashSideMenu.css';

class DashSideMenu extends Component {
    state = {  } 
    render() { 
        return (
        <div key="dash-side-menu">
            <WoyAccordion onClick={this.props.handleClick}/>
        </div>
        );
    }
}
 
export default DashSideMenu;