import React, { Component } from 'react';
class Portables extends Component {
    state = {ptype: this.props.type} 
    render() {
        const types = [
            {
                code:"ADG",
                description:"Portable Diesel Generators"    
            },
            {
                code:"APG",
                description:"Portable Petrol Generators"    
            },
            {
                code:"API",
                description:"Portable Inverter Generators"    
            },
            {
                code:"APW",
                description:"Portable Water Pumps"    
            }
        ];
        const portabletype = types.find(types => types.code === this.state.ptype);
        return (
            <div>
                <h1>APC {portabletype.description} Page</h1>
            </div>
        );
    }
}
 
export default Portables;