import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './woydialog.css'
import WoyPopUp from '../popup/woyPopUp';
import WoyCRUDSearch from '../woycrudsearch/WoyCRUDSearch';
import InvoiceTableBrowse from '../woydbtable/woyinvoicetablebrowse';
import {sortbyColumn} from '../../utils/paginate';

class ButtonInvoiceDialog extends Component {
    state = {
        invoices: [],
        filteredInvoices: [],
        selectedInvoice: {},
        activeColumn: "invoiceDate",
        showPopUp: false    
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/invoice'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:inv} = await Axios(config);
            this.setState({invoices:inv,filteredInvoices:inv});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
        // console.log(colName + " - " + searchValue);
         //const $regex = escapeStringRegexp(searchValue);
        const {invoices} = this.state;
        let newInvoices = [];
         
        newInvoices = invoices.filter( x => 
            x[colName].includes(searchValue) 
        );
         
         this.setState({filteredInvoices:newInvoices})   
     }

    openInvoiceBrowser = () => {
        this.setState({showPopUp: true});
    }

    closeInvoiceBrowser = () => {
        this.setState({showPopUp: false});
    }

    selectInvoiceClick = (selected) => {
        this.setState({selectedInvoice: selected,showPopUp: false});
        this.props.setInvoice(selected);
        //this.closeInvoiceBrowser();
    }

    handleSort = (colName) => {
        const sortedInvoices = sortbyColumn(this.state.filteredInvoices,colName);
        this.setState({filteredInvoices:sortedInvoices,activeColumn:colName});
    }

    render() {
        let display = [
            {
                label:"Invoice Date",
                value:"invoiceDate"
            },
            {
                label:"Invoice No",
                value:"invoiceNo"
            },
            {
                label:"Customer",
                value:"customer.name"
            },
            {
                label:"Contact",
                value: "customer.contactperson"
            },
            {
                label:"Status",
                value:"invoiceStatus"
            },
            {
                label:"Total",
                value:"total"
            },
                        
        ];

        const displayInvoices = this.state.filteredInvoices;
        //console.log(displayProduct);

        const searchOptions = [
            {label:"Name", value:"customer.name"},
            {label:"Contact Person", value:"customer.contactperson"}
        ];

        return (
         <div>
            {
                this.state.showPopUp && 
                    <WoyPopUp handleClose={this.closeInvoiceBrowser}>
                        <div>
                            <WoyCRUDSearch options={searchOptions} defaultOption="customer.name" searchClick={this.handleSearch}/>
                        </div>
                        <InvoiceTableBrowse 
                        data={displayInvoices} 
                        user={this.props.user} 
                        display={display} 
                        selectMethod={this.selectInvoiceClick} 
                        sortBy={this.state.activeColumn} 
                        sortData={this.handleSort} 
                        />
                    </WoyPopUp>    
            } 
            <div className="input-group mb-3">
                    <span className="input-group-text">{this.props.label}</span>
                    <input type="text" className="form-control" id="floatingInputGrid" placeholder={this.props.value} value={this.props.value}/>
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.openInvoiceBrowser}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
                </div>
         </div>
        );
    }
}
 
export default ButtonInvoiceDialog;