import moment from 'moment/moment';

const localTime = [
    {locale:"BRISBANE",utc:10,plus:true},
    {locale:"PERTH",utc:8,plus:true},
    {locale:"SYDNEY",utc:11,plus:true}
]

export function formatMoneyWOCurrency(amt) {
    const value = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).formatToParts(amt).map(
        p => p.type !== 'literal' && p.type !== 'currency' ? p.value : ''
    ).join('');

    return value;
}

export function formatMoney (amt){
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(amt);
}

export function formatDate (date){
    return moment(date).format('DD MMM YYYY');
}

export function getFirstDayOfMonth (date) {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
}

export function getLastDayOfMonth (date) {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay;
}

export function getLocalDate (locale, date) {
    let obj = localTime.find(o => o.locale === locale);
    let retval = date;
    if (Object.keys(obj).length > 0) {
        if (obj.plus)
            retval = new Date(date).setHours(new Date(date).getHours()+obj.utc)
        else
            retval = new Date(date).setHours(new Date(date).getHours()-obj.utc);
    };
    return(retval);
}