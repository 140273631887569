import './main.css';
import React, { Component } from 'react';
import {Routes,Route, Link} from "react-router-dom";
import jwtDecode from 'jwt-decode';
import Home from '../home/home';
import APCLogo from '../../components/apclogo/apclogo';
import APCNavbar from '../../components/navbar/navbar';
import DieselGenerators from '../generators/dieselgenerators';
import SilencedDieselGenerators from '../generators/silenceddieselgenerators';
import Portables from '../portables/portables';
import Compressors from '../compressors/compressors';
import Powerstations from '../powerstations/powerstations';
import Machinery from '../machinery/machinery';
import Hire from '../hire/hire';
import ShippingAffiliates from '../affiliates/shipping';
import FinanceAffiliates from '../affiliates/finance';
import FreightAffiliates from '../affiliates/freight';
import SigninUser from '../signin/signin';
import RegisterUser from '../signin/register';
import Dashboard from '../dashboard/dashboard';

class Main extends Component {
    state = {  } 

    componentDidMount() {
        try {
            const jwt = localStorage.getItem("token");
            const user = jwtDecode(jwt);
            this.setState({user});    
        } catch (error) {
            
        }
        
    }
    
    render() { 
        return (
            <div className="apc-mainscreen">
                <div className="apc-navbar">
                    <div><Link to="/"><APCLogo/></Link></div>
                    <div></div>
                    <div><APCNavbar user={this.state.user}/></div>
                </div>
                <div className="apc-contentscreen">
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/diesel-generators" element={<DieselGenerators/>} /> 
                        <Route path="/silenced-diesel-generators" element={<SilencedDieselGenerators/>} />
                        <Route path="/portable-diesel-generators" element={<Portables type="ADG"/>} />
                        <Route path="/portable-petrol-generators" element={<Portables type="APG"/>} />
                        <Route path="/portable-inverter-generators" element={<Portables type="API"/>} />
                        <Route path="/compressors" element={<Compressors/>} />
                        <Route path="/pumps" element={<Portables type="APW"/>} />
                        <Route path="/powerstations" element={<Powerstations/>} />
                        <Route path="/machinery" element={<Machinery/>} />
                        <Route path="/hire" element={<Hire/>} />
                        <Route path="/shipping-affiliates" element={<ShippingAffiliates/>} />
                        <Route path="/finance-affiliates" element={<FinanceAffiliates/>} />
                        <Route path="/freight-affiliates" element={<FreightAffiliates/>} />
                        <Route path="/signin" element={<SigninUser/>} />
                        <Route path="/register" element={<RegisterUser/>} />
                        <Route path="/dashboard/*" element={<Dashboard user={this.state.user}/>} />
                    </Routes>
                </div>
                <div>

                </div>
            </div>
        );
    }
}
 
export default Main;
