import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faHouse } from '@fortawesome/free-solid-svg-icons';
import DBTable from '../../../components/woydbtable/woydbtable';
import Woymodal from '../../../components/woymodal/Woymodal';
import UsersForm from '../../../components/forms/usersform';

class UsersMaintenance extends Component {
    state = {
        users:[],
        showModal:false,
        dialogMode:"",
        selectedUser:{},
        formTitle:""
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/users'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:users} = await Axios(config);
            this.setState({users});
            //console.log(users);        
        } catch (error) {
            console.log(error);
        }
    }

    openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false});
    }

    addUserClick = () => {
        this.setState({dialogMode:"add"});
        this.openModalDialog();   
        this.setState({formTitle:"Add New User"});
    }

    editUserClick = (user) => {
        this.setState({dialogMode:"update"});
        this.openModalDialog();      
        this.setState({selectedUser:user});
        this.setState({formTitle:"Update User Details"});
    }

    removeUserClick = (user) => {
        this.setState({dialogMode:"remove"});
        this.openModalDialog();      
        this.setState({selectedUser:user});
        this.setState({formTitle:"Are you sure you want to delete this user?"});
    }

    removeFromUsers = (id) => {
        const newUsers = this.state.users.filter((user) => user._id !== id);
        this.setState({users:newUsers});
    }

    editFromUsers = (editedUser) => {
        let editedusers = this.state.users;
        editedusers = editedusers.map(u => u._id !== editedUser._id ? u : editedUser);
        this.setState({users:editedusers})  
    }

    addToUsers = (newuser) => {
        let newusers = this.state.users;
        newusers.push(newuser);
        this.setState({users:newusers})
    }
            
    render() { 
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"Email",
                value:"email"
            },
            {
                label:"Type",
                value:"userRole"
            }
        ]
        return (
            <div className="apc-maintenance-container">
                {this.state.showModal && 
                    <Woymodal>
                        <UsersForm mode={this.state.dialogMode} title={this.state.formTitle} user={this.state.selectedUser} insertUser={this.addToUsers} updateUser={this.editFromUsers} removeUser={this.removeFromUsers} closeForm={this.closeModalDialog} tagsColumns={[]} linkColumns={[]}/>
                    </Woymodal>
                }
                <div className='apc-maintenance-title-container'>
                    <div>
                        <h2>Manage Users</h2>
                    </div>
                    <div className='apc-maintenance-addbutton-container'>
                        <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                        <span>&nbsp;</span>
                    {
                        (this.props.user.isAdmin) && <button type="button" onClick={this.addUserClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                    }
                    </div>
                </div>
                
                <DBTable data={this.state.users} user={this.props.user} display={display} addMethod={this.addUserClick} updateMethod={this.editUserClick} deleteMethod={this.removeUserClick}/>
            </div>    
        );
    }
}
 
export default UsersMaintenance;