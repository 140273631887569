import React from 'react';

const SpecInputBox = ({type, name, placeholder, error, onchange, value, index}) =>{
    return(
        <div className="form-group mb-3">
            <input className="form-control apc-specinputbox" type={type} id={"id-"+name} name={name} placeholder={placeholder} onChange={(event) => {onchange(index,event)}} defaultValue={value}/>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default SpecInputBox;