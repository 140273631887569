import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './woydialog.css'
import WoyPopUp from '../popup/woyPopUp';
import WoyCRUDSearch from '../woycrudsearch/WoyCRUDSearch';
import DBTableBrowse from '../woydbtable/woydbtablebrowse';
import {sortbyColumn} from '../../utils/paginate';

class SupplierDialog extends Component {
    state = { 
        suppliers:[],
        filteredSuppliers:[],
        selectedSupplier:{},
        showPopUp:false,
        formTitle:"",
        pageSize:20,
        currentPage:1,
        activeColumn:"name"
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/suppliers'); 
        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }};
        
        try {
            const {data:suppliers} = await Axios(config);
            this.setState({suppliers:suppliers,filteredSuppliers:suppliers,selectedSupplier:this.props.supplier});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (colName, searchValue) => {
        // console.log(colName + " - " + searchValue);
         //const $regex = escapeStringRegexp(searchValue);
         const {suppliers} = this.state;
         let newSuppliers = [];
         if (colName==="tags"){
             newSuppliers = suppliers.filter( x => 
                 x[colName].some(t => t.includes(searchValue)) 
               );
         } else {
             newSuppliers = suppliers.filter( x => 
                 x[colName].includes(searchValue) 
               );
         }
         
         this.setState({filteredSuppliers:newSuppliers})   
     }

    openSupplierBrowser = () => {
        this.setState({showPopUp: true});
    }

    closeSupplierBrowser = () => {
        this.setState({showPopUp: false});
    }

    selectSupplierClick = (selected) => {
        this.setState({selectedSupplier: selected});
        this.props.setSupplier(selected);
        this.closeSupplierBrowser();
    }

    handleSort = (colName) => {
        const sortedSuppliers = sortbyColumn(this.state.filteredSuppliers,colName);
        this.setState({filteredSuppliers:sortedSuppliers,activeColumn:colName});
    }

    displaySupplierDetails = () => {
        if (this.state.selectedSupplier !== null){
            if (Object.keys(this.state.selectedSupplier).length !== 0)
                return (<span class="input-group-text apc-form-dialog-text">{this.state.selectedSupplier.name}</span>)
            else
                return (<span class="input-group-text apc-form-dialog-text">No supplier selected.</span>);    
        }
        else 
            return (<span class="input-group-text apc-form-dialog-text">No supplier selected.</span>);
    }

    render() {
        let display = [
            {
                label:"Name",
                value:"name",
            },
            {
                label:"Address",
                value:"address"
            },
            {
                label:"Supply Tags",
                value:"tags"
            }
        ];

        const displaySupplier = this.state.filteredSuppliers;
        //console.log(displayProduct);

        const searchOptions = [
            {label:"Name", value:"name"},
            {label:"Supplier Tag", value:"tags"}
        ];

        return (
         <div>
            {
                this.state.showPopUp && 
                    <WoyPopUp handleClose={this.closeSupplierBrowser}>
                        <div>
                            <WoyCRUDSearch options={searchOptions} defaultOption="name" searchClick={this.handleSearch}/>
                        </div>
                        <DBTableBrowse 
                        data={displaySupplier} 
                        user={this.props.user} 
                        display={display} 
                        selectMethod={this.selectSupplierClick} 
                        sortBy={this.state.activeColumn} 
                        sortData={this.handleSort}
                        tagsColumns={["tags"]} 
                        linkColumns={["website"]}
                        />
                    </WoyPopUp>    
            } 

            <div class="input-group">
                <span class="input-group-text apc-form-dialog-label">Supplier</span>
                {this.displaySupplierDetails()}
                <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={this.openSupplierBrowser}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
            </div>
        </div>    
        );
    }
}
 
export default SupplierDialog;