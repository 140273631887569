import React, { Component } from 'react';
import Joi from 'joi-browser';
import Axios from 'axios';
import InputBox from '../input/inputbox2';
import CheckBox from '../input/checkbox';
import WoyTags from '../input/woytags/woytags';
//import TagsBox from '../input/tagsbox';

const updateRemove = ["update","remove","view"];
const updateInsert = ["update","add"];

export const supplierSchema = Joi.object({
    _id: Joi.string()
        .optional(),
    tags: Joi.array()
        .items(Joi.string().min(3).max(200))
        .required(),
    name: Joi.string()
        .min(6)
        .max(75)
        .required(),
    withcreditaccount: Joi.boolean()
        .required(),
    accountno: Joi.string()
        .max(50)
        .optional(),
    address: Joi.string()
        .min(6)
        .max(255)
        .required(),
    email: Joi.string()
        .min(6)
        .max(255)
        .required()
        .email(),
    phoneno: Joi.string()
        .min(6)
        .max(100)
        .required(),
    contactperson: Joi.string()
        .min(3)
        .max(100),
    website: Joi.string()
        .min(6)
        .max(255),
    maxcreditamt: Joi.number()
        .min(0),
    updated_at: Joi.date()
        .optional(),
    created_at: Joi.date()
        .optional(),
    __v: Joi.number()
        .optional()   
});

export default class SuppliersForm extends Component {
    state = { 
        supplier: {
            tags:[],
            name:"",
            withcreditaccount:false,
            accountno:"",
            address:"",
            email:"",
            phoneno:"",
            contactperson:"",
            website:"",
            maxcreditamt:0.0
        },
        errors: {}
     } 

     /* componentDidUpdate(prevState){
        
        console.log(this.state.supplier.tags);
        //this.setState({isLoading:false});
     } */

     componentDidMount() {
        if (updateRemove.includes(this.props.mode)) {
            this.setState({
                supplier: {
                    tags:this.props.supplier.tags,
                    name:this.props.supplier.name,
                    withcreditaccount:this.props.supplier.withcreditaccount,
                    accountno:this.props.supplier.accountno,
                    address:this.props.supplier.address,
                    email:this.props.supplier.email,
                    phoneno:this.props.supplier.phoneno,
                    contactperson:this.props.supplier.contactperson,
                    website:this.props.supplier.website,
                    maxcreditamt:this.props.supplier.maxcreditamt
                }
            })
        }
    }

    formFields = () => {
        /* et editmode=false;
        if (updateInsert.includes(this.props.mode))
            editmode=true; */
        return(
            <React.Fragment>
                
                    <WoyTags
                        label="Supply Tags" 
                        mode={this.props.mode}
                        tags={this.state.supplier.tags}
                        setTags={this.handleTagsChange}
                    />
                    <InputBox 
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Supplier name"
                        error={this.state.errors.name}
                        onchange={this.handleChange}
                        value={this.state.supplier.name}
                    />
                    <div className="apc-form-grid-3-cols">
                        <div className='apc-form-grid-cell'>
                            <CheckBox 
                            label="We have a Credit Account with them"
                            name="withcreditaccount"
                            error={this.state.errors.withcreditaccount}
                            onchange={this.handleChange}
                            value={this.state.supplier.withcreditaccount}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Account No"
                            type="text"
                            name="accountno"
                            placeholder="Account Number"
                            error={this.state.errors.accountno}
                            onchange={this.handleChange}
                            value={this.state.supplier.accountno}
                            />
                        </div>
                        <div className='apc-form-grid-cell'>
                            <InputBox 
                            label="Maximum Credit"
                            type="text"
                            name="maxcreditamt"
                            placeholder="0.00"
                            error={this.state.errors.maxcreditamt}
                            onchange={this.handleChange}
                            value={this.state.supplier.maxcreditamt}
                            />        
                        </div>
                    </div>
                    
                    
                    <InputBox 
                        label="Address"
                        type="text"
                        name="address"
                        placeholder="Enter address"
                        error={this.state.errors.address}
                        onchange={this.handleChange}
                        value={this.state.supplier.address}
                    />
                    <InputBox 
                        label="Email Address"
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        error={this.state.errors.email}
                        onchange={this.handleChange}
                        value={this.state.supplier.email}
                    />
                    <InputBox 
                        label="Phone Number"
                        type="text"
                        name="phoneno"
                        placeholder="Enter phone number"
                        error={this.state.errors.phoneno}
                        onchange={this.handleChange}
                        value={this.state.supplier.phoneno}
                    />
                    <InputBox 
                        label="Contact Person"
                        type="text"
                        name="contactperson"
                        placeholder="Enter name"
                        error={this.state.errors.contactperson}
                        onchange={this.handleChange}
                        value={this.state.supplier.contactperson}
                    />
                    <InputBox 
                        label="Website"
                        type="text"
                        name="website"
                        placeholder="URL of website"
                        error={this.state.errors.website}
                        onchange={this.handleChange}
                        value={this.state.supplier.website}
                    />
                    
            </React.Fragment>
        )
    }

    validate = () => {
        const {supplier} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(supplier, supplierSchema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currSupplier  = {...this.state.supplier};
        currSupplier[name] = value;

        this.setState({
            supplier: currSupplier
        });
    };

    handleTagsChange = (tagsValue) => {
        let currSupplier  = this.state.supplier;
        currSupplier.tags = tagsValue;
        this.setState({
            supplier:currSupplier 
        });
        //console.log(this.state.supplier);
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        let url = "";
        let method = "";

        if (this.props.mode === "add") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/suppliers');
            method = 'post';
        } else if (this.props.mode === "update") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/suppliers/'+this.props.supplier._id);
            method = 'put';
            //console.log("update url and method");
        } else if (this.props.mode === "remove") {
            url = (process.env.REACT_APP_BACKEND_URL + '/api/suppliers/'+this.props.supplier._id);
            method = 'delete';
        }
             
        if (updateInsert.includes(this.props.mode)){
            const errors = this.validate();
            //console.log(errors);
            this.setState({errors: errors || {}});
            if (errors) return;

        } 

        const {supplier} = this.state;
        let data = JSON.stringify({
            tags:supplier.tags,
            name:supplier.name,
            withcreditaccount:supplier.withcreditaccount,
            accountno:supplier.accountno,
            address:supplier.address,
            email:supplier.email,
            phoneno:supplier.phoneno,
            contactperson:supplier.contactperson,
            website:supplier.website,
            maxcreditamt:supplier.maxcreditamt
        });
        
        //console.log(data);  
        var config = {
        method: method,
        url: url,
        headers: { 
            'x-auth-token': localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            //console.log(data);
            //console.log(url);
            const response = await Axios(config);
            //console.log(response);
            if (response) {
                const updatedSupplier = response.data;
                if (this.props.mode === "add"){
                    this.props.insertSupplier(updatedSupplier);
                } else if (this.props.mode === "update"){
                    this.props.updateSupplier(updatedSupplier);
                } else if (this.props.mode === "remove"){
                    /* console.log("delete mode successful");
                    console.log("supplier._id: "+updatedSupplier._id); */
                    this.props.removeSupplier(updatedSupplier._id);
                }
                this.props.closeForm();
            } 
        } catch (error) {
            console.log(error.message);
            this.props.closeForm();
        }
    };

    render() { 
        let formcontents = "";
        if (this.props.mode === "remove"){
            formcontents = <fieldset disabled="disabled">{this.formFields()}</fieldset>;
        } else {
            formcontents = this.formFields();
        }
        return (
            <div>
                    <h2>{this.props.title}</h2>
                    <form onSubmit={this.handleSubmit}>  
                    {formcontents}
                    <div>
                        {this.props.mode !== "view" && <React.Fragment><button className="btn btn-primary" type="submit">
                            {this.props.mode}
                        </button>
                        <span>&nbsp;</span>
                        
                        <button className="btn btn-danger" type="button" onClick={this.props.closeForm}>
                            close
                        </button>
                        </React.Fragment>}
                    </div>
                </form>
                </div>
        );
    }
};