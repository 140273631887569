import { Button } from 'react-bootstrap';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios'
import Joi from 'joi-browser';
import InputBox from '../../components/input/inputbox';
import './signin.css';

class SigninUser extends Component {
    

    state = {
        user: {email: "", password: ""},
        errors: {}        
    }

    validate = () => {
        const schema = Joi.object({      
            email: Joi.string()
                .min(6)
                .max(255)
                .required()
                .email()
                .label("Email"),
            password: Joi.string()
                .min(6)
                .max(255)
                .required()
                .label("Password")
        });
            
        const {user} = this.state;
        const options = {abortEarly:false};
        const result = Joi.validate(user, schema, options);
        if (!result.error) return null;
        
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;

        return errors;
    };
    
    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let currUser  = {...this.state.user};
        currUser[name] = value;

        this.setState({
            user: currUser
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        let {navigation} = this.props;
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/auth'); 

        const errors = this.validate();
        console.log(errors);
        this.setState({errors: errors || {}});
        if (errors) return;

        var data = JSON.stringify(this.state.user);
          
        var config = {
        method: 'post',
        url: url,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        try {
            const responseToken = await Axios(config);
            if (responseToken.data) {
                //console.log(responseToken.data);
                localStorage.setItem("token",responseToken.data);
                //<div>{token}</div>
                //navigation("/");
                window.location = "/";
            } else
            {
                console.log(responseToken.json());
            }
        } catch (error) {
            console.log(error.message);
            this.state.errors.password = "Invalid email or password.";
            navigation("/signin");
        }
    };
    
    render() {
        //let url = (process.env.REACT_APP_BACKEND_URL + '/api/auth');               
        return (
            <div className="apc-sign-in-body">
                <div className="apc-sign-in-box">
                    <h2>Sign-in</h2>
                    <Form onSubmit={this.handleSubmit}>
                        <InputBox 
                            label="Email address"
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            error={this.state.errors.email}
                            onchange={this.handleChange}
                        />
                        <InputBox 
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            error={this.state.errors.password}
                            onchange={this.handleChange}
                        />
                       {/*  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group> */}
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit">
                                Sign-in
                            </Button>
                        </div>
                        {/* <div>{url}</div>    
                        <div>{this.state.user.email}</div>
                        <div>{this.state.user.password}</div> */}
                    </Form>
                </div>
            </div>    
        );
    }
}

const SignIn = (props) => {
    const navigation = useNavigate();
    return <SigninUser {...props} navigation={navigation} />;
}

export default SignIn;