import React, { Component } from 'react';
import DashSideMenu from '../../components/sidemenu/DashSideMenu';
import Users from '../admin/users/usersmaintenance';
import Suppliers from '../admin/suppliers/suppliersmaintenance';
import Products from '../admin/products/productsmaintenance';
import Inv from '../admin/inventory/invmaintenance';
import RecStock from '../admin/receiving/receivingStock';
import Customers from '../admin/customers/customersmaintenance';
import Invoice from '../admin/invoice/invoice';
import Despatch from '../admin/dispatching/stockdispatching';
import './dashboard.css';
class Dashboard extends Component {
    state = {
        url:""
    }
    
    handleLinkClick = (e) => {
        this.setState({url:e.target.value});
    }
    
    render() { 
        return (
            <div className='apc-dashboard-container'>
                <div className='apc-dashboard-side-menu'>
                    <DashSideMenu handleClick={this.handleLinkClick}/>
                </div>
                <div className='apc-dashboard-content-container'>
                    {(this.state.url === "/usersmaintenance") && <Users user={this.props.user} />}    
                    {(this.state.url === "/suppliers") && <Suppliers user={this.props.user} />} 
                    {(this.state.url === "/products") && <Products user={this.props.user} />}
                    {(this.state.url === "/inventory") && <Inv user={this.props.user} />}
                    {(this.state.url === "/receiving") && <RecStock user={this.props.user} type="STOCK" />}
                    {(this.state.url === "/customers") && <Customers user={this.props.user} />}
                    {(this.state.url === "/invoice") && <Invoice user={this.props.user}/>}
                    {(this.state.url === "/despatching") && <Despatch user={this.props.user}/>}
                </div>
            </div>
        );
    }
}
 
export default Dashboard;