import './navbar.css';
import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
class APCNavbar extends Component {
    state = {  }
    
    logout =()=>{
        localStorage.removeItem("token");
        window.location = "/"
    }
    
    render() { 
        return (
            <Navbar className="apcnavbar-position apcnav-items" collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto" fill>
                            <NavDropdown title="Generators" id="generators-nav-dropdown">
                                <NavDropdown.Item href="/diesel-generators">Diesel Generators</NavDropdown.Item>
                                <NavDropdown.Item href="/silenced-diesel-generators">Silenced Diesel Generators</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/portable-diesel-generators">Portable Diesel Generators</NavDropdown.Item>
                                <NavDropdown.Item href="/portable-petrol-generators">Portable Petrol Generators</NavDropdown.Item>
                                <NavDropdown.Item href="/portable-inverter-generators">Portable Inverter Generators</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/compressors">Compressors</Nav.Link>
                            <Nav.Link href="/pumps">Pumps</Nav.Link>
                            <Nav.Link href="/powerstations">Power Stations</Nav.Link>
                            <Nav.Link href="/machinery">Machinery</Nav.Link>
                            <Nav.Link href="/hire">Hire</Nav.Link>
                            <NavDropdown title="Affiliates" id="affiliates-nav-dropdown">
                                <NavDropdown.Item href="/finance-affiliates">Finance</NavDropdown.Item>
                                <NavDropdown.Item href="/shipping-affiliates">Shipping</NavDropdown.Item>
                                <NavDropdown.Item href="/freight-affiliates">Freight</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="https://www.machines4u.com.au" target="_m4u">Machines4U</NavDropdown.Item>
                            </NavDropdown>
                            {!this.props.user && <NavDropdown title="Sign-in" id="signin-nav-dropdown">
                                <NavDropdown.Item href="/signin">Sign-in</NavDropdown.Item>
                                <NavDropdown.Item href="/register">Register</NavDropdown.Item>
                            </NavDropdown>}
                            {this.props.user && <NavDropdown title={this.props.user.name} id="user-nav-dropdown">
                                <NavDropdown.Item onClick={this.logout}>Log-out</NavDropdown.Item>
                                <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                            </NavDropdown>}
                        </Nav>
                        <Nav>
                            <Form>
                                <Form.Group className="mb-3" controlId="formSearch">    
                                <div className="apc-navform-container">
                                    <div className='apc-navform-cell'>
                                        <Form.Control className="apc-navform-searchtext" type="text" placeholder="looking for?.." />
                                    </div>
                                    <div className='apc-navform-cell'>
                                        <Button className="apc-searchbutton apc-navform-searchtext" type="submit">
                                            Search
                                        </Button>
                                    </div>
                                </div>            
                                </Form.Group>
                            </Form>
                            <span>&nbsp;&nbsp;</span>
                            <Navbar.Brand><FontAwesomeIcon icon={faPhone} /> 1 800 287 769</Navbar.Brand>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}
 
export default APCNavbar;