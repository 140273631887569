import React from 'react';

const CheckBox = ({label, name, placeholder, error, onchange, value}) =>{
    return(
        <div className="form-check mb-3">
            {(value) && <input className="form-check-input" type="checkbox" id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange} checked/>}
            {(!value) && <input className="form-check-input" type="checkbox" id={"id-"+name} name={name} placeholder={placeholder} onChange={onchange}/>}
            <label className="form-check-label" htmlFor={"id-"+name}>{label}</label>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
       
}
export default CheckBox;