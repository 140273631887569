import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
import Woymodal from '../../../components/woymodal/Woymodal';
import InvForm from '../../../components/forms/invform';
import WoyPagination from '../../../components/pagination/pagination';
import {paginate} from '../../../utils/paginate';
//import WoyCRUDSearch from '../../../components/woycrudsearch/WoyCRUDSearch';
import WoyInvSearch from '../../../components/woycrudsearch/WoyInvSearch';
import InventoryDetails from '../../../components/forms/inventorydetailstemplate/inventorydetails';
import InventoryRow from './invrow';

const editDelete = ["update","remove"];

class InventoryMaintenance extends Component {
    state = { 
        invs:[],
        filteredInvs:[],
        selectedInv:{},
        showModal:false,
        dialogMode:"",
        formTitle:"",
        pageSize:20,
        currentPage:1,
        activeColumn:"details",
        searchColName:"name",
        statOption:"RECEIVED",
        searchValue:""
    } 

    async componentDidMount() {
        let url = (process.env.REACT_APP_BACKEND_URL + '/api/inventory/byType/STOCK'); 
        /* let data = JSON.stringify({
            type:this.props.type
        }); */

        var config = {
            method: 'get',
            url: url,
            headers: { 
                'x-auth-token': localStorage.getItem("token")
            }
        };
        
        try {
            const {data:inv} = await Axios(config);
            this.setState({invs:inv,filteredInvs:inv});
        } catch (error) {
            console.log(error);
        }
    }

    handleSearch = (statOption,searchColName,searchValue) => {
      

       /*  {label:"Product Name", value:"name"},
            {label:"Serial No", value:"serialno"},
            {label:"Description", value:"description"},
            {label:"Inventory Type", value:"type"},
            {label:"Product Category", value:"category"}, */
        
        const {invs} = this.state;
        let newInvs = [];
        let newInvs2 = [];
        
        if (statOption !=="ALL"){
            newInvs = invs.filter( x => 
                x.status.includes(statOption) 
              );
        } 
        else 
            newInvs = invs;

        if (searchColName==="type"){
            newInvs2 = newInvs.filter( x => 
                x.type.includes(searchValue) 
              );
        }
        else if (searchColName==="description"){
            newInvs2 = newInvs.filter( x => 
                x.product.description.includes(searchValue) 
              );
        }
        else if (searchColName==="serialno"){
            newInvs2 = newInvs.filter( x => 
                x.product.serialno.includes(searchValue) 
              );
        }
        else if (searchColName==="name"){
            newInvs2 = newInvs.filter( x => 
                x.product.name.includes(searchValue) 
              );
        }
        else if (searchColName==="category"){
            newInvs2 = newInvs.filter( x => 
                x.product.category.includes(searchValue) 
              );
        }
        
        return (newInvs2)
               
        //this.setState({filteredInvs:newInvs2})   
    }

    handleStatOptionChange = (e) =>{
        const tempInvs = this.handleSearch(e.target.value,this.state.searchColName,this.state.searchValue);
        this.setState({statOption:e.target.value,filteredInvs:tempInvs});
        
    }

    handleColNameChange = (e) =>{
        const tempInvs = this.handleSearch(this.state.statOption,e.target.value,this.state.searchValue);
        this.setState({searchColName:e.target.value,filteredInvs:tempInvs});
    }

    handleSearchTextChange = (e) =>{
        const tempInvs = this.handleSearch(this.state.statOption,this.state.searchColName,e.target.value);
        this.setState({searchValue:e.target.value,filteredInvs:tempInvs});
        //this.handleSearch();
    }

    openModalDialog = () => {
        this.setState({showModal:true});
    }

    closeModalDialog = () => {
        this.setState({showModal:false});
    }

    addInvClick = () => {
        this.setState({dialogMode:"add"});
        this.openModalDialog();   
        this.setState({formTitle:"Add New Inventory"});
    }

    editInvClick = (inv) => {
        this.setState({dialogMode:"update"});
        this.openModalDialog();      
        this.setState({selectedInv:inv});
        this.setState({formTitle:"Update - " + inv.product.name});
    }

    viewInvClick = (inv) => {
        this.setState({dialogMode:"view"});
        this.openModalDialog();      
        this.setState({selectedInv:inv});
        this.setState({formTitle:"View - " + inv.product.name});
    }

    removeInvClick = (inv) => {
        this.setState({dialogMode:"remove"});
        this.openModalDialog();      
        this.setState({selectedInv:inv});
        this.setState({formTitle:"Are you sure you want to delete this?"});
    }

    removeFromInvs = (id) => {
        let newInvs = this.state.invs.slice();
        let newInvs2 = this.state.filteredInvs.slice();
        newInvs = newInvs.filter((inv) => inv._id !== id);
        newInvs2 = newInvs2.filter((inv) => inv._id !== id);
        this.setState({invs:newInvs,filteredInvs:newInvs2});

    }

    editFromInvs = (editedInv) => {
        let editedInvs = this.state.invs.slice();
        let editedInvs2 = this.state.filteredInvs.slice();
        editedInvs = editedInvs.map(inv => inv._id !== editedInv._id ? inv : editedInv);
        editedInvs2 = editedInvs2.map(inv => inv._id !== editedInv._id ? inv : editedInv);

        this.setState({invs:editedInvs,filteredInvs:editedInvs2,selectedInv:editedInv})  
    }

    addToInvs = (newinv) => {
        let newinvs = this.state.invs.slice();
        let newinvs2 = this.state.filteredInvs.slice();
        newinvs.push(newinv);
        newinvs2.push(newinv);
        this.setState({invs:newinvs,filteredInvs:newinvs2})  
    }

    handlePageChange = (page) =>{
        //console.log(page);
        this.setState({currentPage:page});
        //this.setState({displaySuppliers:display});
    }

    /* handleSort = (colName) => {
        const sortedInvs = this.sortInvByColumn(this.state.filteredInvs,colName);
        this.setState({filteredInvs:sortedInvs,activeColumn:colName});
    } */

    /* sortInvByColumn = (inv,colName) => {
        if (colName==="details") 
            return inv.sort((a, b) => (a.product.name > b.product.name) ? 1 : -1)
        else if (colName==="category") 
            return inv.sort((a, b) => (a.product.category > b.product.category) ? 1 : -1)
        else if (colName==="serialno") 
            return inv.sort((a, b) => (a.product.serialno > b.product.serialno) ? 1 : -1)
        else if (colName==="type") 
            return inv.sort((a, b) => (a.type > b.type) ? 1 : -1)
        else if (colName==="qty") 
            return inv.sort((a, b) => (a.qty > b.qty) ? 1 : -1)
        else if (colName==="cost") 
            return inv.sort((a, b) => (a.cost > b.cost) ? 1 : -1);
    } */
    
    render() { 
        

        const displayInv = paginate(this.state.filteredInvs,this.state.currentPage,this.state.pageSize);

        const searchOptions = [
            {label:"Product Name", value:"name"},
            {label:"Serial No", value:"serialno"},
            {label:"Description", value:"description"},
            {label:"Inventory Type", value:"type"},
            {label:"Product Category", value:"category"},
        ];

        const statOptions = [
            {label:"All", value:"ALL"},
            {label:"In-Stock", value:"RECEIVED"},
            {label:"Sold", value:"DISPATCHED"}
        ];

        return (
            <div className="apc-maintenance-container">
            {this.state.showModal && (this.state.dialogMode==="add") && 
                <Woymodal>
                    <InvForm mode={this.state.dialogMode} user={this.props.user} title={this.state.formTitle} inv={this.state.selectedInv} insertInv={this.addToInvs} updateInv={this.editFromInvs} removeInv={this.removeFromInvs} closeForm={this.closeModalDialog}/>
                </Woymodal>
            }
            <div className='apc-maintenance-title-container'>
                <div>
                    <h2>Manage Stocks</h2>
                </div>
                <div>
                    <WoyInvSearch  statOptions={statOptions} options={searchOptions} handleColNameChange={this.handleColNameChange} handleSearchTextChange={this.handleSearchTextChange} handleStatOptionChange={this.handleStatOptionChange}/>
                </div>
                <div className='apc-maintenance-addbutton-container'>
                    {/* <button type="button" className="btn btn-primary apc-maintenance-homebutton"><FontAwesomeIcon icon={faHouse} /></button>
                    <span>&nbsp;</span> */}
                    <button type="button" onClick={this.addInvClick} className="btn btn-success apc-maintenance-addbutton"><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
          
            <div>
                <table className="apc-table-container">
                    <thead>
                        <tr>
                            <th className='apc-woytable-row-header'>STATUS</th>
                            {/* <th className='apc-woytable-row-header'>STATUS</th> */}
                            <th className='apc-woytable-row-header'>CATEGORY</th>
                            <th className='apc-woytable-row-header'>MOVEMENT</th>
                            <th className='apc-woytable-row-header'>DETAILS</th>
                            <th className='apc-woytable-row-header'>ACTION(S)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    displayInv && displayInv.map((inv,index) =>
                        <React.Fragment>
                            <InventoryRow inv={inv} index={index} user={this.props.user} removeInvClick={this.removeInvClick} editInvClick={this.editInvClick} viewInvClick={this.viewInvClick}/>
                            {(editDelete.includes(this.state.dialogMode))&&(this.state.showModal) && (this.state.selectedInv._id === inv._id) && <tr key={"editdelete"+index}><td colSpan={7} className="apc-table-editRow"><div className='apc-table-editRow-Content'><InvForm mode={this.state.dialogMode} user={this.props.user} title={this.state.formTitle} inv={this.state.selectedInv} insertInv={this.addToInvs} updateInv={this.editFromInvs} removeInv={this.removeFromInvs} closeForm={this.closeModalDialog}/></div></td></tr>}
                            {(this.state.dialogMode === "view")&&(this.state.showModal) && (this.state.selectedInv._id === inv._id) && <tr key={"view"+index}><td colSpan={7} className="apc-table-editRow"><div className='apc-table-editRow-Content'><InventoryDetails user={this.props.user} title="Stock Inventory Details" inv={this.state.selectedInv} closeForm={this.closeModalDialog}/></div></td></tr>}
                        </React.Fragment>
                    )    
                }
                    </tbody>
                </table>
            </div> 

            <div className='apc-maintenance-table-footer'>
                <div className='apc-maintenance-results-container'>Showing {displayInv.length} of {this.state.filteredInvs.length} result(s)</div>
                <WoyPagination itemsCount={this.state.filteredInvs.length} pageSize={this.state.pageSize} currentPage={this.state.currentPage} pageChangeClick={this.handlePageChange}/>
            </div>
        </div>
        );
    }
}
 
export default InventoryMaintenance;